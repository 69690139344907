import { Fade, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoriaFiltre from "../../../../components/CategoriaFiltre";
import CustomPublicButton from "../../../../components/CustomPublicButton";
import LoadingSkeleton from "../../../../components/Layout/LoadingSkeleton";
import MaterialPublic from "../../../../components/MaterialPublic";
import MaterialsSkeleton from "../../../../components/Skeletons/MaterialsSkeleton";
import { getElements } from "../../../../database/API";

export default function StepMaterial({
	setMaterialsSel,
	setTotal,
	handleBack,
	handleNext,
	materialsSel,
	data,
}) {
	const classes = useStyles();
	const [materials, setMaterials] = useState([]);
	const [trans, setTrans] = useState(false);
	const [categories, setCategories] = useState([]);
	const [filter, setFilter] = useState(1);
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const { result } = await getElements("materials");
			const categories = await getElements("categoria_materials");
			setMaterials(result);
			setCategories(categories.result);
			console.log(categories.result);
			setLoading(false);
		};
		get();
	}, []);

	const change = (id) => {
		setTrans(true);
		setTimeout(() => {
			setFilter(id);
			setTrans(false);
		}, 400);
	};

	return (
		<Box>
			<Typography variant="body1" fontWeight={800} pb={3}>
				{t("Seleccionar material")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					pb: 8,
				}}
			>
				<CustomPublicButton
					small
					onClick={handleBack}
					sx={{ mr: 1 }}
					title={t("Enrere")}
				/>
				<CustomPublicButton
					onClick={handleNext}
					small
					disabled={!materialsSel.length}
					title={t("Següent")}
				/>
			</Box>
			<Box display={"flex"} mb={8} justifyContent="center">
				{categories?.map((item) => (
					<CategoriaFiltre
						cat={item}
						categoria={filter}
						set={change}
					/>
				))}
			</Box>
			{filter === 3 && (
				<Box mb={3} className={classes.condicions}>
					<Typography>{t("text_lloguer_acuatic")}</Typography>
				</Box>
			)}
			<Box className={classes.block}>
				<Fade in={!trans}>
					<div>
						<LoadingSkeleton
							loading={loading}
							skeleton={<MaterialsSkeleton />}
						>
							<Grid container spacing={6}>
								{materials?.map((item) => (
									<MaterialPublic
										material={item}
										setMaterialsSel={setMaterialsSel}
										setTotal={setTotal}
										data={data}
										filter={filter}
									/>
								))}
							</Grid>
						</LoadingSkeleton>
					</div>
				</Fade>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					py: 8,
				}}
			>
				<CustomPublicButton
					small
					onClick={handleBack}
					sx={{ mr: 1 }}
					title={t("Enrere")}
				/>
				<CustomPublicButton
					onClick={handleNext}
					small
					disabled={!materialsSel.length}
					title={t("Següent")}
				/>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	block: {
		"& .react-datepicker": {
			border: "1px solid " + theme.palette.background.color,
			borderRadius: 5,
			padding: 20,
			marginBottom: 20,
		},
	},
	condicions: {
		padding: 20,
		borderRadius: 10,
		marginBottom: 100,
		backgroundColor: theme.palette.background.light,
	},
}));

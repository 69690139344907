import { Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function CategoriesSkeleton() {
    const classes = useStyles();
    return (
        <Box my={3} display="flex" justifyContent={'space-between'}>
            <Skeleton variant="rectangular" className={classes.chip} />
            <Skeleton variant="rectangular" className={classes.chip} />
            <Skeleton variant="rectangular" className={classes.chip} />
            <Skeleton variant="rectangular" className={classes.chip} />
            <Skeleton variant="rectangular" className={classes.chip} />
            <Skeleton variant="rectangular" className={classes.chip} />
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    chip: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        margin: 10,
        borderRadius: 20,
        width: '100%',
    },
}));

import {
	Avatar,
	Chip,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { Edit, Eye } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";
import State from "../../../components/State";
import StateColumn from "../../../components/StateColumn";

const LloguersColumns = (lloguers, filterList) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "state",
			label: "Estat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <StateColumn state={value} />,
			},
		},
		{
			name: "state.nom",
			label: "Estats",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				display: false,
				filterList: filterList,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "materials",
			label: "Material llogat",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => {
					return (
						<Stack direction="row" spacing={1}>
							{value?.map((item) => {
								return (
									<Chip
										label={
											item.nom +
											" (" +
											item.pivot.talla +
											" " +
											" x" +
											item.pivot.quant +
											")"
										}
										style={{ fontSize: 11 }}
									/>
								);
							})}
						</Stack>
					);
				},
			},
		},
		{
			name: "data",
			label: "Data lloguer",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "created_at",
			label: "Data compra",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) =>
					moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "preu",
			label: "Total",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => (
					<Typography variant="body1">{value} €</Typography>
				),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Veure detalls">
							<IconButton
								onClick={() =>
									navigate(
										`/admin/lloguers/view/${lloguers[dataIndex].id}`,
										{
											state: lloguers[dataIndex],
										}
									)
								}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default LloguersColumns;

import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";
import StateColumn from "../../../../components/StateColumn";

const EntradesColumns = (reserves, filterList) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "unique_id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: true,
			},
		},
		{
			name: "state",
			label: "Estat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <StateColumn state={value} />,
			},
		},
		{
			name: "state.nom",
			label: "Estat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				display: false,
				filterList: filterList,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "access",
			label: "Accés",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					value === "2" ? "Entrada Barrancs" : "Entrada General",
			},
		},
		{
			name: "data",
			label: "Data reserva",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) =>
					moment(value).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "preu",
			label: "Total",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => (
					<Typography variant="body1">{value} €</Typography>
				),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/entradesEAV/${reserves[dataIndex].id}`,
									{
										state: reserves[dataIndex],
									}
								)
							}
						>
							<Eye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default EntradesColumns;

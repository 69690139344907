import { Box, Button, CircularProgress, Container, Fade } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import MUIDataTable from 'mui-datatables';
import TableOptions from '../../../components/TableOptions';
import { getElements } from '../../../database/API';
import { Plus } from 'react-feather';
import getMuiTheme from '../../../theme/TableStyle';
import Loading from '../../../components/Layout/Loading';
import CustomButton from '../../../components/CustomButton';
import MaterialsColumns from './MaterialsColumns';
import Title from '../../../components/Layout/Title';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: 80,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const MaterialsAdmin = () => {
    const classes = useStyles();
    const [materials, setMaterials] = useState([]);
    const [loading, setLoading] = useState(true);
    const columns = MaterialsColumns(materials);
    const navigate = useNavigate();

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements('materials');
            setMaterials(result);
            setLoading(false);
            console.log(result);
        };

        get();
    }, []);

    const CustomToolbar = () => {
        return (
            <CustomButton
                onClick={() => navigate('/admin/materials/add')}
                title={
                    <>
                        <Plus size={15} /> Nou material
                    </>
                }
            />
        );
    };
    const options = TableOptions(CustomToolbar);

    return (
        <Box className={classes.container}>
            <Title title="Material" />
            <Container maxWidth="xl">
                <Loading loading={loading}>
                    <Box pt={4} spacing={3}>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                data={materials}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </Box>
                </Loading>
            </Container>
        </Box>
    );
};

export default MaterialsAdmin;

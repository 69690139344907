import { Circle } from "@mui/icons-material";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { quinaDisponibilitat } from "../../../../data/Utils";
import { addElement } from "../../../../database/API";
import i18n from "../../../../lang";
import theme from "../../../../theme";
import { blue, green, orange, red, yellow } from "@mui/material/colors";

export default function CalendariDialogMesAdd({
	setUpdate,
	open,
	setOpen,
	setLoading,
	loading,
	dateSel,
}) {
	const [disponible, setDisponible] = useState(0);
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setOpen(false);
	};

	const guardar = async () => {
		setLoading(true);
		const { message } = await addElement("calendarisMes", {
			data: dateSel,
			disponibilitat: disponible,
		});
		setLoading(false);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
				},
			}}
			maxWidth="lg"
		>
			<DialogTitle
				sx={{
					background: theme.palette.background.main,
					color: "white",
					fontSize: 30,
				}}
			>
				Canviar disponibilitat del mes sencer
			</DialogTitle>
			<DialogContent>
				<Typography variant="h3" textAlign={"center"} my={3}>
					{i18n.moment(dateSel).locale("ca").format("MMMM YYYY")}
				</Typography>
				<FormControl>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue={disponible}
						name="disponible"
						value={disponible}
					>
						<FormControlLabel
							value={0}
							control={<Radio />}
							label={
								<Typography
									display={"flex"}
									alignItems={"center"}
								>
									Obert{" "}
									<Circle style={{ fill: green[500] }} />
								</Typography>
							}
							onChange={() => setDisponible(0)}
						/>
						<FormControlLabel
							value={1}
							control={<Radio />}
							label={
								<Typography
									display={"flex"}
									alignItems={"center"}
								>
									Obert mitja jornada
									<Circle style={{ fill: blue[300] }} />
								</Typography>
							}
							onChange={() => setDisponible(1)}
						/>
						<FormControlLabel
							value={2}
							control={<Radio />}
							label={
								<Typography
									display={"flex"}
									alignItems={"center"}
								>
									Tancat{" "}
									<Circle style={{ fill: yellow[600] }} />
								</Typography>
							}
							onChange={() => setDisponible(2)}
						/>
						<FormControlLabel
							value={3}
							control={<Radio />}
							label={
								<Typography
									display={"flex"}
									alignItems={"center"}
								>
									Determinar segons meteo
									<Circle style={{ fill: orange[700] }} />
								</Typography>
							}
							onChange={() => setDisponible(3)}
						/>
						<FormControlLabel
							value={4}
							control={<Radio />}
							label={
								<Typography
									display={"flex"}
									alignItems={"center"}
								>
									Manteniment (tancat del tot)
									<Circle style={{ fill: red[400] }} />
								</Typography>
							}
							onChange={() => setDisponible(4)}
						/>
					</RadioGroup>
				</FormControl>
				<Box display={"flex"} mt={3}>
					<CustomButton
						title="Guardar"
						onClick={guardar}
						fullWidth
						loading={loading}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

import * as React from "react";
import { Box, Grid, IconButton, Input, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { Trash } from "react-feather";
import { useFieldArray } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	talla: {
		borderRadius: 20,
		padding: 10,
		marginBottom: 20,
		boxShadow: "0px 0px 8px 0px #00000030",
		display: "flex",
		alignItems: "center",
	},
	input: {
		marginLeft: 8,
		marginRight: 8,
	},
}));

export default function MaterialPreus({ control, register }) {
	const classes = useStyles();
	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "preus",
	});

	return (
		<Box>
			<Typography>Temps/Preu</Typography>
			<Box my={2} />
			{fields.map((item, index) => {
				return (
					<Box className={classes.talla} key={item.id}>
						<Grid container spacing={2}>
							<Grid item md={8}>
								<Input
									placeholder="Nom"
									type="text"
									{...register(`preus.${index}.nom`)}
									className={classes.input}
								/>
							</Grid>
							<Grid item md={4}>
								<Input
									placeholder="Preu"
									type="number"
									{...register(`preus.${index}.preu`)}
									className={classes.input}
								/>
							</Grid>
						</Grid>

						<IconButton onClick={() => remove(index)}>
							<Trash />
						</IconButton>
					</Box>
				);
			})}
			<Box textAlign="center">
				<IconButton
					onClick={() => append({ nom: "", quant: "" })}
					color="primary"
				>
					<Add />
				</IconButton>
				<IconButton onClick={() => replace([])}>
					<Trash />
				</IconButton>
			</Box>
		</Box>
	);
}

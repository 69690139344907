import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import CustomPublicField from '../../../../components/Activitats/CustomPublicField';
import CustomPublicButton from '../../../../components/CustomPublicButton';

export default function StepForm({
    register,
    errors,
    handleBack,
    handleNext,
    trigger,
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const submit = async () => {
        await trigger(['nom', 'phone', 'email']);
        if (Object.keys(errors).length === 0) {
            handleNext();
        }
    };

    return (
        <Box>
            <Typography variant="body1" fontWeight={800} pb={3}>
                {t('Dades personals')}
            </Typography>
            <Box className={classes.block}>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <CustomPublicField
                            name="nom"
                            label={t('Nom i cognoms')}
                            type="text"
                            errors={errors.nom}
                            register={register}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustomPublicField
                            name="phone"
                            label={t('Telèfon')}
                            type="text"
                            errors={errors.phone}
                            register={register}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustomPublicField
                            name="email"
                            label="E-mail"
                            type="email"
                            errors={errors.email}
                            register={register}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <CustomPublicField
                            name="observacions"
                            label={t('Observacions (opcional)')}
                            type="text"
                            errors={errors.observacions}
                            register={register}
                            rows={5}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    py: 8,
                }}
            >
                <CustomPublicButton
                    small
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    title={t('Enrere')}
                />
                <CustomPublicButton
                    onClick={submit}
                    small
                    title={t('Següent')}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    filters: {
        paddingTop: 100,
        paddingBottom: 200,
    },
    block: {
        '& .react-datepicker': {
            border: '1px solid ' + theme.palette.background.color,
            borderRadius: 5,
            padding: 20,
            marginBottom: 20,
        },
    },
}));

import { CircleTwoTone } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import nivellS from '../assets/img/nivell-s.svg';
import nivellN from '../assets/img/nivell-n.svg';
import theme from '../theme';
import { ReactComponent as Icon } from '../assets/img/nivell-s.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Raleway',
        textAlign: 'left',
        paddingRight: 10,
        paddingTop: 3,
    },

    icon: {
        marginRight: 4,
        '& .cls-ball-2': {
            opacity: 1,
            fill: theme.palette.background.main,
        },
        '& .cls-ball-1': {
            opacity: 1,
            fill: 'transparent',
        },
    },
    iconInv: {
        marginRight: 4,
        '& .cls-ball-2': {
            opacity: 1,
            fill: 'white',
        },
        '& .cls-ball-1': {
            opacity: 1,
            fill: 'transparent',
        },
    },
    iconAct: {
        marginRight: 4,
        '& .cls-ball-2': {
            opacity: 1,
            fill: theme.palette.background.main,
        },
        '& .cls-1': {
            fill: theme.palette.background.color,
        },
    },
    iconInvAct: {
        marginRight: 4,
        '& .cls-ball-2': {
            opacity: 1,
            fill: 'white',
        },
        '& .cls-ball-1': {
            opacity: 1,
            fill: theme.palette.background.color,
        },
    },
}));

export default function Nivell({ dificultat, invertit }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box display="flex" alignItems={'center'} mb={1}>
            <Typography
                variant={invertit ? 'h2' : 'h3'}
                textTransform="uppercase"
                className={classes.text}
                color={invertit ? theme.palette.text.secondary : ''}
            >
                {t('Nivell')}
            </Typography>
            <Icon
                className={
                    invertit
                        ? dificultat >= 1
                            ? classes.iconInvAct
                            : classes.iconInv
                        : dificultat >= 1
                        ? classes.iconAct
                        : classes.icon
                }
                width={15}
            />
            <Icon
                className={
                    invertit
                        ? dificultat >= 2
                            ? classes.iconInvAct
                            : classes.iconInv
                        : dificultat >= 2
                        ? classes.iconAct
                        : classes.icon
                }
                width={15}
            />
            <Icon
                className={
                    invertit
                        ? dificultat >= 3
                            ? classes.iconInvAct
                            : classes.iconInv
                        : dificultat >= 3
                        ? classes.iconAct
                        : classes.icon
                }
                width={15}
            />
            <Icon
                className={
                    invertit
                        ? dificultat >= 4
                            ? classes.iconInvAct
                            : classes.iconInv
                        : dificultat >= 4
                        ? classes.iconAct
                        : classes.icon
                }
                width={15}
            />
        </Box>
    );
}

import {
	CircularProgress,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import CustomPublicButton from "./CustomPublicButton";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { dataMaterial } from "../database/API";
import moment from "moment";
import { HelpCircle } from "react-feather";

export default function DialogMaterial({
	material,
	open,
	setOpen,
	setMaterialsSel,
	setTotal,
	data_ll,
}) {
	const classes = useStyles();
	const [talla, setTalla] = useState(material.talles[0]);
	const [preu, setPreu] = useState(material.preus[0]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const [max, setMax] = useState(material.talles[0].quant);
	const [quant, setQuant] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const { t, i18n } = useTranslation();

	const renderNom = () => {
		switch (i18n.language) {
			case "ca":
				return material?.nom;

			case "es":
				return material?.nom_es;

			case "en":
				return material?.nom_en;

			default:
				break;
		}
	};

	useEffect(() => {
		const get_count = async () => {
			if (open) {
				const m = material.talles[0].quant;

				const { count } = await dataMaterial(
					moment(data_ll).format("YYYY-MM-DD"),
					material.talles[0].id
				);

				m ? setMax(m - count) : setMax(null);
				setLoading(false);
			}
		};
		get_count();
	}, [open]);

	const changeTalla = async (e) => {
		setLoading(true);
		let m = material.talles.filter(
			(item) => item.id === Number(e.target.value)
		)?.[0]?.quant;
		setTalla(
			material.talles.filter(
				(item) => item.id === Number(e.target.value)
			)?.[0]
		);

		const { count } = await dataMaterial(
			moment(data_ll).format("YYYY-MM-DD"),
			e.target.value
		);

		m ? setMax(m - count) : setMax(null);
		setLoading(false);
	};

	const changePreu = (e) => {
		setPreu(
			material.preus.filter(
				(item) => item.id === Number(e.target.value)
			)?.[0]
		);
	};

	const changeQuant = (e) => {
		setQuant(e.target.value);
	};

	const afegir = () => {
		let lloguer = {
			material: material,
			talla: talla,
			preu: preu,
			quant: quant,
		};
		if (Number(quant) > max) {
			setError(t("No hi ha prou unitats"));
		} else {
			setError("");
			setMaterialsSel((prev) => [...prev, lloguer]);
			setOpen(false);
			setTotal((prev) => prev + Number(preu.preu) * quant);
			enqueueSnackbar(t("Material afegit a la cistella"), {
				variant: "success",
			});
			setQuant(0);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			maxWidth="md"
		>
			<DialogContent>
				<Grid container spacing={4}>
					<Grid item md={5}>
						<Box className={classes.body}>
							<img
								src={
									"https://pendolguies.covcontrol.net/public/storage/" +
									material?.foto
								}
								width={"100%"}
								alt={"Imatge"}
								role="presentation"
							/>
						</Box>
					</Grid>
					<Grid item md={7}>
						<Typography
							variant="h2"
							fontWeight={800}
							fontSize={29}
							mb={1}
						>
							{renderNom()}
						</Typography>
						<Typography variant="h3">
							{material?.categories?.map(
								(item, index) =>
									t(item.nom) +
									(index === material.categories.length - 1
										? ""
										: " / ")
							)}
						</Typography>
						<Typography variant="h2" my={2}>
							{preu?.preu} €
						</Typography>
						<Divider flexItem sx={{ width: "30%" }} />
						<Box my={4}>
							<Grid container spacing={3}>
								<Grid item md={4}>
									<FormControl
										fullWidth
										size="small"
										style={{ marginRight: 10 }}
									>
										<InputLabel>{t("talla")}</InputLabel>
										<Select
											native
											label={t("talla")}
											name={"talles"}
											onChange={changeTalla}
										>
											{material?.talles?.map((item) => (
												<option value={item.id}>
													{t(item.nom)}
												</option>
											))}
										</Select>
									</FormControl>
									<a href="/talles" target={"_blank"}>
										<Typography variant="caption">
											<HelpCircle size={12} />{" "}
											{t("Guia de talles")}
										</Typography>
									</a>
								</Grid>

								<Grid item md={4}>
									<FormControl
										fullWidth
										size="small"
										style={{ marginRight: 10 }}
									>
										<InputLabel>{t("temps")}</InputLabel>
										<Select
											native
											label={t("temps")}
											name={"preus"}
											onChange={changePreu}
										>
											{material?.preus?.map((item) => (
												<option value={item.id}>
													{t(item.nom)} - {item.preu}{" "}
													€
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={4}>
									<TextField
										label={t("unitats")}
										name={"unitats"}
										type={"number"}
										variant="outlined"
										onChange={changeQuant}
										disabled={max === null}
										fullWidth
										size="small"
										InputProps={{
											inputProps: { min: 1, max: max },
										}}
									/>
								</Grid>
							</Grid>
						</Box>

						<Divider flexItem sx={{ width: "30%" }} />

						{max !== null ? (
							<Box my={3}>
								{loading ? (
									<Box mb={3}>
										<CircularProgress size={20} />
									</Box>
								) : (
									<Typography mb={3}>
										{t("Queden", { num: max })}
									</Typography>
								)}
								<Divider flexItem sx={{ width: "30%" }} />
							</Box>
						) : (
							""
						)}

						<Box display="flex" mt={4}>
							<CustomPublicButton
								title={t("afegir a la cistella")}
								small
								onClick={afegir}
								disabled={!quant}
							/>
						</Box>
						{error !== "" ? (
							<Typography variant="error" mb={3}>
								{error}
							</Typography>
						) : (
							""
						)}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
const useStyles = makeStyles((theme) => ({
	wrap: {
		backgroundColor: "white",
		borderRadius: 10,
		textAlign: "left",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		border: "1px solid",
		transition: "0.2s",
		cursor: "pointer",
		"&:hover": {
			transform: "scale(1.05)",
			boxShadow:
				"0px 0px 11px 0px " + theme.palette.background.main + "90",
		},
	},
	header: {
		textAlign: "center",
		backgroundColor: theme.palette.background.color,
		borderRadius: "10px 10px 0 0",
		"& p": {
			fontWeight: 600,
		},
	},
	body: {
		textAlign: "center",
		paddingTop: 15,
		paddingBottom: 10,
		paddingLeft: 30,
		paddingRight: 30,
		"& p": {
			fontFamily: "Raleway",
			textAlign: "center",
		},
	},
}));

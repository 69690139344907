import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import CustomButton from './CustomButton';

export default function ModalDelete({
    title,
    description,
    open,
    setOpen,
    func,
}) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography>{description}</Typography>
            </DialogContent>
            <DialogActions>
                <CustomButton onClick={() => setOpen(false)} title="Tancar" />
                <CustomButton onClick={func} title="Eliminar" danger />
            </DialogActions>
        </Dialog>
    );
}

import React from "react";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import theme from "../../../theme";
import HomeStyle from "../../../theme/styles/HomeStyle";
import Destacades from "./Sections/Destacades";
import Logos from "./Sections/Logos";
import QueOferim from "./Sections/QueOferim";
import QuiSom from "./Sections/QuiSom";
import Slider from "./Sections/Slider";
import usePageTracking from "../../../layouts/TrakingGA";

const Home = () => {
	const classes = HomeStyle();
	usePageTracking();

	return (
		<Page title="Pèndol Guies" className={classes.main}>
			<Slider />
			<Sanefa color="white" />
			<QuiSom />
			<Sanefa color={theme.palette.background.third} />
			<QueOferim />
			<Sanefa color={theme.palette.background.third} inverted />
			<Destacades />
			<Sanefa color="white" />
			<Logos />
		</Page>
	);
};

export default Home;

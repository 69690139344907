import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Sanefa from "../../components/Sanefa";
import { ReactComponent as Icon } from "../../assets/img/logo-pendol.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();

	return (
		<Box className={classes.root}>
			<Sanefa color={"white"} fosc />

			<Box className={classes.main}>
				<Box className={classes.header}>
					<Box onClick={() => navigate("/")}>
						<Icon
							alt="Logo Pèndol Guies"
							width={matches ? 400 : 280}
							className={classes.logo}
						/>
					</Box>
					<Box
						display={"flex"}
						justifyContent="center"
						alignItems={"center"}
						flexDirection={matches ? "row" : "column"}
						mt={2}
					>
						<Box
							onClick={() => navigate("/avis-legal")}
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Avís Legal")}
							</Typography>
						</Box>

						<Box
							onClick={() => navigate("/politica-privacitat")}
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Política de privacitat")}
							</Typography>
						</Box>
						<Box
							onClick={() => navigate("/condicions-generals")}
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Condicions generals")}
							</Typography>
						</Box>
						<Box
							onClick={() => navigate("/condicions-activitats")}
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Condicions activitats")}
							</Typography>
						</Box>
						<Box
							onClick={() => navigate("/condicions-lloguer")}
							style={{
								textDecoration: "none",
								cursor: "pointer",
								paddingLeft: 10,
								paddingRight: 10,
							}}
						>
							<Typography color="white">
								{t("Condicions lloguer")}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Footer;

const useStyles = makeStyles((theme) => ({
	main: {
		backgroundColor: theme.palette.background.secondary,
		paddingTop: 100,
		paddingBottom: 100,
		marginTop: -5,
	},
	header: {
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
	},
	logo: {
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
}));

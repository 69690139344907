import { Avatar, Box, Chip, CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

export default function CustomChipMaterial({ item, handleDelete, index }) {
	const { i18n, t } = useTranslation();

	const renderNom = () => {
		switch (i18n.language) {
			case "ca":
				return item?.material?.nom;

			case "es":
				return item?.material?.nom_es;

			case "en":
				return item?.material?.nom_en;

			default:
				break;
		}
	};

	return (
		<Chip
			label={
				renderNom() +
				" (" +
				t(item?.talla?.nom) +
				") " +
				" x" +
				item.quant
			}
			icon={
				<Avatar
					src={
						"https://pendolguies.covcontrol.net/public/storage/" +
						item?.material?.foto
					}
					sx={{
						width: 24,
						height: 24,
					}}
				/>
			}
			onDelete={() => handleDelete(index)}
			id={index}
			style={{ margin: 5 }}
		/>
	);
}

import { Box, Container, Fade, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import Title from "../../../components/Layout/Title";
import { getElementsAdmin } from "../../../database/API";
import theme from "../../../theme";
import { Circle } from "react-feather";
import { useNavigate } from "react-router";
import Loading from "../../../components/Layout/Loading";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 80,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	block: {
		boxShadow: "#00000020 1px 3px 20px 1px ",
		borderRadius: 30,
		padding: 30,
		zIndex: 0,
		marginBottom: 80,
		backgroundColor: "white",
		overflow: "hidden",
		position: "relative",
	},
	calendar: {
		fontFamily: "Raleway",
		color: theme.palette.text.primary,
		marginBottom: 100,
		"& .fc-button-primary": {
			backgroundColor: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
				borderColor: theme.palette.primary.hover,
			},
		},
		"& .fc-scroller-harness-liquid": {
			height: "100%",
		},
	},
}));

const CalendariAdmin = () => {
	const classes = useStyles();
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const get = async () => {
			const reserves = await getElementsAdmin("reservesCal");
			const lloguers = await getElementsAdmin("lloguersCal");

			let events = [];
			reserves.result.map((item) =>
				events.push({
					title:
						item.id +
						" - " +
						item.nom +
						" (" +
						item.persones +
						" pax.)",
					start: item.data,
					color: theme.palette.text.primary,
					id: item.id,
					url: "/admin/reserves/view/" + item.id,
				})
			);
			lloguers.result.map((item) =>
				events.push({
					title: item.id + " - " + item.nom,
					start: item.data,
					color: theme.palette.text.secondary,
					textColor: theme.palette.text.primary,
					id: item.id,
					url: "/admin/lloguers/view/" + item.id,
				})
			);
			setEvents(events);
			setLoading(false);
		};

		get();
	}, []);

	return (
		<Box className={classes.container}>
			<Title title="Calendari" />

			<Box my={4} />
			<Loading loading={loading}>
				<Box p={3}>
					<Box my={3} />
					<div className={classes.calendar}>
						<FullCalendar
							plugins={[dayGridPlugin]}
							initialView="dayGridMonth"
							events={events}
							locale={"ca"}
							firstDay={1}
							buttonText={{ today: "avui" }}
							displayEventTime={false}
							eventClick={(args) => {
								navigate(args.event.url);
							}}
						/>
					</div>
					<Box>
						<Box display={"flex"} mb={2}>
							<Circle fill={theme.palette.background.color} />
							<Typography>Lloguer</Typography>
						</Box>
						<Box display={"flex"}>
							<Circle fill={theme.palette.text.primary} />
							<Typography>Activitats</Typography>
						</Box>
					</Box>
				</Box>
			</Loading>
		</Box>
	);
};

export default CalendariAdmin;

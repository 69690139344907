import React from "react";
import "../../../theme/styles/style.css";
import img from "../../../assets/img/talles.jpeg";
import { Box } from "@mui/system";
import Page from "../../../components/Layout/Page";

export default function Talles() {
	return (
		<Page title={"Pèndol Guies - "}>
			<Box>
				<img src={img} alt="Talles" />
			</Box>
		</Page>
	);
}

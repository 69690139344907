import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/cats/ferrades.jpg";
import Sanefa from "../../../components/Sanefa";
import { getElements } from "../../../database/API";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import ActivitatPublic from "../../../components/ActivitatPublic";
import LoadingSkeleton from "../../../components/Layout/LoadingSkeleton";
import ActivitatsSkeleton from "../../../components/Skeletons/ActivitatsSkeleton";
import CustomPublicButton from "../../../components/CustomPublicButton";
import Page from "../../../components/Layout/Page";
import familia from "../../../assets/img/familia-01.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const Families = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [activitats, setActivitats] = useState([]);
	const matches = useMediaQuery("(min-width:960px)");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		const get = async () => {
			const act = await getElements(
				"activitats?lang_ab=" + i18n.language + "&cat=families"
			);
			setActivitats(act.result);
			setLoading(false);
		};
		get();
	}, [i18n.language]);

	return (
		<Page className={classes.main} title={"Pèndol Guies - Famílies"}>
			<Header title={t("Famílies")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.familia}>
				<Container>
					<Box
						display={matches ? "flex" : "auto"}
						alignItems="center"
					>
						<Box mx={4}>
							<Typography variant="h1" textAlign="left" mb={6}>
								{t("Activitats familiars")}
							</Typography>
							<Typography variant="body1" textAlign="left" mb={6}>
								{t(
									"A Pèndol Guies som especialistes en el turisme familiar. Ens encanta poder oferir tot un ventall d’activitats adreçades aquelles famílies interessades a tastar l’aventura i l’adrenalina de la forma més segura, didàctica i sobretot divertida."
								)}
							</Typography>
							<Typography variant="body1" textAlign="left" mb={6}>
								{t(
									"Si com nosaltres, voleu apostar per un turisme familiar i sostenible podeu consultar totes les nostres recomanacions o contactar amb nosaltres per realitzar qualsevol altre que no surti a priori al nostre catàleg."
								)}
							</Typography>
						</Box>
						<Box mx={4}>
							<img
								src={familia}
								alt={t("Activitat familiar")}
								width={150}
							/>
						</Box>
					</Box>
				</Container>
			</Box>
			<Sanefa color={theme.palette.background.main} />
			<Box className={classes.activitats}>
				<Container>
					<LoadingSkeleton
						loading={loading}
						skeleton={<ActivitatsSkeleton />}
					>
						<Grid container spacing={4}>
							{activitats?.map((item) => (
								<ActivitatPublic activitat={item} />
							))}
						</Grid>
					</LoadingSkeleton>{" "}
				</Container>
			</Box>
			<Sanefa color="white" />
			<Box className={classes.buscant}>
				<Typography variant="h1">
					{t("No has trobat el què estaves buscant?")}
				</Typography>
				<Box my={2} />
				<Typography variant="body1">
					{t(
						"Contacta amb nosaltres i t'oferirem solucions o alternatives similars"
					)}
				</Typography>
				<Box my={8} />
				<CustomPublicButton
					onClick={() => navigate("/contacte")}
					title={t("Contacta'ns")}
				/>
			</Box>
		</Page>
	);
};

export default Families;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	activitats: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
	familia: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: "white",
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 180,
		textAlign: "center",
	},
}));

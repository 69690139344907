import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function CategoriesHomeSkeleton() {
    return (
        <Grid container spacing={8}>
            <CategoriaSkeleton />
            <CategoriaSkeleton />
            <CategoriaSkeleton />
            <CategoriaSkeleton />
            <CategoriaSkeleton />
            <CategoriaSkeleton />
        </Grid>
    );
}

const CategoriaSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid item md={4}>
            <Box className={classes.wrap}>
                <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                />
                <Typography variant="h3" textTransform="uppercase" padding={1}>
                    <Skeleton />
                </Typography>
            </Box>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: 'white',
        border: '2px solid white',
        borderRadius: 15,
        cursor: 'pointer',
        overflow: 'hidden',
        transition: '0.2s',
    },
    img: {
        width: '100%',
    },
    imgWrap: {
        height: 200,
    },
}));

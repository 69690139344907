import {
	Box,
	CircularProgress,
	Container,
	Fade,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/portada-activitats.jpg";
import Sanefa from "../../../components/Sanefa";
import { getElements } from "../../../database/API";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import ActivitatPublic from "../../../components/ActivitatPublic";
import CategoriaFiltre from "../../../components/CategoriaFiltre";
import NivellFiltre from "../../../components/NivellFiltre";
import LoadingSkeleton from "../../../components/Layout/LoadingSkeleton";
import CategoriesSkeleton from "../../../components/Skeletons/CategoriesSkeleton";
import ActivitatsSkeleton from "../../../components/Skeletons/ActivitatsSkeleton";
import { useLocation, useNavigate } from "react-router";
import CustomPublicButton from "../../../components/CustomPublicButton";
import Page from "../../../components/Layout/Page";
import { useTranslation } from "react-i18next";

const dificultats = [
	{ nom: "", id: 1 },
	{ nom: "", id: 2 },
	{ nom: "", id: 3 },
	{ nom: "", id: 4 },
];

const Activitats = () => {
	const classes = useStyles();
	const { state } = useLocation();
	const [loading, setLoading] = useState(true);
	const [loadingAct, setLoadingAct] = useState(true);
	const [categories, setCategories] = useState([]);
	const [categoria, setCategoria] = useState(state ? state : "");
	const [dificultat, setDificultat] = useState("");
	const [activitats, setActivitats] = useState([]);
	const matches = useMediaQuery("(min-width:960px)");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		const get = async () => {
			const categori_sel = await localStorage.getItem("cat");
			const cat = await getElements("categories");
			setCategories(cat.result);
			setLoading(false);
			if (!state) {
				let act = [];
				if (categori_sel) {
					console.log("Amb sel" + categori_sel);
					setCategoria(Number(categori_sel));
					act = await getElements(
						"activitats?lang_ab=" +
							i18n.language +
							"&cat=" +
							categori_sel
					);
				} else {
					console.log("sense sel");

					act = await getElements(
						"activitats?lang_ab=" + i18n.language
					);
				}

				setActivitats(act.result);
			}
			setLoadingAct(false);
		};
		get();
	}, []);

	useEffect(() => {
		const get = async () => {
			setLoadingAct(true);
			const act = await getElements(
				"activitats?lang_ab=" +
					i18n.language +
					"&cat=" +
					categoria +
					"&dif=" +
					dificultat
			);
			setActivitats(act.result);
			setLoadingAct(false);
		};
		get();
	}, [categoria, dificultat, i18n.language]);

	const change = (id) => {
		if (id) {
			localStorage.setItem("cat", id);
		} else {
			localStorage.removeItem("cat");
		}

		setCategoria(id);
	};

	return (
		<Page
			className={classes.main}
			title={"Pèndol Guies - " + t("Activitats")}
		>
			<Header title={t("Activitats")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.filters}>
				<Container>
					<Typography variant="h1">{t("Categories")}</Typography>
					<LoadingSkeleton
						loading={loading}
						skeleton={<CategoriesSkeleton />}
					>
						<Box
							my={4}
							display={matches ? "flex" : "auto"}
							justifyContent={"space-between"}
						>
							<CategoriaFiltre
								set={change}
								categoria={categoria}
							/>
							{categories?.map((item) => (
								<CategoriaFiltre
									cat={item}
									set={change}
									categoria={categoria}
								/>
							))}
						</Box>
					</LoadingSkeleton>
					<Typography variant="h1">{t("Nivell")}</Typography>
					<Box mt={4} display="flex" justifyContent={"flex-start"}>
						<NivellFiltre
							set={setDificultat}
							dificultat={dificultat}
						/>
						{dificultats?.map((item) => (
							<NivellFiltre
								nivell={item}
								set={setDificultat}
								dificultat={dificultat}
							/>
						))}
					</Box>
				</Container>
			</Box>
			<Sanefa color={theme.palette.background.main} />
			<Box className={classes.activitats}>
				<Container>
					<Typography
						variant="h1"
						color="white"
						textAlign="left"
						mb={6}
					>
						{t(
							categories?.filter(
								(item) => item.id === categoria
							)[0]?.nom
						)}
					</Typography>
					<Typography
						variant="body1"
						color="white"
						textAlign="left"
						mb={6}
					>
						{t(
							categories?.filter(
								(item) => item.id === categoria
							)[0]?.descripcio
						)}
					</Typography>
					<LoadingSkeleton
						loading={loadingAct}
						skeleton={<ActivitatsSkeleton />}
					>
						<Grid container spacing={5}>
							{activitats?.map((item) => (
								<ActivitatPublic activitat={item} />
							))}
						</Grid>
					</LoadingSkeleton>{" "}
				</Container>
			</Box>
			<Sanefa color="white" />
			<Box className={classes.buscant}>
				<Typography variant="h1">
					{t("No has trobat el què estaves buscant?")}
				</Typography>
				<Box my={2} />
				<Typography variant="body1">
					{t(
						"Contacta amb nosaltres i t'oferirem solucions o alternatives similars"
					)}
				</Typography>
				<Box my={8} />
				<CustomPublicButton
					onClick={() => navigate("/contacte")}
					title={t("Contacta'ns")}
				/>
			</Box>
		</Page>
	);
};

export default Activitats;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	activitats: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 180,
		textAlign: "center",
	},
}));

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import ReactDatePicker from 'react-datepicker';
import CustomPublicButton from '../../../../components/CustomPublicButton';
import i18n from '../../../../lang';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function StepData({
    setValue,
    setDate,
    date,
    handleBack,
    handleNext,
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const onChangeDate = (date) => {
        setValue('data', moment(date[0]).format('YYYY-MM-DD'));
        setDate(date[0]);
    };

    return (
        <Box>
            <Typography variant="body1" fontWeight={800} pb={3}>
                {t('Seleccionar data')}
            </Typography>
            <Box className={classes.block}>
                <ReactDatePicker
                    onChange={onChangeDate}
                    selected={date}
                    selectsRange
                    selectsDisabledDaysInRange
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    inline
                    locale={i18n.language}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    py: 8,
                }}
            >
                <CustomPublicButton
                    small
                    disabled
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    title={t('Enrere')}
                />
                <CustomPublicButton
                    onClick={handleNext}
                    small
                    disabled={!date}
                    title={t('Següent')}
                />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    filters: {
        paddingTop: 100,
        paddingBottom: 200,
    },
    block: {
        '& .react-datepicker': {
            border: '1px solid ' + theme.palette.background.color,
            borderRadius: 5,
            padding: 20,
            marginBottom: 20,
        },
    },
}));

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaActivitat } from "../../../components/Schemas";
import CustomCard from "../../../components/CustomCard";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import ImageInput from "../../../components/ImageInput";
import { createMaterial, getElements } from "../../../database/API";
import { useSnackbar } from "notistack";
import Loading from "../../../components/Layout/Loading";
import Thumb from "../../../components/Thumb";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import Talles from "../../../components/Materials/Talles";
import Title from "../../../components/Layout/Title";
import MaterialPreus from "../../../components/Materials/Preus";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	talla: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
}));

const MaterialAdd = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors },
		trigger,
		getValues,
		control,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaActivitat),
	});

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("categoria_materials");
			setCategories(result);
			setLoading(false);
		};
		get();
	}, []);

	const enviar = async (values) => {
		setLoading(true);
		const message = await createMaterial(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	return (
		<Box className={classes.container}>
			<Title title="Afegir material" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<form onSubmit={handleSubmit(enviar)}>
							<CustomCard
								title="Entra les dades"
								footer={
									<CustomButton
										type="submit"
										title={"Crear material"}
									/>
								}
							>
								<Grid container spacing={3}>
									<Grid item md={3}>
										<Thumb file={getValues("foto")} />

										<ImageInput
											name="foto"
											register={register}
											trigger={trigger}
											getValues={getValues}
											multiple={false}
										/>
									</Grid>
									<Grid item md={6}>
										<CustomTextField
											name="nom"
											label="Nom del material (Català)"
											type="text"
											errors={errors.nom}
											register={register}
										/>
										<Box my={1} />
										<CustomTextField
											name="nom_es"
											label="Nom del material (Español)"
											type="text"
											errors={errors.nom_es}
											register={register}
										/>
										<Box my={1} />
										<CustomTextField
											name="nom_en"
											label="Nom del material (English)"
											type="text"
											errors={errors.nom_en}
											register={register}
										/>
										<Box my={1} />
										<CustomMultiSelect
											elements={categories}
											name="categories"
											label="Categoria"
											register={register}
											control={control}
										/>
									</Grid>
									<Grid item md={3}>
										<Talles
											register={register}
											control={control}
										/>
										<Divider
											sx={{
												marginTop: 2,
												marginBottom: 2,
											}}
										/>
										<MaterialPreus
											register={register}
											control={control}
										/>
									</Grid>
								</Grid>
							</CustomCard>
						</form>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default MaterialAdd;

import { Box, Chip, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/escoles.jpg";
import via from "../../../assets/img/escoles/via1.jpg";
import canoa from "../../../assets/img/escoles/canoa1.jpg";
import barranc from "../../../assets/img/escoles/barrancs1.jpg";
import escalada from "../../../assets/img/escoles/escalada1.jpeg";
import jocs from "../../../assets/img/escoles/jocs.jpg";
import orientacio from "../../../assets/img/escoles/orientacio.jpeg";
import trekking from "../../../assets/img/escoles/trekking.jpeg";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import CustomPublicButton from "../../../components/CustomPublicButton";
import Page from "../../../components/Layout/Page";
import pdf from "../../../assets/docs/Cataleg-escolar-Pendol-guies.pdf";
import { useTranslation } from "react-i18next";

const Escoles = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Page className={classes.main} title={"Pèndol Guies - " + t("Escoles")}>
			<Header title={t("Escoles")} img={img} />
			<Sanefa color="white" />
			<Box className={classes.familia}>
				<Container>
					<Typography variant="h1" textAlign="center" mb={8}>
						{t("Activitats per a escoles")}
					</Typography>
					<Grid container spacing={3}>
						<Grid item md={12}>
							<Typography
								variant="body1"
								textAlign="center"
								mb={6}
							>
								{t(
									"Estem especialitzats en portar grans grups escolars i realitzar diverses activitats d’aventura. Posa’t en contacte amb nosaltres per demanar més informació i poder personalitzar una o diverses activitats segons les característiques de la teva escola o grup de persones. Oferim les següents activitats:"
								)}
							</Typography>
							{/* <Box textAlign="left">
								<Pastilla title={t("Vies ferrades")} />
								<Pastilla title={t("Canoes")} />
								<Pastilla title={t("Barranquisme")} />
								<Pastilla title={t("Escalada")} />
								<Pastilla title={t("Orientació")} />
								<Pastilla title={t("Trekking")} />
								<Pastilla title={t("Jocs de cohesió")} />
							</Box> */}
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={via}
										alt={"Vies ferrades"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Vies ferrades")}
								</Typography>
							</Box>
						</Grid>

						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={canoa}
										alt={"Canoes"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Canoes")}
								</Typography>
							</Box>
						</Grid>

						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={barranc}
										alt={"Barranquisme"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Barranquisme")}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid container spacing={3} mt={1}>
						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={escalada}
										alt={"Escalada"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Escalada")}
								</Typography>
							</Box>
						</Grid>

						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={orientacio}
										alt={"Orientació"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Orientació")}
								</Typography>
							</Box>
						</Grid>

						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={trekking}
										alt={"Trekking"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Trekking")}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid container spacing={3} mt={1}>
						<Grid item md={4}></Grid>

						<Grid item md={4}>
							<Box className={classes.wrap}>
								<Box className={classes.imgWrap}>
									<img
										src={jocs}
										alt={"Jocs de cohesió"}
										className={classes.img}
									/>
								</Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
									padding={1}
								>
									{t("Jocs de cohesió")}
								</Typography>
							</Box>
						</Grid>

						<Grid item md={4}></Grid>
					</Grid>
					{/* <Document
						file={"./sample.pdf"}
						onLoadSuccess={onDocumentLoadSuccess}
						options={options}
					>
						<PagePDF pageNumber={pageNumber} />
					</Document>
					<p>
						Page {pageNumber} of {numPages}
					</p> */}
					<Box mt={10} textAlign="center">
						<CustomPublicButton
							href={pdf}
							title={t("Descarregar PDF")}
							download
							target="_blank"
						/>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

export default Escoles;

const Pastilla = ({ title }) => {
	const classes = useStyles();
	return <Chip variant="outlined" label={title} className={classes.chip} />;
};

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	activitats: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
	familia: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: "white",
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 180,
		textAlign: "center",
	},
	chip: {
		backgroundColor: theme.palette.background.color,
		margin: 10,
		"& .MuiChip-label": {
			fontSize: 20,
			padding: 20,
		},
	},
	wrap: {
		backgroundColor: theme.palette.background.color,
		border: "2px solid white",
		borderRadius: 15,
		// cursor: "pointer",
		overflow: "hidden",
		transition: "0.2s",
		// "&:hover": {
		// 	transform: "scale(1.05)",
		// 	boxShadow: "0px 0px 5px 0px " + theme.palette.background.main,
		// 	backgroundColor: theme.palette.background.main,
		// 	"& .MuiTypography-root": {
		// 		transition: "0.2s",
		// 		color: "white",
		// 	},
		// },
	},
	img: {
		width: "100%",
	},
	imgWrap: {
		height: 200,
		overflow: "hidden",
	},
}));

import { CheckCircleTwoTone } from "@mui/icons-material";
import { Avatar, Chip, IconButton, Typography } from "@mui/material";
import { Edit } from "react-feather";
import { useNavigate } from "react-router";
import Dificultat from "../../../components/Dificultat";
import theme from "../../../theme";

const ActivitatsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "imatges",
			label: "Imatge",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						variant="circular"
						src={
							value &&
							"https://pendolguies.covcontrol.net/public/storage/" +
								value?.[0]?.foto
						}
					/>
				),
			},
		},
		{
			name: "langs",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				sortCompare: (order) => {
					return ({ data: a }, { data: b }) => {
						const a_val = a[0].nom;
						const b_val = b[0].nom;
						return (
							(a_val < b_val ? -1 : 1) *
							(order === "desc" ? 1 : -1)
						);
					};
				},
				customBodyRender: (value) => value?.[0].nom,
			},
		},
		{
			name: "categoria.nom",
			label: "Categoria",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => <Chip label={value} />,
			},
		},
		{
			name: "preus",
			label: "Preus",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) =>
					value?.map((item) => {
						return (
							<Typography variant="body2">
								De {item.min} a {item.max}:{" "}
								<strong>{item.preu}</strong> €
							</Typography>
						);
					}),
			},
		},
		{
			name: "dificultat",
			label: "Dificultat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <Dificultat value={value} />,
			},
		},

		{
			name: "destacada",
			label: "Destacada",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					value === "1" ? (
						<CheckCircleTwoTone
							color={theme.palette.background.color}
						/>
					) : (
						""
					),
			},
		},

		{
			name: "familia",
			label: "Familiar",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					value === "1" ? (
						<CheckCircleTwoTone
							color={theme.palette.background.color}
						/>
					) : (
						""
					),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/activitats/edit/${activitats[dataIndex].id}`,
									{
										state: activitats[dataIndex],
									}
								)
							}
						>
							<Edit />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default ActivitatsColumns;

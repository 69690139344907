import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ActivitatSkeleton = () => {
    const classes = useStyles();

    return (
        <Box>
            <Typography variant="h1" color="white" mb={5}>
                <Skeleton sx={{ bgcolor: 'grey.600', width: '60%' }} />
            </Typography>
            <Box display="flex" alignItems="center">
                <Box mr={5}>
                    <Typography variant="h2" textTransform="uppercase">
                        <Skeleton sx={{ bgcolor: 'grey.600', width: 200 }} />
                    </Typography>
                    <Skeleton sx={{ bgcolor: 'grey.600' }} />
                </Box>

                <Box>
                    <Skeleton
                        sx={{ bgcolor: 'grey.600', width: 50, height: 50 }}
                        variant="circular"
                    />
                </Box>
            </Box>

            <Typography variant="body1" color="white" my={5}>
                <Skeleton sx={{ bgcolor: 'grey.600', height: 100 }} />
            </Typography>
            <Grid container spacing={5}>
                <Grid item md={4}>
                    <Skeleton sx={{ bgcolor: 'grey.600', height: 80 }} />

                    <Box p={3}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Box my={3} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Box my={3} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Box my={3} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Box my={3} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Skeleton sx={{ bgcolor: 'grey.600', height: 80 }} />

                    <Box p={3}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Skeleton sx={{ bgcolor: 'grey.600', height: 80 }} />

                    <Box p={3}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                        <Box my={3} />

                        <Skeleton sx={{ bgcolor: 'grey.600' }} />

                        <Skeleton sx={{ bgcolor: 'grey.600' }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
export default ActivitatSkeleton;

const useStyles = makeStyles((theme) => ({
    main: {
        overflowX: 'hidden',
    },
    filters: {
        paddingTop: 100,
        paddingBottom: 150,
    },
    activitats: {
        paddingTop: 100,
        paddingBottom: 150,
        background: theme.palette.background.gradient,
        marginTop: -5,
    },
    buscant: {
        paddingTop: 150,
        paddingBottom: 150,
        textAlign: 'center',
    },
    header: {
        border: '1px solid white',
        borderRadius: 50,
        padding: 15,
        paddingLeft: 20,
    },
    label: {
        color: 'white',
        fontWeight: 800,
    },
    value: {
        color: 'white',
    },
    imageWrap: {
        height: 200,
    },
    slide: {
        paddingBottom: 50,
        paddingTop: 50,
        '& .alice-carousel__stage-item div': {
            width: '100%',
        },
    },
    left: {
        position: 'absolute',
        left: -60,
        top: '35%',
        width: 64,
        height: 64,
    },
    right: {
        position: 'absolute',
        right: -60,
        top: '35%',
        width: 64,
        height: 64,
    },
}));

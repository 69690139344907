import {
	Box,
	Checkbox,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/cats/ponting.jpg";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router";
import IconList from "../../../components/Activitats/IconList";
import { Activity, Calendar, MapPin } from "react-feather";
import { Payments } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaRes } from "../../../components/Schemas";
import CustomPublicField from "../../../components/Activitats/CustomPublicField";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import ca from "date-fns/locale/ca";
import moment from "moment";
import "../../../theme/styles/style.css";
import i18n from "../../../lang";
import CustomPublicButton from "../../../components/CustomPublicButton";
import { createReserva, getPrice } from "../../../database/API";
import Page from "../../../components/Layout/Page";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

registerLocale("es", es);
registerLocale("ca", ca);

const Reserva = () => {
	const classes = useStyles();
	const { state } = useLocation();
	const { schemaReserva } = SchemaRes();
	const [date, setDate] = useState("");
	const [preu, setPreu] = useState(0);
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [formCompra, setForm] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		setValue("activitat_id", state?.id);
	}, [state]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaReserva),
	});

	const persones = watch("persones");

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			if (persones >= 2 && persones <= 8) {
				const { result } = await getPrice(persones, state.id);
				console.log(result);
				setPreu(result.data);
				setValue("preu", result.data);
			} else {
				setPreu(0);
				setValue("preu", 0);
			}
			setLoading(false);
		};
		get();
	}, [persones, date]);

	const enviar = async (values) => {
		// console.log(values);
		setLoading(true);
		const { form, message } = await createReserva(values);
		setForm(form);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setTimeout(() => document.forms["redsys_form"].submit(), 500);
		reset();
		setLoading(false);
	};

	const onChangeDate = (date) => {
		setValue("data", moment(date[0]).format("YYYY-MM-DD"));
		setDate(date[0]);
	};

	return (
		<Page title={"Pèndol Guies - " + t("Reserva")} className={classes.main}>
			<Header title="Reserva" img={img} small />
			<Sanefa color="white" />
			<Box className={classes.filters}>
				{parse(formCompra ? formCompra.data : "")}

				<form onSubmit={handleSubmit(enviar)}>
					<Container>
						{/* <Typography variant="h1">Ompla les dades</Typography> */}
						<Grid container spacing={5}>
							<Grid item md={8} xs={12}>
								<Grid container spacing={3}>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="nom"
											label={t("Nom i cognoms")}
											type="text"
											errors={errors.nom}
											register={register}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<CustomPublicField
											name="phone"
											label={t("Telèfon")}
											type="text"
											errors={errors.phone}
											register={register}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<CustomPublicField
											name="email"
											label="E-mail"
											type="email"
											errors={errors.email}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12} padding={0}>
										<CustomPublicField
											name="persones"
											label={t("Número de persones")}
											type="number"
											defaultValue={2}
											errors={errors.persones}
											register={register}
											InputProps={{
												inputProps: { min: 2 },
											}}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="observacions"
											label={t("Observacions (opcional)")}
											type="text"
											errors={errors.observacions}
											register={register}
											rows={5}
											multiline
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<Box className={classes.block}>
											<Typography
												variant="h6"
												textTransform="uppercase"
											>
												{t("Data de reserva")}
											</Typography>
											<IconList
												label={
													date
														? moment(date).format(
																"DD/MM/YYYY"
														  )
														: "..."
												}
												icon={<Calendar />}
												pb={2}
											/>
											{errors?.data ? (
												<Typography variant="error">
													{errors?.data?.message}
												</Typography>
											) : (
												""
											)}
										</Box>
										<FormControlLabel
											control={
												<Checkbox
													onChange={(e) => {}}
													name="accepta"
													{...register("accepta")}
													required
												/>
											}
											label={
												<Typography>
													{t(
														"He llegit i accepto la"
													)}{" "}
													<a
														href="/politica-privacitat"
														target="_blank"
														alt="Política de Privacitat"
														rel="noreferrer"
													>
														{t(
															"Política de Privacitat"
														)}
													</a>
													{t(" i les ")}
													<a
														href="/condicions-activitats"
														target="_blank"
														alt="Condicions d'activitat"
														rel="noreferrer"
													>
														{t(
															"Condicions activitats"
														)}
													</a>
												</Typography>
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={4}>
								<Box className={classes.block}>
									<Typography
										variant="h6"
										textTransform="uppercase"
									>
										{t("Resum activitat")}
									</Typography>
									<IconList
										label={state?.langs?.[0]?.nom}
										icon={<Activity />}
										pb={2}
									/>
									<IconList
										label={state?.situacio}
										icon={<MapPin />}
										pb={2}
									/>

									<IconList
										label={state?.preus.map((item, index) =>
											parse(
												(state?.categoria_id !== "4"
													? t("de_a_persones", {
															min: item.min,
															max: item.max,
													  })
													: t("Salt nº") +
													  " " +
													  (index + 1)) +
													" " +
													item.preu +
													"€/" +
													(item.unic === "1"
														? t("preu tancat")
														: t("persona")) +
													"<br/>"
											)
										)}
										icon={<Payments />}
										pb={2}
									/>
								</Box>

								<Box className={classes.blockDate}>
									<Typography
										variant="h6"
										textTransform="uppercase"
										pl={2}
									>
										{t("Seleccionar data")}
									</Typography>
									<DatePicker
										onChange={onChangeDate}
										selected={date}
										selectsRange
										selectsDisabledDaysInRange
										dateFormat="dd/MM/yyyy"
										minDate={new Date()}
										inline
										locale={i18n.language}
									/>
								</Box>
							</Grid>
						</Grid>
						<Box
							className={classes.blockTotal}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box>
								<Typography
									variant="h1"
									fontSize={30}
									color="white"
									display="flex"
								>
									{t("Total")}:{" "}
									{loading ? (
										<CircularProgress
											color="secondary"
											size={30}
											style={{ marginLeft: 20 }}
										/>
									) : (
										preu + "€"
									)}
								</Typography>
							</Box>
							<CustomPublicButton
								type="submit"
								title={t("Reservar i pagar")}
								loading={loading}
							/>
						</Box>
					</Container>
				</form>
			</Box>
		</Page>
	);
};

export default Reserva;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	block: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		padding: 20,
		marginBottom: 20,
	},

	blockTotal: {
		backgroundColor: theme.palette.background.third,
		borderRadius: 5,
		padding: 20,
		marginBottom: 20,
	},

	blockDate: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		paddingTop: 20,
		marginBottom: 20,
	},
}));

import { Box, Fade, Paper, Popper } from "@mui/material";
import { X } from "react-feather";
import theme from "../theme";

export default function CustomPopper({ children, setPopper, ...rest }) {
	return (
		// <ClickAwayListener onClickAway={() => setPopper(false)}>
		<Popper
			transition
			{...rest}
			modifiers={[
				{
					name: "flip",
					enabled: true,
					options: {
						altBoundary: true,
						rootBoundary: "document",
						padding: 8,
					},
				},
				{
					name: "preventOverflow",
					enabled: false,
					options: {
						altAxis: true,
						rootBoundary: "viewport",
						padding: 8,
					},
				},
				{
					name: "arrow",
					enabled: true,
				},
			]}
		>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps}>
					<Paper
						sx={{
							padding: 3,
							marginTop: 1,
							borderRadius: 2,
							width: 280,
							boxShadow:
								"0px 0px 11px 0px " +
								theme.palette.background.main +
								"90",
						}}
					>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
						>
							{children}{" "}
							<Box
								onClick={() => setPopper(false)}
								position="absolute"
								top={10}
								right={5}
							>
								<X />
							</Box>
						</Box>
					</Paper>
				</Fade>
			)}
		</Popper>
		// </ClickAwayListener>
	);
}

import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function MaterialsSkeleton() {
    return (
        <Grid container spacing={6}>
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
            <MaterialSkeleton />
        </Grid>
    );
}

const MaterialSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid item md={3} sm={4} xs={12}>
            <Box className={classes.wrap}>
                <Box>
                    <Box className={classes.header}>
                        <Typography variant="body1" padding={1}>
                            <Skeleton />
                        </Typography>
                    </Box>
                    <Box className={classes.body}>
                        <Skeleton
                            sx={{ height: 182 }}
                            animation="wave"
                            variant="rectangular"
                        />
                    </Box>
                </Box>
                <Typography variant="body1" textAlign="center" pb={1}>
                    <Skeleton />
                </Typography>
            </Box>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: 'white',
        borderRadius: 10,
        textAlign: 'left',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid',
    },
    header: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.color,
        borderRadius: '10px 10px 0 0',
        '& p': {
            fontWeight: 600,
        },
    },
    body: {
        textAlign: 'center',
        paddingTop: 15,
        paddingBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
        '& p': {
            fontFamily: 'Raleway',
            textAlign: 'center',
        },
    },
}));

import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, styled } from '@mui/styles';
import theme from '../../../theme';
import { Box } from '@mui/system';

const Custom = styled(Step)({
    '& .MuiSvgIcon-root': {
        width: '3rem',
        height: '3rem',
    },
    '& .MuiStepConnector-root': {
        top: 24,
        left: 'calc(-50% + 50px)',
        right: 'calc(50% + 50px)',
    },
    '& .MuiStepIcon-text': {
        fontFamily: 'DM Serif Display',
        fontSize: '1rem',
    },
    '&.Mui-completed': {
        '& .MuiSvgIcon-root': {
            color: theme.palette.background.color,
        },
    },
    '& .MuiStepLabel-label': {
        '&.Mui-active': {
            fontWeight: 700,
        },
        fontFamily: 'Raleway',
        fontSize: '0.8rem',
    },
});

const CustomStep = ({ activeStep, steps }) => {
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                return (
                    <Custom key={label}>
                        <StepLabel>{label}</StepLabel>
                        <Box my={1}>
                            <hr style={{ width: '80%' }} />
                        </Box>
                    </Custom>
                );
            })}
        </Stepper>
    );
};

export default CustomStep;

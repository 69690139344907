import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getElements } from "../../../../database/API";
import ActivitatPublic from "../../../../components/ActivitatPublic";
import LoadingSkeleton from "../../../../components/Layout/LoadingSkeleton";
import ActivitatsSkeleton from "../../../../components/Skeletons/ActivitatsSkeleton";
import { useTranslation } from "react-i18next";

export default function Destacades() {
	const classes = useStyles();
	const [activitats, setActivitats] = useState([]);
	const [loading, setLoading] = useState(true);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements(
				"activitatsDes?lang_ab=" + i18n.language
			);
			setActivitats(result);
			setLoading(false);
		};
		get();
	}, [i18n.language]);

	return (
		<Box className={classes.container}>
			<Container maxWidth="lg">
				<Typography
					variant="h1"
					textAlign="center"
					marginBottom={8}
					color="white"
				>
					{t("Destacades")}
				</Typography>
				<LoadingSkeleton
					loading={loading}
					skeleton={<ActivitatsSkeleton />}
				>
					<Grid container spacing={4}>
						{activitats?.map((item) => (
							<ActivitatPublic activitat={item} />
						))}
					</Grid>
				</LoadingSkeleton>
			</Container>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 100,
		paddingBottom: 190,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
}));

import { Box, IconButton } from "@mui/material";
import React from "react";
import { Facebook, Instagram } from "react-feather";

const XXSS = ({ white }) => {
	return (
		<Box m={white ? 0 : 2}>
			<IconButton
				onClick={() =>
					window.open(
						"https://www.instagram.com/pendolguies/",
						"_blank"
					)
				}
				color={white ? "inherit" : "primary"}
			>
				<Instagram size={16} />
			</IconButton>
			<IconButton
				onClick={() =>
					window.open(
						"https://www.facebook.com/pendolguies",
						"_blank"
					)
				}
				color={white ? "inherit" : "primary"}
			>
				<Facebook size={16} />
			</IconButton>
		</Box>
	);
};

export default XXSS;

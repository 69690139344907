import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import barranquisme from '../assets/img/cats/barranquisme.jpg';
import ferrades from '../assets/img/cats/ferrades.jpg';
import altres from '../assets/img/cats/altres.jpg';
import ponting from '../assets/img/cats/ponting.jpg';
import raquetes from '../assets/img/cats/raquetes.jpg';
import trekking from '../assets/img/cats/trekking.jpg';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: 'white',
        border: '2px solid white',
        borderRadius: 15,
        cursor: 'pointer',
        overflow: 'hidden',
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 0px 5px 0px ' + theme.palette.background.main,
            backgroundColor: theme.palette.background.main,
            '& .MuiTypography-root': {
                transition: '0.2s',
                color: 'white',
            },
        },
    },
    img: {
        width: '100%',
    },
    imgWrap: {
        height: 200,
        overflow: 'hidden',
    },
}));

export default function CategoriaHome({ cat }) {
    const classes = useStyles();
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Grid item md={4}>
            <Box
                onClick={() =>
                    navigate('/activitats', {
                        state: cat.id,
                    })
                }
                className={classes.wrap}
            >
                <Imatge cat={cat.nom} />
                <Typography variant="h3" textTransform="uppercase" padding={1}>
                    {t(cat.nom)}
                </Typography>
            </Box>
        </Grid>
    );
}

const Imatge = ({ cat }) => {
    const [img, setImg] = useState('');
    const classes = useStyles();
    useEffect(() => {
        switch (cat) {
            case 'Barranquisme':
                setImg(barranquisme);
                break;
            case 'Trekking':
                setImg(trekking);
                break;

            case 'Vies ferrades':
                setImg(ferrades);
                break;

            case 'Raquetes de neu':
                setImg(raquetes);
                break;

            case 'Ponting':
                setImg(ponting);
                break;

            case 'Altres activitats':
                setImg(altres);
                break;
            default:
                break;
        }
    }, [cat]);
    return (
        <Box className={classes.imgWrap}>
            <img src={img} alt={cat} className={classes.img} />
        </Box>
    );
};

import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

export default function Header({ title, img, small, logo }) {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<Box
			className={clsx(
				classes.header,
				small ? classes.small : classes.big
			)}
		>
			<Box className={classes.player}>
				<img
					src={img}
					alt="Portada"
					width={"100%"}
					position="center"
					className={small ? classes.fotoSmall : ""}
				/>
			</Box>

			<Box className={classes.overlay}>
				{logo ? (
					<>{title}</>
				) : (
					<Typography
						variant="h1"
						fontSize={matches ? 110 : 70}
						color={"white"}
						textAlign="center"
					>
						{title}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	header: {
		position: "relative",
	},
	big: {
		paddingTop: "36.25%",
		[theme.breakpoints.down("md")]: {
			paddingTop: "50.25%",
		},
	},
	small: {
		paddingTop: "20.25%",
		[theme.breakpoints.down("md")]: {
			paddingTop: "50.25%",
		},
	},
	overlay: {
		position: "absolute",
		backgroundColor: "#00000070",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
		justifyContent: "center",
		display: "flex",
		flex: 1,
		alignItems: "center",
	},
	logo: {
		width: 700,
		marginBottom: 100,
	},
	logoMobile: {
		width: 300,
	},
	player: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	fotoSmall: {
		filter: "blur(8px)",
		marginTop: -100,
	},
}));

import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomPublicButton from '../../../../components/CustomPublicButton';
import { getElements } from '../../../../database/API';
import CategoriaHome from '../../../../components/CategoriaHome';
import Loading from '../../../../components/Layout/Loading';
import LoadingSkeleton from '../../../../components/Layout/LoadingSkeleton';
import CategoriesHomeSkeleton from '../../../../components/Skeletons/CategoriesHomeSkeleton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 100,
        paddingBottom: 120,
        textAlign: 'center',
        backgroundColor: theme.palette.background.third,
        marginTop: -5,
        marginBottom: -5,
        zIndex: 10,
    },
}));

export default function QueOferim() {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(min-width:960px)');
    const { t } = useTranslation();

    useEffect(() => {
        const get = async () => {
            const { result } = await getElements('categories');
            setCategories(result);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Box className={classes.container}>
            <Container maxWidth="lg">
                <Typography
                    variant="h1"
                    textAlign="center"
                    marginBottom={8}
                    color="white"
                >
                    {t('Què oferim')}
                </Typography>
                <LoadingSkeleton
                    skeleton={<CategoriesHomeSkeleton />}
                    loading={loading}
                >
                    <Grid container spacing={8}>
                        {categories.map((item) => (
                            <CategoriaHome cat={item} />
                        ))}
                    </Grid>
                </LoadingSkeleton>
            </Container>
        </Box>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Chip,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaActivitat } from "../../../components/Schemas";
import CustomCard from "../../../components/CustomCard";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import ImageInput from "../../../components/ImageInput";
import {
	deleteMaterial,
	getElement,
	getElements,
	updateMaterial,
} from "../../../database/API";
import { useSnackbar } from "notistack";
import Loading from "../../../components/Layout/Loading";
import Thumb from "../../../components/Thumb";
import Talles from "../../../components/Materials/Talles";
import ModalDelete from "../../../components/ModalDelete";
import Title from "../../../components/Layout/Title";
import MaterialPreus from "../../../components/Materials/Preus";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	talla: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const MaterialEdit = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [cats, setCats] = useState([]);
	const [categories, setCategories] = useState([]);
	const [material, setMaterial] = useState([]);
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const { key } = useParams();

	const {
		register,
		handleSubmit,
		formState: { errors },
		trigger,
		getValues,
		setValue,
		reset,
		control,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaActivitat),
		defaultValues: material,
	});

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("categoria_materials");
			setCategories(result);
			setLoading(false);
		};
		get();
	}, []);

	useEffect(() => {
		const get = async () => {
			const { result } = await getElement("materials", key);
			setMaterial(result);
			reset(result);
			setCats(result?.categoria_materials.map((item) => item.nom));
			setValue(
				"categories",
				result?.categoria_materials.map((item) => item.nom)
			);
			setLoading(false);
		};
		get();
	}, [key, reset, setValue]);

	const enviar = async (values) => {
		const message = await updateMaterial(values, key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	const eliminar = async () => {
		const message = await deleteMaterial(key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1);
	};

	return (
		<Box className={classes.container}>
			<Title title="Editar material" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<form onSubmit={handleSubmit(enviar)}>
							<CustomCard
								title="Entra les dades"
								footer={
									<>
										<CustomButton
											onClick={() => setOpen(true)}
											danger
											title={"Eliminar material"}
										/>
										<CustomButton
											type="submit"
											title={"Guardar material"}
										/>
									</>
								}
							>
								<Grid container spacing={3}>
									<Grid item md={3}>
										<Thumb file={getValues("foto")} />

										<ImageInput
											name="foto"
											register={register}
											trigger={trigger}
											getValues={getValues}
										/>
									</Grid>
									<Grid item md={6}>
										<CustomTextField
											name="nom"
											label="Nom del material (Català)"
											type="text"
											errors={errors.nom}
											register={register}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<Box my={1} />
										<CustomTextField
											name="nom_es"
											label="Nom del material (Español)"
											type="text"
											errors={errors.nom}
											register={register}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<Box my={1} />
										<CustomTextField
											name="nom_en"
											label="Nom del material (English)"
											type="text"
											errors={errors.nom}
											register={register}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<Box my={1} />
										<FormControl
											fullWidth
											style={{ marginTop: 20 }}
										>
											<InputLabel
												shrink={true}
												htmlFor={"categories"}
												id="demo-multiple-chip-label"
											>
												Categories
											</InputLabel>
											<Controller
												name={"categories"}
												control={control}
												render={() => (
													<Select
														labelId="demo-multiple-chip-label"
														id="demo-multiple-chip"
														label={"Categories"}
														notched={true}
														multiple
														value={cats}
														onChange={(event) => {
															setCats(
																event.target
																	.value
															);
															setValue(
																"categories",
																event.target
																	.value
															);
														}}
														input={
															<OutlinedInput
																id="select-multiple-chip"
																label={
																	"Categories"
																}
															/>
														}
														renderValue={(
															selected
														) => (
															<Box
																sx={{
																	display:
																		"flex",
																	flexWrap:
																		"wrap",
																	gap: 0.5,
																}}
															>
																{selected.map(
																	(value) => (
																		<Chip
																			key={
																				value
																			}
																			label={
																				value
																			}
																		/>
																	)
																)}
															</Box>
														)}
														MenuProps={MenuProps}
													>
														{categories?.map(
															(item) => (
																<MenuItem
																	key={
																		item.id
																	}
																	value={
																		item.nom
																	}
																>
																	{item.nom}
																</MenuItem>
															)
														)}
													</Select>
												)}
											/>
										</FormControl>
									</Grid>
									<Grid item md={3}>
										<Talles
											register={register}
											control={control}
										/>
										<MaterialPreus
											register={register}
											control={control}
										/>
									</Grid>
								</Grid>
							</CustomCard>
						</form>
					</Box>
				</Container>
			</Loading>
			<ModalDelete
				title="Segur que vols eliminar el material?"
				description="Si l'elimines, també s'eliminaran les imatges associades a aquesta"
				open={open}
				setOpen={setOpen}
				func={eliminar}
			/>
		</Box>
	);
};

export default MaterialEdit;

import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function ActivitatsSkeleton() {
    return (
        <Grid container spacing={4}>
            <ActivitatSkeleton />
            <ActivitatSkeleton />
            <ActivitatSkeleton />
        </Grid>
    );
}

const ActivitatSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid item md={4}>
            <Box className={classes.wrap}>
                <Box>
                    <Box className={classes.header}>
                        <Typography variant="h2" padding={1}>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Typography>
                    </Box>
                    <Box className={classes.body}>
                        <Typography variant="h3" textTransform="uppercase">
                            <Skeleton width="60%" />
                        </Typography>
                        <Skeleton
                            height={30}
                            style={{ marginBottom: 15 }}
                            width="60%"
                        />
                        <Typography variant="body1">
                            <Skeleton height={10} style={{ marginBottom: 6 }} />
                            <Skeleton height={10} style={{ marginBottom: 6 }} />
                            <Skeleton height={10} style={{ marginBottom: 6 }} />
                            <Skeleton height={10} width="80%" />
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.slide}>
                    <Skeleton height={250} />
                    <Skeleton height={80} />
                </Box>
            </Box>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    chip: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        margin: 10,
        borderRadius: 20,
        width: '100%',
    },
    wrap: {
        backgroundColor: 'white',
        borderRadius: 30,
        textAlign: 'left',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    header: {
        backgroundColor: theme.palette.background.color,
        padding: 10,
        borderRadius: '30px 30px 0 0',
        '& h2': {
            fontFamily: 'DM Serif Display',
            fontWeight: 400,
            fontSize: 32,
        },
    },
    body: {
        textAlign: 'left',
        padding: 20,
        '& p': {
            fontFamily: 'Raleway',
            textAlign: 'left',
        },
    },
    slide: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
    },
}));

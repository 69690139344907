import * as React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExternalLink, Link, Link2 } from "react-feather";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
	material: {
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		marginTop: 15,
		boxShadow: "0px 0px 8px 0px #00000030",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	material_1: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	material_2: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
	},
}));

export default function MaterialElement({ material }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user")) || "";

	return (
		<Box className={classes.material}>
			<Box className={classes.material_1}>
				<Box mr={2}>
					<img
						src={
							"https://pendolguies.covcontrol.net/public/storage/" +
							material?.foto
						}
						style={{ width: 90, height: 90 }}
						alt={material?.nom}
					/>
				</Box>
				<Box>
					<Typography variant="h3" textTransform={"uppercase"}>
						{material?.nom}
					</Typography>
					<Typography>
						Talla:{" "}
						{material?.pivot.talla + " x" + material?.pivot.quant}
					</Typography>
					<Typography>
						Preu:{" "}
						{material?.pivot.nom_preu +
							" - " +
							material?.pivot.preu +
							"€"}
					</Typography>
				</Box>
			</Box>
			<Box className={classes.material_2}>
				{user.role_id === "1" && (
					<Tooltip title="Veure material">
						<IconButton
							onClick={() =>
								navigate(
									"/admin/materials/edit/" + material?.id
								)
							}
						>
							<ExternalLink />
						</IconButton>
					</Tooltip>
				)}
				<Typography variant="h2" mt={2}>
					{material?.pivot.preu * material?.pivot.quant} €
				</Typography>
			</Box>
		</Box>
	);
}

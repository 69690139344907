import * as React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";

const useStyles = makeStyles((theme) => ({
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	wrap: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
	},
	editor: {
		fontFamily: "Raleway",
		paddingLeft: 10,
		height: "150px !important",
	},
	toolbar: {
		border: 0,
		borderBottom: "1px solid " + theme.palette.background.light,
	},
}));

export default function RichText({
	register,
	name,
	setValue,
	label,
	getValues,
}) {
	const classes = useStyles();

	const [editorState, setEditorState] = React.useState(() => {
		console.log(getValues);
		if (getValues) {
			if (getValues(name)) {
				const contentBlocks = convertFromHTML(getValues(name));

				const contentState = ContentState.createFromBlockArray(
					contentBlocks.contentBlocks
				);

				return EditorState.createWithContent(contentState);
			} else {
				return EditorState.createEmpty();
			}
		} else {
			const contentBlocks = convertFromHTML("");

			const contentState = ContentState.createFromBlockArray(
				contentBlocks.contentBlocks
			);

			return EditorState.createWithContent(contentState);
		}
	});

	React.useEffect(() => {
		setValue(name, convertToHTML(editorState.getCurrentContent()));
	}, [editorState]);

	return (
		<Box mt={3}>
			<Editor
				editorState={editorState}
				onEditorStateChange={setEditorState}
				placeholder={label}
				wrapperClassName={classes.wrap}
				editorClassName={classes.editor}
				toolbarClassName={classes.toolbar}
				toolbar={{
					options: ["inline", "blockType", "list"],
					inline: {
						options: [
							"bold",
							"italic",
							"underline",
							"strikethrough",
						],
					},
					blockType: {
						inDropdown: false,
						options: ["Normal", "H3", "H4", "H5", "H6"],
					},
					list: {
						options: ["ordered", "unordered"],
					},
				}}
			/>
			<input {...register(name)} hidden />
		</Box>
	);
}

import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player";
import video from "../../../../assets/video/pendol-video-web.mp4";
import logo from "../../../../assets/img/logo-pendol.svg";
import { ReactComponent as Icon } from "../../../../assets/img/logo-pendol.svg";

export default function Slider() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<Box className={classes.header}>
			<ReactPlayer
				playing={true}
				loop
				url={[{ src: video }]}
				height="100%"
				width="100%"
				className={classes.player}
				muted
			/>

			<Box className={classes.overlay}>
				<Icon alt="Logo Pèndol Guies" className={classes.logo} />
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	header: {
		position: "relative",
		paddingTop: "56.25%",
	},
	overlay: {
		position: "absolute",
		// backgroundImage: `url(${overlay})`,
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
		justifyContent: "center",
		display: "flex",
		flex: 1,
		alignItems: "center",
	},
	logo: {
		width: 700,
		marginBottom: 120,
		[theme.breakpoints.down("md")]: {
			width: 400,
			marginBottom: 80,
		},
		[theme.breakpoints.down("sm")]: {
			width: 250,
			marginBottom: 10,
		},
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
	logoMobile: {
		width: 250,
	},
	player: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	},
}));

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Checkbox,
	Collapse,
	Container,
	FormControlLabel,
	Grid,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaActivitat } from "../../../components/Schemas";
import CustomCard from "../../../components/CustomCard";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import ImageInput from "../../../components/ImageInput";
import RichText from "../../../components/RichText";
import CustomRadio from "../../../components/CustomRadio";
import { createActivitat, getElements } from "../../../database/API";
import CustomSelect from "../../../components/CustomSelect";
import { useSnackbar } from "notistack";
import ThumbMulti from "../../../components/ThumbMulti";
import CustomHeader from "../../../components/CustomHeader";
import Preus from "../../../components/Preus";
import Title from "../../../components/Layout/Title";
import Loading from "../../../components/Layout/Loading";
import TabsLang from "./TabsLang";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
}));

const ActivitatAdd = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showDesc, setShowDesc] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors },
		trigger,
		getValues,
		setValue,
		control,
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaActivitat),
		defaultValues: {
			preus: [{ min: "", max: "", preu: "", unic: false }],
			descompte: 0,
		},
	});
	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "preus",
	});

	useEffect(() => {
		const subscription = watch(() => {
			setShowDesc(getValues("check_descompte"));
		});
		return () => subscription.unsubscribe();
	}, [watch("check_descompte")]);

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("categories");
			setCategories(result);
			setLoading(false);
		};
		get();
	}, []);

	const enviar = async (values) => {
		console.log(values);
		setLoading(true);
		const message = await createActivitat(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	return (
		<Box className={classes.container}>
			<Title title="Afegir activitat" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<form onSubmit={handleSubmit(enviar)}>
							<CustomHeader
								title={
									<Typography variant="h3">
										{watch({ nest: true }).langs?.[0]?.nom}
									</Typography>
								}
								button={
									<CustomButton
										type="submit"
										title={"Crear i sortir"}
									/>
								}
							/>
							<Grid container spacing={5}>
								<Grid item md={8}>
									<CustomCard title="Dades">
										<TabsLang
											register={register}
											control={control}
											setValue={setValue}
											getValues={getValues}
											errors={errors}
										/>

										<Grid spacing={3} container>
											<Grid item md={4}>
												<CustomTextField
													name="edat"
													label="Edat mínima"
													type="number"
													errors={errors.edat}
													register={register}
												/>
											</Grid>
											<Grid item md={4}>
												<CustomTextField
													name="situacio"
													label="Situació"
													type="text"
													errors={errors.situacio}
													register={register}
												/>
											</Grid>
											<Grid item md={4}>
												<CustomSelect
													elements={categories}
													name="categoria_id"
													label="Categoria"
													register={register}
												/>
											</Grid>
										</Grid>
										<Grid spacing={3} container>
											<Grid item md={6}>
												<CustomRadio
													name={"dificultat"}
													label={"Nivell"}
													elements={[
														{
															nom: "Iniciació",
															value: 1,
														},
														{
															nom: "Mig",
															value: 2,
														},
														{
															nom: "Avançat",
															value: 3,
														},
														{
															nom: "Expert",
															value: 4,
														},
													]}
													control={control}
												/>
											</Grid>
											<Grid item md={6}>
												<Typography mt={1}>
													Opcions
												</Typography>
												<FormControlLabel
													control={
														<Checkbox
															{...register(
																"destacada"
															)}
														/>
													}
													label={
														<Box lineHeight={"9px"}>
															<Typography>
																Destacar
															</Typography>
														</Box>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															{...register(
																"familia"
															)}
														/>
													}
													label={
														<Box lineHeight={"9px"}>
															<Typography>
																Familiar
															</Typography>
														</Box>
													}
												/>
												<FormControlLabel
													control={
														<Checkbox
															onClick={() =>
																setValue(
																	"descompte",
																	0
																)
															}
															name={
																"check_descompte"
															}
															{...register(
																"check_descompte"
															)}
														/>
													}
													label={
														<Box lineHeight={"9px"}>
															<Typography>
																Descompte
															</Typography>
														</Box>
													}
												/>
												<Collapse in={showDesc}>
													<CustomTextField
														name="descompte"
														label="% de descompte"
														type="number"
														errors={
															errors.descompte
														}
														register={register}
													/>
												</Collapse>
											</Grid>
										</Grid>
									</CustomCard>
								</Grid>
								<Grid item md={4}>
									<CustomCard title="Imatge">
										<ThumbMulti file={getValues("foto")} />

										<ImageInput
											name="foto"
											register={register}
											trigger={trigger}
											getValues={getValues}
											multiple={true}
										/>
									</CustomCard>
									<Box my={4} />
									<CustomCard title="Preus">
										<Preus
											register={register}
											fields={fields}
											remove={remove}
											append={append}
											replace={replace}
											control={control}
											watch={watch}
										/>
									</CustomCard>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default ActivitatAdd;

import * as React from "react";
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Fab,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
	Check,
	CheckCircle,
	ExternalLink,
	Link,
	Link2,
	MapPin,
	User,
} from "react-feather";
import { useNavigate } from "react-router";
import clsx from "clsx";
import moment from "moment";
import StateColumn from "../StateColumn";
import theme from "../../theme";
import State from "../State";

const useStyles = makeStyles((theme) => ({
	wrap: {
		paddingLeft: 10,
		backgroundColor: "white",
		borderRadius: 10,
		borderLeft: "5px solid",
		marginBottom: 20,
	},
	espera: {
		borderColor: "#ababab",
	},
	completada: {
		borderColor: "#8bc34a",
	},
	cancelada: {
		borderColor: "#ff1744",
	},
	pagada: {
		borderColor: "#03a9f4",
	},
}));

export default function ReservaAdmin({ reserva }) {
	const classes = useStyles();
	const navigate = useNavigate();

	const style =
		reserva.state.nom === "Pagat"
			? classes.pagada
			: reserva.state.nom === "Completat"
			? classes.completada
			: reserva.state.nom === "En espera"
			? classes.espera
			: classes.cancelada;

	return (
		<Box className={clsx(classes.wrap, style)}>
			<Grid container px={1} py={4}>
				<Grid
					item
					xs={2}
					display="flex"
					justifyContent={"center"}
					alignItems="center"
					textAlign={"right"}
				>
					<Box
						borderRight={
							"1px solid " + theme.palette.background.light
						}
						pr={3}
					>
						<Typography variant="h3">
							{moment(reserva.data).format("D MMM")}
						</Typography>
						<Typography variant="h2">
							{moment(reserva.data).format("YYYY")}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={7} display="flex" alignItems={"center"}>
					<Avatar
						src={
							"https://pendolguies.covcontrol.net/public/storage/" +
							reserva?.activitat?.imatges[0].foto
						}
						style={{ width: 50, height: 50 }}
					/>
					<Box ml={2}>
						<Typography>
							{reserva.activitat.langs[0].nom}
						</Typography>
						<Box display={"flex"}>
							<User
								color={theme.palette.background.third}
								size={15}
							/>
							<Typography ml={1} variant="caption">
								{reserva?.nom}
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={2} display="flex" alignItems={"center"}>
					<State
						state={reserva.state}
						id={reserva.id}
						element="reserves"
					/>
				</Grid>
				<Grid item xs={1} textAlign={"center"}>
					<Fab
						color="primary"
						size="small"
						onClick={() =>
							navigate("/admin/reserves/view/" + reserva.id)
						}
					>
						<ExternalLink />
					</Fab>
				</Grid>
			</Grid>
		</Box>
	);
}

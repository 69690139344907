import {
	Box,
	Container,
	Divider,
	Grid,
	Typography,
	ClickAwayListener,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import img from "../../../assets/img/portada_lloguer.jpg";
import { makeStyles } from "@mui/styles";
import CustomStep from "./Step";
import CustomPublicButton from "../../../components/CustomPublicButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaRes } from "../../../components/Schemas";
import StepData from "./Steps/StepData";
import i18n from "../../../lang";
import moment from "moment";
import "moment/min/moment-with-locales";
import "moment/locale/es";
import "moment/locale/ca";
import StepMaterial from "./Steps/StepMaterial";
import CustomPopper from "../../../components/CustomPopper";
import StepForm from "./Steps/StepForm";
import { scroller } from "react-scroll";
import { Element } from "react-scroll";
import CustomChipMaterial from "../../../components/Layout/CustomChipMaterial";
import { createLloguer } from "../../../database/API";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Lloguer = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [date, setDate] = useState("");
	const [popper, setPopper] = useState(false);
	const [materialsSel, setMaterialsSel] = useState([]);
	const [total, setTotal] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [skipped, setSkipped] = useState(new Set());
	const { schemaLloguer } = SchemaRes();
	const classes = useStyles();
	const [formCompra, setForm] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const steps = [
		t("Quin dia necessites el material?"),
		t("Quin material necessites?"),
		t("Dades personals"),
	];

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		trigger,
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaLloguer),
	});

	useEffect(() => {
		if (activeStep > 0)
			scroller.scrollTo("steps", {
				duration: 400,
				smooth: true,
				offset: 50,
			});
	}, [activeStep]);

	useEffect(() => {
		setValue("material", materialsSel);
	}, [materialsSel]);

	useEffect(() => {
		setValue("preu", total);
	}, [total]);

	const handleNext = () => {
		let newSkipped = skipped;

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setTotal(0);
		setMaterialsSel([]);
		setDate();
		reset();
		setActiveStep(0);
		scroller.scrollTo("steps", {
			duration: 400,
			smooth: true,
			offset: 50,
		});
	};

	const renderSwitch = () => {
		switch (activeStep) {
			case 0:
				return (
					<StepData
						date={date}
						setDate={setDate}
						setValue={setValue}
						handleBack={handleBack}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<StepMaterial
						setMaterialsSel={setMaterialsSel}
						setTotal={setTotal}
						handleBack={handleBack}
						handleNext={handleNext}
						materialsSel={materialsSel}
						data={date}
					/>
				);
			case 2:
				return (
					<StepForm
						register={register}
						errors={errors}
						handleBack={handleBack}
						handleNext={handleNext}
						trigger={trigger}
					/>
				);
			default:
				return "";
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setPopper((previousOpen) => !previousOpen);
	};

	const handleDelete = (index) => {
		let mat = materialsSel[index];
		// console.log(mat);
		setTotal((prev) => prev - mat?.preu.preu * mat?.quant);
		setMaterialsSel([
			...materialsSel.slice(0, index),
			...materialsSel.slice(index + 1, materialsSel.length),
		]);
	};

	const enviar = async (values) => {
		setLoading(true);
		// console.log(values);
		const { form, message } = await createLloguer(values);
		setForm(form);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setTimeout(() => document.forms["redsys_form"].submit(), 500);
		reset();
	};

	return (
		<Page title={"Pèndol Guies - " + t("Lloguer")}>
			<Header title={t("Lloguer de material")} img={img} />
			<Sanefa color="white" />
			<Container maxWidth="lg">
				{parse(formCompra ? formCompra.data : "")}
				<Box textAlign="center" pt={4}>
					<Element name="steps">
						<Typography variant="body1" my={10}>
							{t(
								"Per a fer reserves de material preguem que segueixis els passos que s’indiquen a continuació. Escull en aquest calendari en quines dates necessites el material. Especifica tot el material que necessites, ja sigui material individual o bé equips complets, així com les talles que vols. Finalment omple el formulari amb les teves dades personals i accepta les condicions i el contracte."
							)}
						</Typography>
					</Element>
					<Box my={10} />

					<CustomStep steps={steps} activeStep={activeStep} />
					<Grid container spacing={0}>
						<Grid item md={4} xs={12} mb={5}>
							<Typography
								variant="caption"
								className={classes.resum}
							>
								{date
									? moment(date)
											.locale(i18n.language)
											.format("LL")
									: ""}
							</Typography>
						</Grid>
						<Grid item md={4} xs={12} mb={5}>
							<ClickAwayListener
								onClickAway={() => setPopper(false)}
							>
								<Box>
									<CustomPublicButton
										aria-describedby={"popper-id"}
										type="button"
										onClick={handleClick}
										title={
											t("Veure cistella") +
											" - " +
											total +
											" €"
										}
										small
										disabled={materialsSel.length === 0}
										style={{ marginTop: 5 }}
									/>

									<CustomPopper
										id={"popper-id"}
										open={popper}
										anchorEl={anchorEl}
										setPopper={setPopper}
									>
										<>
											{materialsSel?.map(
												(item, index) => (
													<CustomChipMaterial
														index={index}
														item={item}
														handleDelete={
															handleDelete
														}
													/>
												)
											)}
											<Box my={1} />
											<Divider flexItem />

											<Box
												display="flex"
												justifyContent={"space-between"}
												mt={2}
												width="100%"
											>
												<Typography>
													{t("Total")}
												</Typography>
												<Typography>
													{total} €
												</Typography>
											</Box>
										</>
									</CustomPopper>
								</Box>
							</ClickAwayListener>
						</Grid>

						<Grid item md={4} xs={12} mb={5}>
							<Typography
								variant="caption"
								className={classes.resum}
							>
								{watch("nom")}
							</Typography>
							<br />
							<Typography
								variant="caption"
								className={classes.resum}
							>
								{watch("email")}
							</Typography>
							<br />
							<Typography
								variant="caption"
								className={classes.resum}
							>
								{watch("phone")}
							</Typography>
						</Grid>
					</Grid>
					<form onSubmit={handleSubmit(enviar)}>
						{activeStep === steps.length ? (
							<React.Fragment>
								<Typography my={5}>
									{t("Confirmar dades i prem Pagar")}
								</Typography>
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) => {}}
											name="accepta"
											{...register("accepta")}
											required
										/>
									}
									label={
										<Typography>
											{t("He llegit i accepto la")}{" "}
											<a
												href="/politica-privacitat"
												target="_blank"
												alt="Política de Privacitat"
												rel="noreferrer"
											>
												{t("Política de Privacitat")}
											</a>
											{t(" i les ")}
											<a
												href="/condicions-lloguer"
												target="_blank"
												alt="Política de Privacitat"
												rel="noreferrer"
											>
												{t("Condicions lloguer")}
											</a>
										</Typography>
									}
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										py: 8,
									}}
								>
									<CustomPublicButton
										small
										title={t("Enrere")}
										onClick={handleBack}
										style={{ marginRight: 10 }}
									/>
									<CustomPublicButton
										small
										title={t("Tornar a començar")}
										onClick={handleReset}
										style={{ marginRight: 10 }}
									/>
									<CustomPublicButton
										small
										title={t("Pagar")}
										type="submit"
										// loading={loading}
										// disabled={loading}
									/>
								</Box>

								<Box className={classes.condicions}>
									<Typography variant="h3" mb={2}>
										{t("Condicions importants")}
									</Typography>
									{i18n.language === "ca" ? (
										<Typography>
											El material es recollirà a{" "}
											<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
												l’Espai Actiu Vallcebre
											</a>{" "}
											amb direcció Crta., B-401, 08699
											Vallcebre, Barcelona, més conegut
											com a PLA DE LA BARRACA. A partir de
											les 9 AM i es tornarà el mateix dia
											abans de les 16:30 PM (Si l’empresa
											modifica horari o ubicació informarà
											el client un cop realitzat el
											tràmit)
										</Typography>
									) : i18n.language === "es" ? (
										<Typography>
											El material se recogerá en el{" "}
											<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
												Espai Actiu Vallcebre
											</a>{" "}
											con dirección Crta., B-401, 08699
											Vallcebre, Barcelona, más conocido
											como PLAN DE LA BARRACA. A partir de
											las 9 AM y se devolverá el mismo día
											antes de las 16:30 PM. (Si la
											empresa modifica horario o ubicación
											informará al cliente una vez
											realizado el trámite)
										</Typography>
									) : (
										<Typography>
											The material will be collected at
											the{" "}
											<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
												Espai Actiu Vallcebre
											</a>{" "}
											with address Crta, B-401, 08699
											Vallcebre, Barcelona, better known
											as PLA DE LA BARRACA. From 9 AM and
											will return the same day before
											16:30 PM. (If the company changes
											the timetable or location, it will
											inform the client once the procedure
											has been carried out).
										</Typography>
									)}
								</Box>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Box my={7}>{renderSwitch()}</Box>
							</React.Fragment>
						)}
					</form>
				</Box>
			</Container>
		</Page>
	);
};

export default Lloguer;

const useStyles = makeStyles((theme) => ({
	resum: {
		fontSize: "12px !important",
		lineHeight: "0 !important",
	},
	condicions: {
		padding: 20,
		borderRadius: 10,
		marginBottom: 100,
		backgroundColor: theme.palette.background.light,
	},
}));

import { Cancel } from "@mui/icons-material";
import {
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import img from "../../../assets/img/cats/ponting.jpg";
import Sanefa from "../../../components/Sanefa";
import Page from "../../../components/Layout/Page";

const CondicionsLloguer = () => {
	const classes = Styles();
	const { t, i18n } = useTranslation();
	return (
		<Page title={"Pèndol Guies - Condicions lloguer"}>
			<Box className={classes.root}>
				<Header title={t("Condicions lloguer")} img={img} small />
				<Sanefa color="white" />
				{i18n.language === "ca" ? (
					<Container maxWidth="lg" className={classes.main}>
						<Typography variant="h2" className={classes.titol}>
							PRIMERA.- Objecte del contracte
						</Typography>
						<Typography>
							L'objecte del contracte actual és el lloguer de
							material tècnic de muntanya per part de Pèndol Guies
							de Muntanya al CLIENT, per un preu i temps
							determinat. L'ús del material llogat per Pèndol
							Guies de Muntanya està restringit a l'àmbit
							territorial de la Unió Europea. En cas de tenir
							intenció d'utilitzar-ho fora d'aquest espai, el
							CLIENT ha de comunicar-lo expressament a Pèndol
							Guies de Muntanya, la responsabilitat del qual no
							serà exigible en qualsevol succés ocorregut fora de
							l'àmbit territorial referit de la UE, amb excepció
							del supòsit contemplat en la Clàusula Cinquena
							d'aquestes condicions generals.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							SEGONA.- Producte llogat i durada.
						</Typography>
						<Typography>
							El preu del lloguer del material va en funció del
							material seleccionat i de la durada de l'arrendament
							sol·licitada pel CLIENT. El preu s'abonarà SEMPRE
							per endavant, en el moment de la contractació.
						</Typography>{" "}
						<Typography>
							En funció de les circumstàncies de l'arrendament,
							pot ser necessari en alguns casos el dipòsit d'una
							quantitat de diners en concepte de fiança. En cas de
							ser aplicada aquesta fiança, Pèndol Guies de
							Muntanya reintegrarà aquest import en un termini
							màxim de 24 hores després d'haver recepcionat el
							material arrendat i comprovat que està en perfectes
							condicions.
						</Typography>
						<Typography>
							El material es recollirà a l’Espai Actiu Vallcebre{" "}
							<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
								https://goo.gl/maps/UtnJPMhVjMxtizxQ6
							</a>{" "}
							amb direcció Crta., B-401, 08699 Vallcebre,
							Barcelona, més conegut com a PLA DE LA BARRACA. A
							partir de les 9 AM i es tornarà el mateix dia abans
							de les 16:30 PM (Si l’empresa modifica horari o
							ubicació informarà el client un cop realitzat el
							tràmit)
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							TERCERA.- Exempció de Responsabilitats
						</Typography>
						<Typography>
							El CLIENT reconeix i accepta que Pèndol Guies de
							Muntanya no es farà responsable de: Retards en la
							devolució del material per tercers que
							impossibilitin un servei puntual per part de Pèndol
							Guies de Muntanya. Material retornat per un tercer
							que es trobi avariat o amb desperfectes que
							impossibilitin el seu lloguer a un altre CLIENT.
							Reclamacions per accidents, ferides, cops o danys
							ocasionats a si mateix o a tercers (persones o béns)
							durant el període de lloguer Causes de força major
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							QUARTA.- Obligacions de Pèndol Guies de Muntanya
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya s'obliga a: Lliurar al
							CLIENT el material triat en condicions de
							funcionament i ús adequats. Revisar i comprovar cada
							material retornat, reclamant al CLIENT els possibles
							danys que es poguessin haver produït durant el seu
							període de lloguer.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							QUINTA.- Obligacions del CLIENT
						</Typography>
						<Typography>
							El CLIENT s'obliga a: Recollir i retornar l'article
							arrendat, així com els seus accessoris i embalatges
							originals subministrats per Pèndol Guies de
							Muntanya, en el lloc i dates pactades a l'inici i
							final del període d'arrendament.
						</Typography>
						<Typography>
							Retornar a Pèndol Guies de Muntanya els articles
							arrendats en bones condicions Usar el material
							arrendat de manera responsable i adequada, vetllant
							per la seva integritat, bon funcionament del mateix
							i no exposant-ho a situacions en les quals pogués
							ser danyat. Informar a Pèndol Guies de Muntanya en
							els casos en què el producte vagi a ser traslladat a
							països no coberts per les condicions del segur, i en
							aquest cas Pèndol Guies de Muntanya proporcionarà al
							CLIENT pressuposat per a l'ampliació de cobertura
							per a aquest trasllat. Si el CLIENT o la companyia
							asseguradora, no accepten l'ampliació de la
							cobertura, o el CLIENT no satisfà el pagament de la
							prima, s'entendrà que assumeix la responsabilitat
							total sobre el producte, havent d'abonar la
							reparació o restitució del producte en cas de
							sinistre. Abonar íntegrament la reparació que es
							derivi per qualsevol desperfecte causat per ús
							negligent o poc acurat, que s'hagués pogut produir
							durant el període de lloguer. L'import serà
							l'especificat en el pressupost de la reparació que
							Pèndol Guies de Muntanya enviarà al CLIENT a
							l'adreça especificada en l'encapçalament d'aquestes
							CGC generant-se una ordre de pagament en *Pay-*Pal o
							altres plataformes autoritzades, que haurà de ser
							satisfeta en un termini de 48 hores.
						</Typography>
						<Typography>
							Abonar íntegrament la compra per restitució del
							material i els seus accessoris en cas de robatori,
							furt, pèrdua, danys irreparables i extraviament
							d'accessoris, així com l'enviament d'un article
							diferent de l'enviat per Pèndol Guies de Muntanya .
							L'import de la restitució es realitzarà conforme a
							les tarifes recollides en l'ANNEX d'aquestes *CGC i
							es generarà una ordre de pagament en *Pay-*Pal o
							altres plataformes autoritzades, que haurà de ser
							satisfeta en un termini de 72 hores.
						</Typography>
						<Typography>
							El CLIENT s'abstindrà en tot cas de cedir, llogar,
							hipotecar, obstinar, vendre, prestar o de qualsevol
							manera donar en garantia l'equipament llogat, els
							seus accessoris, així com el seu embalatge.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SISENA.- Dates i Terminis de Lliurament
						</Typography>
						<Typography>
							Si el producte llogat, inclòs l'embalatge, no fos
							retornat en la data marcada, el CLIENT abonarà a
							Pèndol Guies de Muntanya en concepte de penalització
							d'un 100% de l'import per dia de retard sobre el
							total de la comanda i es generarà en *Pay-*Pal o
							altres plataformes autoritzades una ordre de
							pagament per aquest import, que haurà de ser abonada
							pel CLIENT en un termini de 48 hores.
						</Typography>{" "}
						<Typography>
							Si en un termini de quatre dies naturals a partir de
							la data de devolució acordada del producte, aquest
							no estigués en possessió de *Pèndol *Guies de
							*Muntanya , el CLIENT es compromet a abonar l'import
							de la restitució de l'article segons les tarifes
							recollides en l'ANNEX d'aquestes *CGC. Els dies
							considerats, començaran a comptar-se des del dia de
							finalització del període de lloguer fins al mateix
							dia en què l'article sigui retornat a Pèndol Guies
							de Muntanya.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SETENA.- Anul·lació de Reserves
						</Typography>
						<Typography>
							Per a anul·lar una reserva el pagament de la qual
							hagi estat efectuat, el CLIENT haurà de posar-se en
							contacte amb Pèndol Guies de Muntanya a través dels
							mitjans proporcionats en la seva pàgina web, o bé
							contactant directament en el telèfon o email que
							apareixen en l'encapçalament d'aquestes CGC.
						</Typography>{" "}
						<Typography>
							Les penalitzacions per anul·lació de reserva seran
							en funció de l'antelació amb la qual el CLIENT la
							sol·liciti i seran les següents:
						</Typography>{" "}
						<Typography>
							Anul·lació amb més de deu dies d'antelació respecte
							a la data de lliurament *à penalització del 0% del
							total del material
						</Typography>
						<Typography>
							Anul·lació amb una antelació prèvia d'entre tres i
							sis dies la penalització del 50% del total del
							material
						</Typography>{" "}
						<Typography>
							Anul·lació amb una antelació prèvia de dos dies la
							penalització del 100% del total del material
						</Typography>{" "}
						<Typography>
							La no presentació del CLIENT en la data i hora
							indicades per Pèndol Guies de Muntanya o
							l'anul·lació del lloguer de material les 24 h
							anteriors a l'inici de la data de lliurament
							implicarà la pèrdua total de les quantitats
							anticipades.
						</Typography>{" "}
						<Typography>
							En el cas de condicions adverses severes,
							s'acceptarà l'anul·lació del lloguer de material,
							guardant-se la quantitat total abonada per a un
							altre dia.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							OCTAVA.- Resolució del contracte
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya podrà resoldre el contracte
							actual de ple dret i mitjançant simple comunicació
							escrita, si es donés qualsevol de les següents
							circumstàncies: Incompliment greu del contracte
							actual. En aquest cas, l'arrendador podrà prendre
							possessió immediata del material en el lloc en què
							es trobi, sense que tingui dret l'arrendatari a cap
							mena de reclamació o indemnització. Per incapacitat
							per a continuar prestant el servei Per
							indisponibilitat d'estoc.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							NOVENA.- Notificacions
						</Typography>
						<Typography>
							A l'efecte de notificacions, tant Pèndol Guies de
							Muntanya com el CLIENT, designen expressament el
							domicili que consta en el contracte actual.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							DÈCIMA.- Jurisdicció Aplicable
						</Typography>
						<Typography>
							En el cas que sorgeixi qualsevol conflicte o
							discrepància en la interpretació o aplicació de les
							presents condicions contractuals, els Jutjats i
							Tribunals que en el seu cas coneixeran de
							l'assumpte, seran els que disposi la normativa legal
							aplicable en matèria de jurisdicció competent. En el
							cas que el CLIENT tingui el seu domicili fora
							d'Espanya, totes dues parts se sotmeten, amb
							renúncia expressa a qualsevol altre fur, als Jutjats
							i Tribunals de Berga (Berguedà).
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							ONZENA.- Protecció de dades
						</Typography>
						<Typography>
							En compliment de Llei orgànica 15/1999 de Protecció
							de Dades de Caràcter Personal, l'informem que les
							dades personals que ens faciliti per a realitzar el
							procés de contractació i els derivats de la
							prestació del servei són confidencials i que
							s'incorporaran a un fitxer de “Clients”
							responsabilitat de *Pèndol *Guies de *Muntanya, amb
							la finalitat de proveir-li dels serveis contractats,
							així com remetre-li informació dels nostres serveis,
							inclusivament per correu electrònic. Així mateix,
							l'informem que les dades personals facilitades són
							tractats internament i amb la màxima
							confidencialitat, aplicant les mesures de seguretat
							regulades en l'RD 1720/2007 i que no són publicats
							ni transmesos a terceres persones per a ser
							utilitzats amb finalitats comercials o promocionals.
							En qualsevol moment pot oposar-se a la recepció dels
							nostres enviaments comercials, així com executar els
							drets d'accés, rectificació, cancel·lació i oposició
							en els termes legalment establerts davant Pèndol
							Guies de Muntanya SCP., responsable de Dades
							Personals, en C/ de l’*alberg S/N, Vallcebre, 08699,
							Barcelona.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							DOTZENA.- Compromís, acceptació i validesa del
							contracte.
						</Typography>
						<Typography>
							El CLIENT reconeix que ha entès tota la informació
							respecte al servei de lloguer de material
							contractat, així com totes les condicions i
							estipulacions recollides en el contracte actual, per
							la qual cosa afirma que són suficients per a
							l'exclusió de l'error en el consentiment del
							contracte actual, i, per tant, les accepta
							íntegrament i expressament.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							Annexes condicions LLOGUER
						</Typography>
						<Typography>
							Llista PVP dels productes arrendats:
						</Typography>
						<Typography>
							ARTICLES D'ARRENDAMENT Pèndol Guies de Muntanya SCP
						</Typography>
						<Box my={4}>
							<TableContainer component={Paper}>
								<Table
									sx={{ minWidth: 650 }}
									aria-label="simple table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Material
											</TableCell>
											<TableCell align="right">
												PVP (reposició)
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Neoprè adult i nen
											</TableCell>
											<TableCell align="right">
												210 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Arnès de barrancs + *culera
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												*Bagas d'ancoratge + 2
												mosquetons simples + *maillon
												*rapide de *8mm
											</TableCell>
											<TableCell align="right">
												15€ + 10€ - 6€{" "}
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												*Descensor 8 + mosquetó de
												seguretat
											</TableCell>
											<TableCell align="right">
												10€ + 15€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Casc
											</TableCell>
											<TableCell align="right">
												53 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Escarpins
											</TableCell>
											<TableCell align="right">
												15 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												*Petate de barrancs
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Pot estanc
											</TableCell>
											<TableCell align="right">
												23 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Arnès polivalent
											</TableCell>
											<TableCell align="right">
												55 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Raquetes de neu + funda
											</TableCell>
											<TableCell align="right">
												75€ + 20€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Bastons de *trekking
											</TableCell>
											<TableCell align="right">
												50 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Pala metàl·lica per a allaus
											</TableCell>
											<TableCell align="right">
												45 €
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Container>
				) : i18n.language === "es" ? (
					<Container maxWidth="lg" className={classes.main}>
						<Typography variant="h2" className={classes.titol}>
							PRIMERA.- Objeto del contrato
						</Typography>
						<Typography>
							El objeto del presente contrato es el alquiler de
							material técnico de montaña por parte de Pèndol
							Guies de Muntanya al CLIENTE, por un precio y tiempo
							determinado. El uso del material alquilado por
							Pèndol Guies de Muntanya está restringido al ámbito
							territorial de la Unión Europea. En caso de tener
							intención de utilizarlo fuera de este espacio, el
							CLIENTE debe de comunicarlo expresamente a Pèndol
							Guies de Muntanya, cuya responsabilidad no será
							exigible en cualquier suceso ocurrido fuera del
							ámbito territorial referido de la UE, con excepción
							del supuesto contemplado en la Cláusula Quinta de
							estas condiciones generales.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							SEGUNDA.- Producto alquilado y duración.
						</Typography>
						<Typography>
							El precio del alquiler del material va en función
							del material seleccionado y de la duración del
							arrendamiento solicitada por el CLIENTE. El precio
							se abonará SIEMPRE por adelantado, en el momento de
							la contratación.
						</Typography>{" "}
						<Typography>
							En función de las circunstancias del arrendamiento,
							puede ser necesario en algunos casos el depósito de
							una cantidad de dinero en concepto de fianza. En
							caso de ser aplicada esta fianza, Pèndol Guies de
							Muntanya reintegrará dicho importe en un plazo
							máximo de 24 horas después de haber recepcionado el
							material arrendado y comprobado que está en
							perfectas condiciones.
						</Typography>
						<Typography>
							El material se recogerá en el Espai Actiu Vallcebre
							<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
								https://goo.gl/maps/UtnJPMhVjMxtizxQ6
							</a>{" "}
							con dirección Crta., B-401, 08699 Vallcebre,
							Barcelona, más conocido como PLAN DE LA BARRACA. A
							partir de las 9 AM y se devolverá el mismo día antes
							de las 16:30 PM. (Si la empresa modifica horario o
							ubicación informará al cliente una vez realizado el
							trámite)
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							TERCERA.- Exención de Responsabilidades
						</Typography>
						<Typography>
							El CLIENTE reconoce y acepta que Pèndol Guies de
							Muntanya no se hará responsable de: • Retrasos en la
							devolución del material por terceros que
							imposibiliten un servicio puntual por parte de
							Pèndol Guies de Muntanya. • Material devuelto por un
							tercero que se encuentre averiado o con desperfectos
							que imposibiliten su alquiler a otro CLIENTE. •
							Reclamaciones por accidentes, heridas, golpes o
							daños ocasionados a sí mismo o a terceros (personas
							o bienes) durante el periodo de alquiler • Causas de
							fuerza mayor
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							CUARTA.- Obligaciones de Pèndol Guies de Muntanya
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya se obliga a: • Entregar al
							CLIENTE el material elegido en condiciones de
							funcionamiento y uso adecuados. • Revisar y
							comprobar cada material devuelto, reclamando al
							CLIENTE los posibles daños que se pudieran haber
							producido durante su periodo de alquiler.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							QUINTA.- Obligaciones del CLIENTE
						</Typography>
						<Typography>
							El CLIENTE se obliga a: • Recoger y devolver el
							artículo arrendado, así como sus accesorios y
							embalajes originales suministrados por Pèndol Guies
							de Muntanya, en el lugar y fechas pactadas al inicio
							y final del periodo de arrendamiento.
						</Typography>
						<Typography>
							• Devolver a Pèndol Guies de Muntanya los artículos
							arrendados en buenas condiciones • Usar el material
							arrendado de forma responsable y adecuada, velando
							por su integridad, buen funcionamiento del mismo y
							no exponiéndolo a situaciones en las que pudiera ser
							dañado. • Informar a Pèndol Guies de Muntanya en los
							casos en que el producto vaya a ser trasladado a
							países no cubiertos por las condiciones del seguro,
							en cuyo caso Pèndol Guies de Muntanya proporcionará
							al CLIENTE presupuesto para la ampliación de
							cobertura para dicho traslado. Si el CLIENTE o la
							compañía aseguradora, no aceptan la ampliación de la
							cobertura, o el CLIENTE no satisface el pago de la
							prima, se entenderá que asume la responsabilidad
							total sobre el producto, debiendo abonar la
							reparación o restitución del producto en caso de
							siniestro. • Abonar íntegramente la reparación que
							se derive por cualquier desperfecto causado por uso
							negligente o poco cuidadoso, que se hubiera podido
							producir durante el periodo de alquiler. El importe
							será el especificado en el presupuesto de la
							reparación que Pèndol Guies de Muntanya enviará al
							CLIENTE a la dirección especificada en el
							encabezamiento de estas CGC generándose una orden de
							pago en Pay-Pal u otras plataformas autorizadas, que
							deberá ser satisfecha en un plazo de 48 horas. •
							Abonar íntegramente la compra por restitución del
							material y sus accesorios en caso de robo, hurto,
							pérdida, daños irreparables y extravío de
							accesorios, así como el envío de un artículo
							distinto al enviado por Pèndol Guies de Muntanya .
							El importe de la restitución se realizará conforme a
							las tarifas recogidas en el ANEXO de estas CGC y se
							generará una orden de pago en Pay-Pal u otras
							plataformas autorizadas, que deberá ser satisfecha
							en un plazo de 72 horas. • El CLIENTE se abstendrá
							en todo caso de ceder, alquilar, hipotecar, empeñar,
							vender, prestar o de cualquier manera dar en
							garantía el equipamiento alquilado, sus accesorios,
							así como su embalaje.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SEXTA.- Fechas y Plazos de Entrega
						</Typography>
						<Typography>
							Si el producto alquilado, incluido el embalaje, no
							fuera devuelto en la fecha marcada, el CLIENTE
							abonará a Pèndol Guies de Muntanya en concepto de
							penalización de un 100% del importe por día de
							retraso sobre el total del pedido y se generará en
							Pay-Pal u otras plataformas autorizadas una orden de
							pago por dicho importe, que tendrá que ser abonada
							por el CLIENTE en un plazo de 48 horas.
						</Typography>{" "}
						<Typography>
							Si en un plazo de 4 días naturales a partir de la
							fecha de devolución acordada del producto, este no
							estuviera en posesión de Pèndol Guies de Muntanya ,
							el CLIENTE se compromete a abonar el importe de la
							restitución del artículo según las tarifas recogidas
							en el ANEXO de estas CGC. Los días considerados,
							empezarán a contarse desde el día de finalización
							del periodo de alquiler hasta el mismo día en que el
							artículo sea devuelto a Pèndol Guies de Muntanya.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SÉPTIMA.- Anulación de Reservas
						</Typography>
						<Typography>
							Para anular una reserva cuyo pago haya sido
							efectuado, el CLIENTE deberá ponerse en contacto con
							Pèndol Guies de Muntanya a través de los medios
							proporcionados en su página web, o bien contactando
							directamente en el teléfono o email que aparecen en
							el encabezamiento de estas CGC.
						</Typography>{" "}
						<Typography>
							Las penalizaciones por anulación de reserva serán en
							función de la antelación con la que el CLIENTE la
							solicite y serán las siguientes:
						</Typography>{" "}
						<Typography>
							• Anulación con más de 10 días de antelación
							respecto a la fecha de entrega à penalización del 0%
							del total del material
						</Typography>
						<Typography>
							• Anulación con una antelación previa de entre 3 y 6
							días la penalización del 50% del total del material
						</Typography>{" "}
						<Typography>
							• Anulación con una antelación previa de 2 días la
							penalización del 100% del total del material
						</Typography>{" "}
						<Typography>
							• La no presentación del CLIENTE en la fecha y hora
							indicadas por Pèndol Guies de Muntanya o la
							anulación del alquiler de material las 24h
							anteriores al inicio de la fecha de entrega
							implicará la pérdida total de las cantidades
							anticipadas.
						</Typography>{" "}
						<Typography>
							En el caso de condiciones adversas severas, se
							aceptará la anulación del alquiler de material,
							guardándose la cantidad total abonada para otro día.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							OCTAVA.- Resolución del contrato
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya podrá resolver el presente
							contrato de pleno derecho y mediante simple
							comunicación escrita, si se diera cualquiera de las
							siguientes circunstancias: • Incumplimiento grave
							del presente contrato. En este caso, el arrendador
							podrá tomar posesión inmediata del material en el
							lugar en que se encuentre, sin que tenga derecho el
							arrendatario a ningún tipo de reclamación o
							indemnización. • Por incapacidad para seguir
							prestando el servicio • Por indisponibilidad de
							stock.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							NOVENA.- Notificaciones
						</Typography>
						<Typography>
							A efectos de notificaciones, tanto Pèndol Guies de
							Muntanya como el CLIENTE, designan expresamente el
							domicilio que consta en el presente contrato.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							DÉCIMA.- Jurisdicción Aplicable
						</Typography>
						<Typography>
							En el supuesto de que surja cualquier conflicto o
							discrepancia en la interpretación o aplicación de
							las presentes condiciones contractuales, los
							Juzgados y Tribunales que en su caso conocerán del
							asunto, serán los que disponga la normativa legal
							aplicable en materia de jurisdicción competente. En
							el caso de que el CLIENTE tenga su domicilio fuera
							de España, ambas partes se someten, con renuncia
							expresa a cualquier otro fuero, a los Juzgados y
							Tribunales de Berga (Berguedà).
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							UNDÉCIMA.- Protección de datos
						</Typography>
						<Typography>
							En cumplimiento de Ley Orgánica 15/1999 de
							Protección de Datos de Carácter Personal, le
							informamos que los datos personales que nos facilite
							para realizar el proceso de contratación y los
							derivados de la prestación del servicio son
							confidenciales y que se incorporarán a un fichero de
							“Clientes” responsabilidad de Pèndol Guies de
							Muntanya, con la finalidad de proveerle de los
							servicios contratados, así como remitirle
							información de nuestros servicios, inclusive por
							correo electrónico. Asimismo, le informamos que los
							datos personales facilitados son tratados
							internamente y con la máxima confidencialidad,
							aplicando las medidas de seguridad reguladas en el
							RD 1720/2007 y que no son publicados ni transmitidos
							a terceras personas para ser utilizados con fines
							comerciales o promocionales. En cualquier momento
							puede oponerse a la recepción de nuestros envíos
							comerciales, así como ejecutar los derechos de
							acceso, rectificación, cancelación y oposición en
							los términos legalmente establecidos ante Pèndol
							Guies de Muntanya SCP., responsable de Datos
							Personales, en C/ de l’alberg S/N, Vallcebre, 08699,
							Barcelona.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							DUODÉCIMA.- Compromiso, aceptación y validez del
							contrato.
						</Typography>
						<Typography>
							El CLIENTE reconoce que ha entendido toda la
							información respecto al servicio de alquiler de
							material contratado, así como todas las condiciones
							y estipulaciones recogidas en el presente contrato,
							por lo que afirma que son suficientes para la
							exclusión del error en el consentimiento del
							presente contrato, y por lo tanto las acepta íntegra
							y expresamente.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							ANEXO CONDICIONES ALQUILER
						</Typography>
						<Typography>
							Lista PVP de los productos arrendados:
						</Typography>
						<Typography>
							ARTÍCULOS DE ARRENDAMIENTO Pèndol Guies de Muntanya
							SCP
						</Typography>
						<Box my={4}>
							<TableContainer component={Paper}>
								<Table
									sx={{ minWidth: 650 }}
									aria-label="simple table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Material
											</TableCell>
											<TableCell align="right">
												PVP (reposición)
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Neopreno adulto y niño
											</TableCell>
											<TableCell align="right">
												210 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Arnés de barrancos + culera
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>

										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Bagas de anclaje + 2 mosquetones
												simples + maillon rapide de 8mm
											</TableCell>
											<TableCell align="right">
												15€ + 10€ - 6€{" "}
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Descensor 8 + mosquetón de
												seguridad
											</TableCell>
											<TableCell align="right">
												10€ + 15€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Casco
											</TableCell>
											<TableCell align="right">
												53 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Escarpines
											</TableCell>
											<TableCell align="right">
												15 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Petate de barrancos
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Bote estanco
											</TableCell>
											<TableCell align="right">
												23 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Arnés polivalente
											</TableCell>
											<TableCell align="right">
												55 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Raquetas de nieve + funda
											</TableCell>
											<TableCell align="right">
												75€ + 20€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Bastones de trekking
											</TableCell>
											<TableCell align="right">
												50 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Pala metálica para aludes
											</TableCell>
											<TableCell align="right">
												45 €
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Container>
				) : (
					<Container maxWidth="lg" className={classes.main}>
						<Typography variant="h2" className={classes.titol}>
							FIRST - Purpose of the contract
						</Typography>
						<Typography>
							The purpose of this contract is the rental of
							technical mountain equipment by Pèndol Guies de
							Muntanya to the CLIENT, for a specific price and
							time. The use of the material rented by Pèndol Guies
							de Muntanya is restricted to the European Union. In
							the event of intending to use it outside this area,
							the CLIENT must expressly inform Pèndol Guies de
							Muntanya, whose responsibility will not be
							demandable in any event occurring outside the
							aforementioned territorial area of the EU, with the
							exception of the case contemplated in Clause Five of
							these general conditions.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							SECOND.- Rented product and duration.
						</Typography>
						<Typography>
							The price of the rental of the material depends on
							the material selected and the duration of the rental
							requested by the CUSTOMER. The price will ALWAYS be
							paid in advance, at the time of contracting.
						</Typography>{" "}
						<Typography>
							Depending on the circumstances of the rental, it may
							be necessary in some cases to deposit a sum of money
							as a deposit. If this deposit is applied, Pèndol
							Guies de Muntanya will refund this amount within a
							maximum period of 24 hours after having received the
							rented material and checked that it is in perfect
							condition.
						</Typography>
						<Typography>
							The material will be collected at the Espai Actiu
							Vallcebre{" "}
							<a href="https://goo.gl/maps/UtnJPMhVjMxtizxQ6">
								https://goo.gl/maps/UtnJPMhVjMxtizxQ6
							</a>{" "}
							with address Crta, B-401, 08699 Vallcebre,
							Barcelona, better known as PLA DE LA BARRACA. From 9
							AM and will return the same day before 16:30 PM. (If
							the company changes the timetable or location, it
							will inform the client once the procedure has been
							carried out).
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							THIRD - Exemption from Liability
						</Typography>
						<Typography>
							The CLIENT acknowledges and accepts that Pèndol
							Guies de Muntanya will not be held responsible for:
							Delays in the return of material by third parties
							that make it impossible for Pèndol Guies de Muntanya
							to provide a punctual service. Material returned by
							a third party that is broken or damaged so that it
							cannot be rented to another CLIENT. Claims for
							accidents, injuries, blows or damage caused to
							oneself or to third parties (persons or goods)
							during the rental period. Causes of force majeure.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							FOURTH - Obligations of Pèndol Guies de Muntanya
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya is obliged to: Deliver to
							the CLIENT the chosen material in proper working
							condition and use. Review and check each returned
							material, claiming from the CLIENT any possible
							damage that may have occurred during the rental
							period.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							FIFTH: Obligations of the CUSTOMER.
						</Typography>
						<Typography>
							The CLIENT is obliged to: Collect and return the
							rented item, as well as its accessories and original
							packaging supplied by Pèndol Guies de Muntanya, at
							the place and on the dates agreed at the beginning
							and end of the rental period.
						</Typography>
						<Typography>
							Return the rented items to Pèndol Guies de Muntanya
							in good condition. To use the rented material in a
							responsible and appropriate manner, ensuring its
							integrity, its proper functioning and not exposing
							it to situations in which it could be damaged. To
							inform Pèndol Guies de Muntanya in cases where the
							product is to be taken to countries not covered by
							the insurance conditions, in which case Pèndol Guies
							de Muntanya will provide the CUSTOMER with a quote
							for the extension of cover for this transfer. If the
							CUSTOMER or the insurance company does not accept
							the extension of coverage, or the CUSTOMER does not
							pay the premium, it will be understood that he/she
							assumes full responsibility for the product, and
							must pay for the repair or restitution of the
							product in the event of a claim. The CUSTOMER shall
							pay in full for the repair of any damage caused by
							negligent or careless use that may have occurred
							during the rental period. The amount will be the
							amount specified in the repair estimate that Pèndol
							Guies de Muntanya will send to the CLIENT at the
							address specified in the heading of these GTC,
							generating a payment order in Pay-Pal or other
							authorised platforms, which must be paid within 48
							hours. To pay the full amount of the purchase for
							the restitution of the material and its accessories
							in the event of theft, robbery, loss, irreparable
							damage and loss of accessories, as well as the
							sending of an article other than the one sent by
							Pèndol Guies de Muntanya. The amount of the refund
							will be made in accordance with the rates set out in
							the APPENDIX to these GTC and a payment order will
							be generated in Pay-Pal or other authorised
							platforms, which must be paid within 72 hours. The
							CLIENT shall refrain in any case from transferring,
							renting, mortgaging, pledging, selling, lending or
							in any way giving as guarantee the rented equipment,
							its accessories, as well as its packaging.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SIXTH - Delivery Dates and Deadlines
						</Typography>
						<Typography>
							If the rented product, including packaging, is not
							returned on the agreed date, the CUSTOMER will pay
							Pèndol Guies de Muntanya as a penalty of 100% of the
							amount per day of delay on the total order and will
							be generated in Pay-Pal or other authorized
							platforms a payment order for that amount, which
							must be paid by the CUSTOMER within 48 hours.
						</Typography>{" "}
						<Typography>
							If within 4 calendar days from the agreed return
							date of the product, it is not in the possession of
							Pèndol Guies de Muntanya , the CUSTOMER agrees to
							pay the amount of the restitution of the article
							according to the rates listed in the APPENDIX to
							these GTC. The days considered will be counted from
							the end of the rental period until the day the item
							is returned to Pèndol Guies de Muntanya.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							SEVENTH - Cancellation of Reservations
						</Typography>
						<Typography>
							To cancel a reservation for which payment has been
							made, the CLIENT must contact Pèndol Guies de
							Muntanya through the means provided on its website,
							or by contacting directly by telephone or email as
							listed in the heading of these GTC.
						</Typography>{" "}
						<Typography>
							The penalties for cancellation of a booking will
							depend on how far in advance the CLIENT requests it
							and will be as follows:
						</Typography>{" "}
						<Typography>
							Cancellation more than 10 days before the delivery
							date à penalty of 0% of the total amount of the
							material.
						</Typography>
						<Typography>
							Cancellation between 3 and 6 days before the date of
							delivery à penalty of 50% of the total amount of the
							material.
						</Typography>{" "}
						<Typography>
							Cancellation with 2 days notice penalty of 100% of
							the total amount of the material.
						</Typography>{" "}
						<Typography>
							Failure of the CLIENT to show up on the date and
							time indicated by Pèndol Guies de Muntanya or
							cancellation of the equipment hire 24 hours before
							the start of the delivery date will result in the
							total loss of the amounts paid in advance.
						</Typography>{" "}
						<Typography>
							In the case of severe adverse conditions, the
							cancellation of the equipment rental will be
							accepted, keeping the total amount paid for another
							day.
						</Typography>{" "}
						<Typography variant="h2" className={classes.titol}>
							EIGHTH - Termination of the contract
						</Typography>
						<Typography>
							Pèndol Guies de Muntanya may terminate this contract
							by right and by means of a simple written
							communication, if any of the following circumstances
							occur: Serious breach of this contract. In this
							case, the lessor may take immediate possession of
							the material in the place where it is located,
							without the lessee being entitled to any type of
							claim or compensation. Inability to continue
							providing the service Due to unavailability of
							stock.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							NINTH.- Notifications
						</Typography>
						<Typography>
							For notification purposes, both Pèndol Guies de
							Muntanya and the CLIENT expressly designate the
							address stated in this contract.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							TENTH.- Applicable Jurisdiction
						</Typography>
						<Typography>
							In the event of any conflict or discrepancy arising
							in the interpretation or application of these
							contractual conditions, the Courts and Tribunals
							that will hear the matter, as the case may be, will
							be those provided for in the applicable legal
							regulations regarding competent jurisdiction. In the
							event that the CLIENT is domiciled outside Spain,
							both parties submit, expressly waiving any other
							jurisdiction, to the Courts and Tribunals of Berga
							(Berguedà).
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							ELEVENTH.- Data protection
						</Typography>
						<Typography>
							In compliance with Organic Law 15/1999 on the
							Protection of Personal Data, we inform you that the
							personal data you provide us with to carry out the
							contracting process and those derived from the
							provision of the service are confidential and will
							be included in a file of "Clients" under the
							responsibility of Pèndol Guies de Muntanya, in order
							to provide you with the contracted services, as well
							as to send you information about our services,
							including by e-mail. We also inform you that the
							personal data provided are treated internally and
							with the utmost confidentiality, applying the
							security measures regulated in RD 1720/2007 and that
							they are not published or transmitted to third
							parties to be used for commercial or promotional
							purposes. At any time you may object to receiving
							our commercial mailings, as well as exercise your
							rights of access, rectification, cancellation and
							opposition under the terms established by law before
							Pèndol Guies de Muntanya SCP, responsible for
							Personal Data, at C/ de l'alberg S/N, Vallcebre,
							08699, Barcelona.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							TWELFTH.- Commitment, acceptance and validity of the
							contract.
						</Typography>
						<Typography>
							The CLIENT acknowledges that he/she has understood
							all the information regarding the equipment rental
							service contracted, as well as all the conditions
							and stipulations contained in this contract, and
							therefore states that they are sufficient for the
							exclusion of error in the consent to this contract,
							and therefore accepts them fully and expressly.
						</Typography>
						<Typography variant="h2" className={classes.titol}>
							APPENDIX RENTAL CONDITIONS
						</Typography>
						<Typography>Price list of rented products:</Typography>
						<Typography>
							RENTAL ITEMS Pèndol Guies de Muntanya SCP
						</Typography>
						<Box my={4}>
							<TableContainer component={Paper}>
								<Table
									sx={{ minWidth: 650 }}
									aria-label="simple table"
								>
									<TableBody>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Material
											</TableCell>
											<TableCell align="right">
												RPR (refill)
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Neoprene adult and child
											</TableCell>
											<TableCell align="right">
												210 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Canyoning harness + pad
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Anchor ropes + 2 single
												carabiners + maillon rapide de
												8mm
											</TableCell>
											<TableCell align="right">
												15€ + 10€ - 6€{" "}
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Descender 8 + safety carabiner
											</TableCell>
											<TableCell align="right">
												10€ + 15€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Helmet
											</TableCell>
											<TableCell align="right">
												53 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Booties
											</TableCell>
											<TableCell align="right">
												15 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Canyoning backpack
											</TableCell>
											<TableCell align="right">
												65 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Watertight canister
											</TableCell>
											<TableCell align="right">
												23 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Multi-purpose harness
											</TableCell>
											<TableCell align="right">
												55 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Snowshoes + cover
											</TableCell>
											<TableCell align="right">
												75€ + 20€
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Trekking poles
											</TableCell>
											<TableCell align="right">
												50 €
											</TableCell>
										</TableRow>
										<TableRow
											sx={{
												"&:last-child td, &:last-child th":
													{
														border: 0,
													},
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												Metal avalanche shovel
											</TableCell>
											<TableCell align="right">
												45 €
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Container>
				)}
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingTop: 100,
		paddingBottom: 100,
	},
	root: {
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: 25,
		paddingTop: 40,
		paddingBottom: 20,
	},
}));

export default CondicionsLloguer;

import {
	Avatar,
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React from "react";
import Header from "../../../components/Header";
import Page from "../../../components/Layout/Page";
import Sanefa from "../../../components/Sanefa";
import img from "../../../assets/img/cats/raquetes_2.jpg";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon } from "../../../assets/img/logo-pendol.svg";
import usePageTracking from "../../../layouts/TrakingGA";
import theme from "../../../theme";

const QuiSomPage = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();
	usePageTracking();

	return (
		<Page title="Pèndol Guies - Qui som" className={classes.main}>
			<Header
				title={
					<Icon
						alt="Logo Pèndol Guies"
						className={matches ? classes.logo : classes.logoMobile}
					/>
				}
				img={img}
				logo
			/>
			<Sanefa color="white" />
			<Container maxWidth="sm">
				<Box textAlign="center" className={classes.section}>
					<Typography
						variant="h1"
						fontSize={matches ? 100 : 50}
						mt={4}
					>
						{t("Qui som")}
					</Typography>
					<Typography variant="body1" mt={4}>
						{t(
							"Pèndol Guies de Muntanya és una empresa d’esports d’aventura, ubicada a l’Alt Berguedà (Vallcebre) i creada per guies titulats i titulades de muntanya i barrancs. La nostra passió per la natura i els esports de muntanya, com el barranquisme, les vies ferrades, l’escalada i el senderisme, ens ha portat a racons màgics del nostre territori. De manera que hem fet de la nostra passió, una professió."
						)}
					</Typography>
					<Typography variant="body1" mt={4}>
						{t(
							"Gràcies als coneixements adquirits en el nostre recorregut en l'àmbit personal i professional, volem transmetre un tracte especial de cara als nostres usuaris i usuàries."
						)}
					</Typography>
					<Typography variant="body1" mt={4}>
						{t(
							"La seguretat, el tracte personalitzat, el respecte pel medi ambient, la diversió i la professionalitat són la nostra base. Ens volem destacar, per tal que els nostres clients/es puguin dur a terme les activitats de forma segura, adequada al seu nivell i al grup i que, d’aquesta manera, ens permeti donar a conèixer la nostra passió per la muntanya a tots i totes els que decidiu acompanyar-nos en la nostra aventura."
						)}
					</Typography>
					<Box my={3} />
					<Typography variant="caption" mt={4}>
						{t("Número de registre")}: C-593
					</Typography>
				</Box>
			</Container>
			<Sanefa color={theme.palette.background.main} />
			<Box className={classes.missio}>
				<Container>
					<Typography
						variant="h1"
						color="white"
						textAlign="left"
						mb={6}
					>
						{t("missio")}
					</Typography>
					<Grid spacing={2} container>
						<Grid item md={8}>
							<Box textAlign={"left"}>
								<Typography color="white" mb={2}>
									{t("missio_1")}
								</Typography>
								<Typography color="white" mb={2}>
									{t("missio_2")}
								</Typography>
								<Typography color="white" mb={2}>
									{t("missio_3")}
								</Typography>
								<Typography color="white" mb={2}>
									{t("missio_4")}
								</Typography>
							</Box>
						</Grid>
						<Grid item md={4}>
							<Avatar
								src={img}
								alt="Missió natura - Pèndol guies"
								sx={{
									width: "100%",
									height: "100%",
									borderRadius: 10,
								}}
							/>
						</Grid>
					</Grid>
					<Box textAlign="left" mt={3}>
						<Typography variant="h1" color="white">
							{t("visio")}
						</Typography>
						<Typography mb={2} color="white">
							{t("visio_1")}
						</Typography>
						<Typography mb={2} color="white">
							{t("visio_2")}
						</Typography>
					</Box>
				</Container>
			</Box>
			<Sanefa color="white" />
			<Box py={5}>
				<Container>
					<Typography variant="h1" mb={2}>
						{t("valors")}
					</Typography>
					<Box
						display={"flex"}
						alignItems="center"
						alignContent={"center"}
					>
						<Typography variant="h1" mr={2}>
							1
						</Typography>
						<Box mt={4}>
							<Typography mb={2} variant="h2" lineHeight={0}>
								{t("valors_1")}
							</Typography>
							<Typography mb={2}>{t("valors_1_1")}</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems="center"
						alignContent={"center"}
					>
						<Typography variant="h1" mr={2}>
							2
						</Typography>
						<Box mt={4}>
							<Typography mb={2} variant="h2" lineHeight={0}>
								{t("valors_2")}
							</Typography>
							<Typography mb={2}>{t("valors_2_1")}</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems="center"
						alignContent={"center"}
					>
						<Typography variant="h1" mr={2}>
							3
						</Typography>
						<Box mt={4}>
							<Typography mb={2} variant="h2" lineHeight={0}>
								{t("valors_3")}
							</Typography>
							<Typography mb={2}>{t("valors_3_1")}</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems="center"
						alignContent={"center"}
					>
						<Typography variant="h1" mr={2}>
							4
						</Typography>
						<Box mt={4}>
							<Typography mb={2} variant="h2" lineHeight={0}>
								{t("valors_4")}
							</Typography>
							<Typography mb={2}>{t("valors_4_1")}</Typography>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems="center"
						alignContent={"center"}
					>
						<Typography variant="h1" mr={2}>
							5
						</Typography>
						<Box mt={4}>
							<Typography mb={2} variant="h2" lineHeight={0}>
								{t("valors_5")}
							</Typography>
							<Typography mb={2}>{t("valors_5_1")}</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 700,
		marginBottom: 120,
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
	logoMobile: {
		width: 300,
		"& .cls-1": {
			fill: "white",
			opacity: 1,
		},
	},
	section: {
		paddingBottom: 150,
		paddingTop: 100,
	},
	missio: {
		paddingTop: 100,
		paddingBottom: 200,
		textAlign: "center",
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
}));

export default QuiSomPage;

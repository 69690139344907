import { Cancel } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import img from "../../../assets/img/cats/ponting.jpg";
import Sanefa from "../../../components/Sanefa";
import Page from "../../../components/Layout/Page";

const CondicionsGenerals = () => {
	const classes = Styles();
	const { t } = useTranslation();
	return (
		<Page title={"Pèndol Guies - Condicions Generals"}>
			<Box className={classes.root}>
				<Header title="Condicions Generals" img={img} small />
				<Sanefa color="white" />
				<Container maxWidth="lg" className={classes.main}>
					<Typography variant="h2" className={classes.titol}>
						0. OBJECTE
					</Typography>
					<Typography>
						Aquestes Condicions Generals regulen l'adquisició dels
						productes oferts al lloc web www.pendolguies.com del que
						és titular PÈNDOL GUIES DE MUNTANYA S.C.P (d'ara
						endavant, PÈNDOL GUIES).
					</Typography>{" "}
					<Typography>
						L'adquisició de qualsevol dels productes comporta
						l'acceptació plena i sense reserves de totes i cadascuna
						de les Condicions Generals que s'indiquen, sens
						perjudici de l'acceptació de les Condicions Particulars
						que puguin ser aplicables en adquirir determinats
						productes.
					</Typography>{" "}
					<Typography>
						Aquestes Condicions Generals podran ser modificades
						sense notificació prèvia, per tant, és recomanable
						llegir-ne atentament el contingut abans de procedir a
						l'adquisició de qualsevol dels productes oferts.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						1. IDENTIFICACIÓ
					</Typography>
					<Typography>
						PÈNDOL GUIES DE MUNTANYA S.C.P, en compliment de la Llei
						34/2002, d'11 de juliol, de Serveis de la Societat de la
						Informació i de Comerç Electrònic, us informa que:
					</Typography>{" "}
					<Typography>
						{" "}
						• La seva denominació social és: PÈNDOL GUIES DE
						MUNTANYA S.C.P.
					</Typography>{" "}
					<Typography> • El seu CIF es: J09920877 </Typography>{" "}
					<Typography>
						• El seu domicili social es a: C/ de l'alberg s/n, 08699
						Vallcebre – Barcelona
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						2. COMUNICACIONS
					</Typography>
					<Typography>
						Par comunicar-se amb nosaltres, posem a la seva
						disposició diferents mitjans de contacte que li detallem
						a continuació:
					</Typography>{" "}
					<Typography>• Telèfon: (34) 650 85 34 79</Typography>{" "}
					<Typography>• Email: info@pendolguies.com</Typography>
					<Typography>
						Totes les notificacions i comunicacions entre els
						usuaris i EL PROPIETARI DE LA WEB es consideraran
						eficaços, a tots els efectes, quan es realitzin a través
						de correu postal o qualsevol altre mitjà dels detallats
						anteriorment.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						3. SERVEIS
					</Typography>
					<Typography>
						Els serveis oferts, juntament amb les característiques
						principals dels mateixos i el seu preu apareixen a la
						pantalla.
					</Typography>{" "}
					<Typography>
						PÈNDOL GUIES es reserva el dret de decidir, a cada
						moment, els productes i/o serveis que s'ofereixen als
						Clients. D'aquesta manera, PÈNDOL GUIES podrà, en
						qualsevol moment, afegir nous productes i/o serveis als
						oferts actualment. Així mateix, PÈNDOL GUIES es reserva
						el dret a retirar o deixar d'oferir, en qualsevol
						moment, i sense avís previ, qualsevol dels serveis
						oferts.
					</Typography>{" "}
					<Typography>
						Un cop dins de www.pendolguies.com i per accedir a la
						contractació dels diferents serveis, l'Usuari haurà de
						seguir totes les instruccions indicades en el procés de
						compra, fet que suposarà la lectura i acceptació de
						totes les condicions generals i particulars fixades a
						www.pendolguies.com.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						4. PREUS, DESPESES I IMPOSTOS
					</Typography>
					<Typography>
						Els preus dels serveis que es recullen a
						www.pendolguies.com vindran expressats en euros llevat
						que s'expressi el contrari i estaran garantits durant el
						temps de validesa d'aquests, excepte error d'impressió i
						sigui quina sigui la destinació de la comanda.
					</Typography>{" "}
					<Typography>SERVEIS</Typography>{" "}
					<Typography>
						Els preus finals de tots els serveis, juntament amb les
						característiques, les ofertes, descomptes, etc. es
						reflecteixen a cadascun dels serveis que s'ofereixen a
						www.pendolguies.com.
					</Typography>
					<Typography>• Els serveis tenen IVA inclòs.</Typography>
					<Typography>• El tipus d'IVA és del 21%.</Typography>
					<Typography>
						Els preus dels serveis poden variar en qualsevol moment.
						En aquest cas, el preu a aplicar serà el vigent a la
						data de realització de la comanda. De qualsevol manera,
						el cost final serà comunicat al client en el procés de
						compra electrònica abans que aquest formalitzi la seva
						acceptació.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						5. PROCÉS DE COMPRA I/O CONTRACTACIÓ
					</Typography>
					<Typography>
						Per realitzar qualsevol compra a www.pendolguies.com,
						cal que el client sigui més gran de 18 anys.
					</Typography>
					<Typography>
						A més, caldrà que el client es registri a
						www.pendolguies.com a través d'un formulari de recollida
						de dades on es proporcioni a PÈNDOL GUIES la informació
						necessària per a la contractació; dades que en qualsevol
						cas seran veraces, exactes i complets sobre la seva
						identitat i que el client haurà de consentir
						expressament mitjançant l'acceptació de la política de
						privadesa de PÈNDOL GUIES.
					</Typography>
					<Typography>
						Els passos a seguir per fer la compra són:
					</Typography>
					<Typography>
						1. Accediu al web i feu la compra d’activitats o lloguer
						de material
					</Typography>
					<Typography>
						2. L'única forma de pagament serà amb targeta de crèdit
						o dèbit.
					</Typography>
					<Typography>
						Així mateix, és recomanable que el client imprimeixi i/o
						guardi una còpia en suport durador de les condicions de
						venda en realitzar la seva comanda, així com també del
						comprovant de recepció enviat per correu electrònic.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						6. RESPONSABILITAT I EXONERACIÓ DE RESPONSABILITAT
					</Typography>
					<Typography>
						PÈNDOL GUIES podrà suspendre, retirar o cancel·lar
						parcialment o totalment els serveis sotmesos a
						contractació en qualsevol moment i sense necessitat
						d'avís previ. La previsió anterior no afectarà aquells
						serveis que estan reservats per a usuaris registrats o
						que són objecte de contractació prèvia i que es regiran
						per les seves condicions específiques.{" "}
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						7. POLÍTICA DE DEVOLUCIONS I DESISTIMENT
					</Typography>
					<Typography>
						Segons estableix la normativa, el consumidor i usuari
						tindrà dret a DESISTIR del contracte durant un període
						màxim de 14 dies naturals sense necessitat d'indicar-ne
						el motiu i sense incórrer en cap cost, llevat dels
						previstos a l'art. 107.2 i 108 del RD1/2007, de 16 de
						novembre, pel qual s'aprova el text referit de la Llei
						general per a la defensa dels consumidors i usuaris.
					</Typography>
					<Typography>
						Aquest dret de desistiment no és aplicable entre
						d'altres a:
					</Typography>
					<Typography>
						• Els contractes de subministraments de béns el preu
						dels quals estigui subjecte a fluctuacions de
						coeficients del mercat financer que l'empresari no pugui
						controlar.
					</Typography>
					<Typography>
						• Els contractes de subministrament de productes
						confeccionats d'acord amb les especificacions del
						consumidor i usuari o, clarament personalitzats, o que
						per la seva naturalesa no puguin ser tornats o puguin
						deteriorar-se o caducar amb rapidesa.
					</Typography>
					<Typography>
						• Subministrament de béns precintats que no poden ser
						tornats per raons de salut o higiene.
					</Typography>
					<Typography>
						• Subministrament de béns que després del lliurament
						s'hagin barrejat amb altres.
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						8. ATENCIÓ AL CLIENT I RECLAMACIONS
					</Typography>
					<Typography>
						Per interposar qualsevol queixa o reclamació davant de
						PÈNDOL GUIES el client haurà d'adreçar-se al departament
						d'atenció al client bé al número de telèfon (34) 650 85
						34 79, bé a través de l'adreça de correu electrònic
						info@pendolguies.com
					</Typography>
					<Typography>
						Segons la Resolució de litigis en línia en matèria de
						consum de la UE i conforme a l'Art. 14.1 del Reglament
						(UE) 524/2013, us informem que la Comissió europea
						facilita a tots els consumidors una plataforma de
						resolució de litigis en línia que es troba disponible al
						següent enllaç:http://ec.europa.eu/consumers/odr/.{" "}
					</Typography>
					<Typography variant="h2" className={classes.titol}>
						9. LEGISLACIÓ APLICABLE
					</Typography>
					<Typography>
						Les condicions presents es regiran per la legislació
						espanyola vigent.
					</Typography>
					<Typography>
						La llengua utilitzada serà el Català.
					</Typography>
				</Container>
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingTop: 100,
		paddingBottom: 100,
	},
	root: {
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: 25,
		paddingTop: 40,
		paddingBottom: 20,
	},
}));

export default CondicionsGenerals;

import { Avatar, ImageList, ImageListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const ThumbMulti = (props) => {
    const [thumb, setThumb] = useState([]);

    useEffect(() => {
        const getImages = async () => {
            if (props?.file?.[0]?.name) {
                let imatges = [];
                Array.from(props?.file).forEach((image) => {
                    imatges.push(URL.createObjectURL(image));
                });
                setThumb(imatges);
            } else {
                let imatges = props.file?.map(
                    (item) =>
                        'https://pendolguies.covcontrol.net/public/storage/' +
                        item.foto
                );
                setThumb(imatges);
            }
        };
        getImages();
    }, [props?.file]);

    return (
        <ImageList sx={{ width: '100%' }} cols={2} rowHeight={164}>
            {thumb?.map((item) => (
                <ImageListItem key={item}>
                    <Avatar
                        variant="rounded"
                        src={item}
                        alt={item.title}
                        style={{ width: '100%', height: '100%' }}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

export default ThumbMulti;

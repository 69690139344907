import * as React from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Activity, Calendar, CheckSquare, Package } from "react-feather";
import { getElementsAdmin, getElements } from "../database/API";
import { LocalMall } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
	kml: {
		borderRight: "1px solid " + theme.palette.background.light,
		paddingRight: 20,
		paddingLeft: 20,
		paddingTop: 25,
		paddingBottom: 25,
	},
	icon: {
		paddingRight: 10,
	},
	loading: {
		fontSize: 10,
	},
}));

export default function KPI() {
	const classes = useStyles();
	const [kpi, setKpi] = React.useState();
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const get = async () => {
			const { result } = await getElementsAdmin("kpi");
			setKpi(result);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<Box>
			<Grid
				container
				sx={{
					alignItems: "center",
					borderRadius: 2,
					bgcolor: "background.paper",
					color: "text.secondary",
					padding: 0,
				}}
			>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Activitats </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<Activity size={"35px"} className={classes.icon} />
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.activitats}
							</Typography>
						)}
					</Box>
				</Grid>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Material </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<Package size={"35px"} className={classes.icon} />
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.material}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Reserves </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<CheckSquare size={"35px"} className={classes.icon} />
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.reserves}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Lloguers </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<LocalMall size={"35px"} className={classes.icon} />
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2">
								{kpi?.lloguers}
							</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

import * as React from 'react';
import {
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CustomMultiSelect({
    register,
    name,
    label,
    elements,
    value,
    control,
}) {
    return (
        <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel
                shrink={true}
                htmlFor={name}
                id="demo-multiple-chip-label"
            >
                {label}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        label={label}
                        name={name}
                        {...field}
                        notched={true}
                        multiple
                        defaultValue={[]}
                        input={
                            <OutlinedInput
                                id="select-multiple-chip"
                                label={label}
                            />
                        }
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                }}
                            >
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {elements?.map((item) => (
                            <MenuItem key={item.id} value={item.nom}>
                                {item.nom}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>
    );
}

import * as React from "react";
import {
	Box,
	Chip,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Check, CheckCircle, ExternalLink, Link, Link2 } from "react-feather";
import { useNavigate } from "react-router";
import familia from "../../assets/img/familia.svg";

const useStyles = makeStyles((theme) => ({
	activitat: {
		borderRadius: 10,
		paddingLeft: 10,
	},
	activitatInner: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	activitat_1: {
		display: "flex",
		justifyContent: "space-between",
	},
	activitat_2: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
	},
}));

export default function ActivitatAdmin({ activitat }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("user")) || "";

	return (
		<Box className={classes.activitat}>
			<Box className={classes.activitatInner}>
				<Box className={classes.activitat_1}>
					<Box mr={2}>
						<img
							src={
								"https://pendolguies.covcontrol.net/public/storage/" +
								activitat?.imatges[0].foto
							}
							style={{ width: 120, borderRadius: 5 }}
							alt={activitat?.nom}
						/>
					</Box>
					<Box>
						<Typography
							variant="h3"
							textTransform={"uppercase"}
							mb={1}
						>
							{activitat?.langs?.[0]?.nom}
						</Typography>
						{activitat?.situacio !== "null" && (
							<Typography>
								Situació: {activitat?.situacio}
							</Typography>
						)}
						{activitat?.edat !== "null" && (
							<Typography>
								Edat mínima: {activitat?.edat} anys
							</Typography>
						)}
						{activitat?.epoca !== "null" && (
							<Typography>
								Època: {activitat?.langs?.[0]?.epoca}
							</Typography>
						)}
						{activitat?.durada !== "null" && (
							<Typography>
								Durada: {activitat?.langs?.[0]?.durada}
							</Typography>
						)}
					</Box>
				</Box>
				<Box className={classes.activitat_2}>
					{user.role_id === "1" && (
						<IconButton
							onClick={() =>
								navigate(
									"/admin/activitats/edit/" + activitat?.id
								)
							}
						>
							<ExternalLink />
						</IconButton>
					)}
				</Box>
			</Box>
			<Divider />
			<Box mt={2} display="flex">
				{activitat?.familia === "1" && (
					<Box mr={1}>
						<Tooltip title="Activitat familiar">
							<img
								src={familia}
								alt="Activitat familiar"
								width={30}
							/>
						</Tooltip>
					</Box>
				)}
				{activitat?.destacada === "1" && (
					<Box mr={1}>
						<Tooltip title="Destacada">
							<CheckCircle size={30} />
						</Tooltip>
					</Box>
				)}
				<Chip label={activitat?.categoria.nom} />
			</Box>
		</Box>
	);
}

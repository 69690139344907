import * as React from 'react';
import {
    SignalCellular1Bar,
    SignalCellular2Bar,
    SignalCellular3Bar,
    SignalCellular4Bar,
} from '@mui/icons-material';

export default function Dificultat({ value }) {
    switch (value) {
        case '1':
            return <SignalCellular1Bar sx={{ color: '#91c67d' }} />;
        case '2':
            return <SignalCellular2Bar sx={{ color: '#e6dc7c' }} />;
        case '3':
            return <SignalCellular3Bar sx={{ color: '#ec963f' }} />;
        case '4':
            return <SignalCellular4Bar sx={{ color: '#e75a5d' }} />;
        default:
            break;
    }
}

import * as React from "react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	IconButton,
	Input,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { Trash } from "react-feather";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	talla: {
		borderRadius: 20,
		padding: 10,
		marginBottom: 20,
		boxShadow: "0px 0px 8px 0px #00000030",
		display: "flex",
		alignItems: "center",
	},
	input: {
		marginLeft: 8,
		marginRight: 8,
		minWidth: 25,
	},
	input_preu: {
		marginLeft: 8,
		marginRight: 8,
		minWidth: 35,
	},
}));

export default function Preus({
	register,
	fields,
	remove,
	append,
	replace,
	control,
	watch,
}) {
	const classes = useStyles();
	const cat = watch("categoria_id");

	React.useEffect(() => {
		console.log(cat);
	}, [cat]);

	return (
		<Box>
			{fields.map((item, index) => {
				return (
					<Box className={classes.talla} key={item.id}>
						{cat === "4" ? (
							<>
								<Typography>Salt nº {index + 1}: </Typography>
							</>
						) : (
							<>
								<Typography>De</Typography>
								<Input
									placeholder="Mínim"
									type="number"
									{...register(`preus.${index}.min`)}
									className={classes.input}
								/>
								<Typography>a</Typography>
								<Input
									placeholder="Màxim"
									type="number"
									{...register(`preus.${index}.max`)}
									className={classes.input}
								/>
								<Typography>p,</Typography>{" "}
							</>
						)}

						<Input
							placeholder="Preu"
							type="number"
							{...register(`preus.${index}.preu`)}
							className={classes.input_preu}
						/>
						<Typography> € </Typography>
						<Controller
							name={`preus.${index}.unic`}
							control={control}
							render={({ field }) => (
								<Tooltip title={"Preu unic"}>
									<FormControlLabel
										style={{ margin: 0 }}
										control={
											<Checkbox
												onChange={(e) =>
													field.onChange(
														e.target.checked
													)
												}
												size={"small"}
												checked={
													field.value === "1" ||
													field.value === true
												}
											/>
										}
									/>
								</Tooltip>
							)}
						/>
						<IconButton size="small" onClick={() => remove(index)}>
							<Trash size={15} />
						</IconButton>
					</Box>
				);
			})}
			<Box textAlign="center">
				<IconButton
					onClick={() =>
						append({
							min: null,
							max: null,
							preu: null,
							unic: false,
						})
					}
					color="primary"
				>
					<Add />
				</IconButton>
				<IconButton onClick={() => replace([])}>
					<Trash />
				</IconButton>
			</Box>
		</Box>
	);
}

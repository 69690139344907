import { Cancel } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import img from "../../../assets/img/cats/ponting.jpg";
import Sanefa from "../../../components/Sanefa";
import Page from "../../../components/Layout/Page";

const CondicionsActivitats = () => {
	const classes = Styles();
	const { t, i18n } = useTranslation();
	return (
		<Page title={"Pèndol Guies - Condicions activitats"}>
			<Box className={classes.root}>
				<Header title={t("Condicions activitats")} img={img} small />
				<Sanefa color="white" />
				{i18n.language === "ca" ? (
					<Container maxWidth="lg" className={classes.main}>
						<Typography>
							1a.- PÈNDOL GUIES DE MUNTANYA SCP. Amb CIF:
							J09920877, amb número de cens d'organitzador
							d'activitats al medi natural no C-593 es compromet
							que:
						</Typography>{" "}
						<Typography>
							- El personal estigui titulat/habilitat i capacitat
							per a l'activitat que es realitzarà.
						</Typography>{" "}
						<Typography>
							- Les activitats estan cobertes per assegurança de
							responsabilitat civil per la companyia LLOYDS de la
							pòlissa LLO2023000203 i assegurança d'accidents per
							la companyia PREVISIÓN BALEAR, M.P.S amb pòlissa
							4020011374.
						</Typography>
						<Typography>
							- Les activitats estan adequadament planificades,
							especialment pel que fa a la gestió de riscos i
							adversitats.
						</Typography>{" "}
						<Typography>
							2a.- El/la participant s'informarà degudament de
							l'activitat a realitzar, per tenir la convicció que
							aquesta activitat és la que desitja realitzar, i és
							l'adequada a les seves característiques personals.
						</Typography>{" "}
						<Typography>
							3a.- Excepte indicació contrària al programa, serà
							de càrrec i compte del/la participant els costos i
							responsabilitat del transport fins al lloc on
							s'imparteixi el curs o es desenvolupi l'activitat.
							El/la participant arribarà a les activitats amb
							antelació suficient per resoldre qualsevol imprevist
							o necessitat. L'acumulació de petits retards
							incideix directament i molt negativament en la
							seguretat, l'harmonia del grup i el bon
							desenvolupament de l'activitat.
						</Typography>{" "}
						<Typography>
							4a.- El/la participant notificarà als/a les
							responsables de l'activitat qualsevol incidència
							ocorreguda durant el desplaçament a la mateixa
							(físiques, psíquiques, material, etc.) encara que
							puguin semblar insignificants, com marejos pel
							viatge, debilitat per un mal esmorzar, etc. Així
							mateix, advertirà l'organització en cas d'estar amb
							medicació de substàncies que puguin alterar-ne les
							aptituds habituals.
						</Typography>
						<Typography>
							5a.- Les activitats estan subjectes a les condicions
							meteorològiques hi ha les característiques de les
							persones que hi participin. Els horaris, itineraris
							i formes a seguir, podran modificar-se o fins i tot
							suspendre's totalment o en part, si les
							circumstàncies ho requereix i sempre sota el criteri
							de l'empresa, els monitors/es, tècnics, guies de
							muntanya o instructors/es. En aquests casos, no es
							procedirà al reemborsament de cap import abonat
							pel/per la participant, atesa la imprevisibilitat
							d'aquests factors (sempre que l'activitat ja hagués
							començat), ÉS PROCEDIRIA A CANVI DE DATES SENSE CAP
							COST ADDICIONAL. En cas que encara no hagués
							començat l'activitat suspesa.
						</Typography>{" "}
						<Typography>
							6a.- Política de cancel·lació: Es tornarà l'import
							íntegre quan: Es cancel·li l'activitat amb deu dies
							d'antelació. Aviseu a l’empresa la seva cancel·lació
							per qüestions meteorològiques, en aquest cas
							l'empresa podrà fer alguna proposta de canvi
							d'activitat o de data, segons les condicions.
						</Typography>
						<Typography>
							Es retornarà el 50% de la reserva quan: Es cancel·li
							l'activitat amb menys de deu dies d'antelació.
						</Typography>{" "}
						<Typography>
							No es tornarà el 100% de l'activitat quan: Es
							cancel·li l'activitat amb menys de 72 hores
							d'antelació o disminueixi el nombre de participants.
						</Typography>{" "}
						<Typography>
							7a.- A cada activitat s'especificarà el tipus i
							quantitat de material necessari. Si a algun/a
							participant li faltés algun element imprescindible
							de l'equip, els responsables de l'activitat podran
							suspendre o endarrerir-ne el començament fins que
							aquest problema sigui esmenat o fins i tot optar per
							continuar amb el programa sense la presència de la
							persona afectada, si la manca d'aquests elements són
							imputable al/la participant.
						</Typography>
						<Typography>
							8a.- La sessió teòrica o explicativa prèvia a
							l'activitat, es considera INICI dels serveis. El/la
							participant prestarà i mantindrà, durant tot el
							desenvolupament de l'activitat, la deguda atenció a
							les instruccions que es puguin impartir per part de
							l'organització. Tindrà una atenció molt especial als
							monitors/es, tècnics, guies de muntanya o
							instructors/es responsables de l'activitat, seguint
							totes les indicacions d'actuació i/o seguretat que
							li puguin proporcionar. Sense coneixement i expressa
							autorització dels/de les responsables, el/la
							participant no se situarà en llocs potencialment
							perillosos com a grans desnivells, precipicis, sòls
							inestables, corrents d'aigua turbulenta, avencs,
							plaques de gel, zones de gel, despreniments, allaus,
							etc. Qualsevol incompliment d'aquestes pautes podrà
							donar lloc a l'exclusió del/de la participant
							incomplidor/a fins i tot a ser baixa al curs o
							activitat, amb pèrdua de tots els seus drets. El/la
							monitors/es, tècnics, guies de muntanya o
							instructors/es responsables de l'activitat, tenen
							autoritat per suspendre una activitat o impedir que
							un/a participant continuï en ella si suposa un
							perill per a ell/ella mateix/a o la resta del grup.
							PÈNDOL GUIES DE MUNTANYA declina tota
							responsabilitat davant de qualsevol accident produït
							per la imprudència, negligència o temeritat de
							qualsevol participant.
						</Typography>{" "}
						<Typography>
							9a.- El/la participant mantindrà en tot moment posat
							i ben instal·lat tot l'equipament específic de
							l'activitat, intensificant la seva atenció i cures
							amb l'equipament de protecció i seguretat, tant
							individual com col·lectiu, conservarà el material
							assignat per a les activitats, i comunicarà
							instantàniament si es produeix algun deteriorament o
							pèrdua durant la realització d'aquestes activitats.
							Els equips per al desenvolupament de les activitats
							no són instruments de joc i la seva bona utilització
							farà que l'activitat sigui molt més agradable i
							segura. En cas de pèrdua o trencament del material
							prestat o llogat per PÈNDOL GUIES DE MUNTANYA que
							sigui degut a la negligència del/la participant,
							aquest/a podrà ser obligat/da a abonar-ne el valor o
							a reposar-ho.
						</Typography>
						<Typography>
							10a.- El/la participant respectarà profundament el
							medi natural on es desenvolupi l'activitat,
							intensificant aquesta cura a les zones d'espais
							naturals protegits. A l'entorn natural no han de
							quedar petjades del seu pas per ell. Respectarà
							igualment a altres grups o persones que estiguin a
							les zones on es realitzin les activitats.
						</Typography>
						<Typography>
							11a.- El/la participant dona el seu consentiment a
							utilitzar les imatges que se'n prenguin en el
							transcurs de les activitats per a campanyes
							d'informació/publicitat que PÈNDOL GUIES DE MUNTANYA
							emprengui en el futur i per mostrar-la a altres
							participants que facin cursos i/o activitats amb
							aquesta empresa, excepte indicació en contra.
						</Typography>
						<Typography>
							12a.- En finalitzar l'activitat, el/la participant
							lliurarà tot el material assignat als/les
							responsables d'aquesta.
						</Typography>
					</Container>
				) : i18n.language === "es" ? (
					<Container maxWidth="lg" className={classes.main}>
						<Typography>
							1a.- PÉNDOL GUÍAS DE MUNTANYA SCP. Con CIF:
							J09920877, con número de censo de organizador de
							actividades en el medio natural no C-470 se
							compromete a que:
						</Typography>{" "}
						<Typography>
							- El personal esté titulado/habilidad y capacidad
							para la actividad que se va a realizar.
						</Typography>{" "}
						<Typography>
							- Las actividades están cubiertas por seguro de
							responsabilidad civil por la compañía Berkley
							Company España, póliza no 120060630. y seguro de
							accidentes por la compañía Berkley Company España
							póliza no 1300010834.
						</Typography>
						<Typography>
							- Las actividades están adecuadamente planificadas,
							especialmente en lo que se refiere a la gestión de
							riesgos y adversidades.
						</Typography>{" "}
						<Typography>
							2ª.- El/la participante se informará debidamente de
							la actividad a realizar, por tener la convicción de
							que esta actividad es la que desea realizar, y es la
							adecuada a sus características personales.
						</Typography>{" "}
						<Typography>
							3ª.- Salvo indicación contraria al programa, será de
							cargo y cuenta del/la participante los costes y
							responsabilidad del transporte hasta el lugar donde
							se imparta el curso o se desarrolle la actividad.
							El/la participante llegará a las actividades con
							suficiente antelación para resolver cualquier
							imprevisto o necesidad. La acumulación de pequeños
							retrasos incide directamente y mucho negativamente
							en la seguridad, armonía del grupo y buen desarrollo
							de la actividad.
						</Typography>{" "}
						<Typography>
							4ª.- El/la participante notificará a los/las
							responsables de la actividad cualquier incidencia
							ocurrida durante el desplazamiento a la misma
							(físicas, psíquicas, material, etc.) aunque puedan
							parecer insignificantes, como mareos por el viaje,
							debilitado por un mal desayuno, etc. Asimismo,
							advertirá a la organización en caso de estar con
							medicación de sustancias que puedan alterar sus
							aptitudes habituales.
						</Typography>
						<Typography>
							5a.- Las actividades están sujetas a las condiciones
							meteorológicas están las características de las
							personas que participen. Los horarios, itinerarios y
							formas a seguir, podrán modificarse o incluso
							suspenderse totalmente o en parte, si las
							circunstancias lo requiere y siempre bajo el
							criterio de la empresa, monitores/as, técnicos,
							guías de montaña o instructores /es. En estos casos,
							no se procederá al reembolso de ningún importe
							abonado por el/la participante, dada la
							imprevisibilidad de estos factores (siempre que la
							actividad ya hubiera comenzado), ES PROCEDIría A
							CAMBIO DE FECHAS SIN COSTE ADICIONAL. En caso de que
							todavía no hubiera comenzado la actividad
							suspendida.
						</Typography>{" "}
						<Typography>
							6ª.- Política de cancelación: Se devolverá el
							importe íntegro cuando: Se cancele la actividad con
							diez días de antelación. Avise a la empresa su
							cancelación por cuestiones meteorológicas, en cuyo
							caso la empresa podrá hacer alguna propuesta de
							cambio de actividad o de fecha, según las
							condiciones.
						</Typography>
						<Typography>
							Se devolverá el 50% de la reserva cuando: Se cancele
							la actividad con menos de diez días de antelación.
						</Typography>{" "}
						<Typography>
							No se devolverá el 100% de la actividad cuando: Se
							cancele la actividad con menos de 72 horas de
							antelación o disminuya el número de participantes.
						</Typography>{" "}
						<Typography>
							7ª.- En cada actividad se especificará el tipo y
							cantidad de material necesario. Si a algún/a
							participante le faltara algún elemento
							imprescindible del equipo, los responsables de la
							actividad podrán suspender o retrasar su comienzo
							hasta que este problema sea enmendado o incluso
							optar por continuar con el programa sin la presencia
							de la persona afectada, si la carencia de estos
							elementos son imputable al/la participante.
						</Typography>
						<Typography>
							8ª.- La sesión teórica o explicativa previa a la
							actividad, se considera INICIO de los servicios.
							El/la participante prestará y mantendrá, durante
							todo el desarrollo de la actividad, la debida
							atención a las instrucciones que puedan impartirse
							por parte de la organización. Tendrá una atención
							muy especial a los monitores/as, técnicos, guías de
							montaña o instructores/as responsables de la
							actividad, siguiendo todas las indicaciones de
							actuación y/o seguridad que le puedan proporcionar.
							Sin conocimiento y expresa autorización de los/las
							responsables, el/la participante no se situará en
							lugares potencialmente peligrosos como grandes
							desniveles, precipicios, suelos inestables,
							corrientes de agua turbulenta, simas, placas de
							hielo, zonas de hielo, desprendimientos, aludes,
							etc. Cualquier incumplimiento de estas pautas podrá
							dar lugar a la exclusión del/de la participante
							incumplidor/a incluso a ser baja en el curso o
							actividad, con pérdida de todos sus derechos. El/la
							monitores/as, técnicos, guías de montaña o
							instructores/as responsables de la actividad, tienen
							autoridad para suspender una actividad o impedir que
							uno/a participante continúe en ella si supone un
							peligro para él/ella mismo/a o el resto del grupo.
							PÉNDULO GUÍAS DE MONTAÑA declina toda
							responsabilidad ante cualquier accidente producido
							por la imprudencia, negligencia o temeridad de
							cualquier participante.
						</Typography>{" "}
						<Typography>
							9ª.- El/la participante mantendrá en todo momento
							puesto y bien instalado todo el equipamiento
							específico de la actividad, intensificando su
							atención y cuidados con el equipamiento de
							protección y seguridad, tanto individual como
							colectivo, conservará el material asignado para las
							actividades, y comunicará instantáneamente si se
							produce algún deterioro o pérdida durante la
							realización de estas actividades. Los equipos para
							el desarrollo de las actividades no son instrumentos
							de juego y su buena utilización hará que su
							actividad sea mucho más agradable y segura. En caso
							de pérdida o rotura del material prestado o
							alquilado por PÉNDULO GUÍAS DE MONTAÑA que se deba a
							la negligencia del/la participante, este/a podrá ser
							obligado/a a abonar su valor oa reponerlo.
						</Typography>
						<Typography>
							10a.- El/la participante respetará profundamente el
							medio natural donde se desarrolle la actividad,
							intensificando este cuidado en las zonas de espacios
							naturales protegidos. En el entorno natural no deben
							quedar huellas de su paso por él. Respetará
							igualmente a otros grupos o personas que estén en
							las zonas donde se realicen las actividades.
						</Typography>
						<Typography>
							11ª.- El/la participante da su consentimiento a
							utilizar las imágenes que se tomen en el transcurso
							de las actividades para campañas de
							información/publicidad que PÉNDULO GUÍAS DE MONTAÑA
							emprenda en el futuro y para mostrarla a otros
							participantes que realicen cursos y/o actividades
							con esta empresa, salvo indicación en contrario.
						</Typography>
						<Typography>
							12ª.- Al finalizar la actividad, el/la participante
							entregará todo el material asignado a los/las
							responsables de ésta.
						</Typography>
					</Container>
				) : (
					<Container maxWidth="lg" className={classes.main}>
						<Typography>
							1a.- PÈNDOL GUIES DE MUNTANYA SCP. CIF: J09920877,
							with census number of organizer of activities in the
							natural environment no C-470 undertakes that:
						</Typography>{" "}
						<Typography>
							- The staff is qualified/skilled and capable for the
							activity to be carried out.
						</Typography>{" "}
						<Typography>
							- The activities are covered by civil liability
							insurance by the company Berkley Company España,
							policy no. 120060630. and accident insurance by the
							company Berkley Company España policy no 1300010834.
						</Typography>
						<Typography>
							- The activities are adequately planned, especially
							with regard to risk and adversity management.
						</Typography>{" "}
						<Typography>
							2nd - The participant will be duly informed of the
							activity to be carried out, as he/she is convinced
							that this activity is the one he/she wishes to carry
							out, and is suitable for his/her personal
							characteristics.
						</Typography>{" "}
						<Typography>
							3rd - Unless otherwise indicated in the programme,
							the participant will be responsible for the costs
							and responsibility of transport to the place where
							the course is given or the activity takes place. The
							participant will arrive at the activities
							sufficiently in advance to resolve any unforeseen
							circumstances or needs. The accumulation of small
							delays has a direct and very negative impact on the
							security negatively affects the safety, harmony of
							the group and the smooth running of the activity.
						</Typography>{" "}
						<Typography>
							4th.- The participant will notify those responsible
							for the activity of any incident occurring during
							the journey to the activity (physical, mental,
							material, etc.) even if they may seem insignificant,
							such as dizziness due to the journey, weakened by a
							bad breakfast, etc. Likewise, he/she shall warn the
							organisation in the event of being on medication of
							substances that may alter his/her usual aptitudes.
							usual aptitudes.
						</Typography>
						<Typography>
							5a.- The activities are subject to weather
							conditions and the characteristics of the people
							taking part. The schedules, itineraries, and ways to
							follow may be modified or even suspended totally or
							partially, if the circumstances require it and
							always under the criteria of the company, monitors,
							technicians, mountain guides or instructors. In
							these cases, no refund of any amount paid by the
							participant will be made, given the unpredictability
							of these factors (provided that the activity has
							already begun), it WILL PROCEDURE A CHANGE OF DATES
							AT NO ADDITIONAL COST. In the event that the
							suspended activity has not yet started.
						</Typography>{" "}
						<Typography>
							6ª.- Cancellation policy: The full amount will be
							refunded when: The activity is cancelled ten days in
							advance. You notify the company of your cancellation
							due to weather conditions, in which case the company
							may propose a change of activity or date, depending
							on the conditions.
						</Typography>
						<Typography>
							50% of the reservation will be refunded when: The
							activity is cancelled less than ten days before the
							activity is due to take place.
						</Typography>{" "}
						<Typography>
							100% of the activity will not be refunded when: The
							activity is cancelled with less than 72 hours notice
							or when the number of participants is reduced.
						</Typography>{" "}
						<Typography>
							7ª.- Each activity will specify the type and
							quantity of material required. If any participant is
							missing any essential piece of equipment, those
							responsible for the activity may suspend or delay
							the start of the activity until this problem has
							been rectified, or even decide to continue with the
							programme without the presence of the person
							concerned, if the lack of these elements is
							attributable to the participant.
						</Typography>
						<Typography>
							8ª.- The theoretical or explanatory session prior to
							the activity is considered the START of the
							services. The participant will pay and maintain,
							throughout the development of the activity, due
							attention to the instructions that may be given by
							the organisation. He/she will pay special attention
							to the monitors, technicians, mountain guides or
							instructors responsible for the activity, following
							all the indications for action and/or safety that
							they may provide. Without the knowledge and express
							authorisation of those responsible, the participant
							shall not go to potentially dangerous places such as
							steep slopes, precipices, unstable ground, turbulent
							water currents, chasms, ice sheets, icy areas,
							landslides, avalanches, etc. Any non-compliance with
							these guidelines may result in the exclusion of the
							non-compliant participant and even the cancellation
							of the course or activity, with the loss of all
							rights. The monitors, technicians, mountain guides
							or instructors responsible for the activity have the
							authority to suspend an activity or prevent a
							participant from continuing if it poses a risk to
							the participant. participant to continue with the
							activity if it poses a danger to him/herself or the
							rest of the group. PÈNDOL MOUNTAIN GUIDES declines
							all responsibility for any accident caused by the
							imprudence, negligence, or recklessness of any
							participant.
						</Typography>{" "}
						<Typography>
							9ª.- The participant will keep all the specific
							equipment for the activity in place and well
							installed at all times, intensifying his/her
							attention and care with the protection and safety
							equipment, both individual and collective, will
							conserve the material assigned for the activities,
							and will communicate instantly if any deterioration
							or loss occurs during the carrying out of these
							activities. The equipment for the development of the
							activities are not play instruments and their proper
							use will make the activity much more pleasant and
							safe. In case of loss or breakage of the equipment
							lent or rented by PÈNDOL MOUNTAIN GUIDES that is due
							to the participant's negligence, he/she may be
							obliged to pay for its value or to replace it.
						</Typography>
						<Typography>
							10a.- The participant will deeply respect the
							natural environment where the activity takes place,
							intensifying this care in areas of protected natural
							spaces. No traces of their passage through the
							natural environment should be left. He/she will also
							respect other groups or people who are in the areas
							where the activities take place.
						</Typography>
						<Typography>
							11th - The participant gives his/her consent to use
							the images taken during the course of the activities
							for information/advertising campaigns that PÈNDOL
							MOUNTAIN GUIDES may undertake in the future and to
							show them to other participants who take part in
							courses and/or activities with this company. courses
							and/or activities with this company, unless
							otherwise indicated.
						</Typography>
						<Typography>
							12th.- At the end of the activity, the participant
							will hand over all the material assigned to those
							responsible for the activity.
						</Typography>
					</Container>
				)}
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingTop: 100,
		paddingBottom: 100,
	},
	root: {
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: 25,
		paddingTop: 40,
		paddingBottom: 20,
	},
}));

export default CondicionsActivitats;

import React, { useEffect, useState } from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function Logos() {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:960px)');
    const [images, setImages] = useState([]);
    useEffect(() => {
        const im = importAll(
            require.context(
                '../../../../assets/img/logos',
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(im);
        setImages(im);
    }, []);

    return (
        <Box className={classes.container}>
            <Container maxWidth="lg">
                <Box
                    display={matches ? 'flex' : 'auto'}
                    justifyContent={'space-around'}
                >
                    {images?.map((img) => (
                        <img className={classes.image} src={img} alt="logos" />
                    ))}
                </Box>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 150,
        paddingBottom: 190,
        textAlign: 'center',
        marginTop: -5,
        marginBottom: -5,
        zIndex: 10,
    },
    image: {
        height: 80,
        padding: 20,
    },
}));

function importAll(r) {
    return r.keys().map(r);
}

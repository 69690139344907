import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import theme from '../theme';
import { CircularProgress } from '@mui/material';

const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    padding: '15px 30px',
    borderRadius: 50,
    lineHeight: 1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.color,
    borderColor: theme.palette.background.color,
    fontFamily: 'Raleway',
    boxShadow: '0px 0px 5px 0px ' + theme.palette.background.color,
    fontWeight: 700,
    '&:hover': {
        backgroundColor: theme.palette.background.main,
        color: 'white',
        borderColor: theme.palette.background.main,
        boxShadow: '0px 0px 5px 0px ' + theme.palette.background.main,
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.background.main,
        borderColor: theme.palette.background.main,
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem ' + theme.palette.background.main,
    },
});

const BootstrapButtonSM = styled(Button)({
    textTransform: 'none',
    fontSize: 15,
    padding: '5px 20px',
    borderRadius: 50,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.color,
    borderColor: theme.palette.background.color,
    fontFamily: 'Raleway',
    boxShadow: '0px 0px 5px 0px ' + theme.palette.background.color,
    fontWeight: 700,
    '&:hover': {
        backgroundColor: theme.palette.background.main,
        color: 'white',
        borderColor: theme.palette.background.main,
        boxShadow: '0px 0px 5px 0px ' + theme.palette.background.main,
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.background.main,
        borderColor: theme.palette.background.main,
    },
});

const BootstrapButtonDanger = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '10px 20px',
    borderRadius: 5,
    lineHeight: 1,
    color: 'white',
    backgroundColor: theme.palette.text.danger,
    borderColor: theme.palette.text.danger,
    fontFamily: 'Raleway',
    fontWeight: 700,
    '&:hover': {
        backgroundColor: 'white',
        color: theme.palette.text.danger,
        borderColor: theme.palette.text.danger,
        boxShadow: '0px 0px 5px 0px ' + theme.palette.text.danger,
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.text.danger,
        borderColor: theme.palette.text.danger,
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem ' + theme.palette.text.danger,
    },
});

export default function CustomPublicButton({
    title,
    danger,
    loading,
    small,
    ...rest
}) {
    return danger ? (
        <BootstrapButtonDanger {...rest} variant="contained">
            {loading ? (
                <CircularProgress
                    size={20}
                    style={{ paddingRight: 15, paddingLeft: 15 }}
                />
            ) : (
                <>{title}</>
            )}
        </BootstrapButtonDanger>
    ) : small ? (
        <BootstrapButtonSM {...rest} variant="contained">
            {loading ? (
                <CircularProgress
                    size={20}
                    style={{ paddingRight: 15, paddingLeft: 15 }}
                />
            ) : (
                <>{title}</>
            )}
        </BootstrapButtonSM>
    ) : (
        <BootstrapButton {...rest} variant="contained">
            {loading ? (
                <CircularProgress
                    size={20}
                    style={{ paddingRight: 15, paddingLeft: 15 }}
                />
            ) : (
                <>{title}</>
            )}
        </BootstrapButton>
    );
}

import {
	Box,
	Checkbox,
	Collapse,
	Container,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Title from "../../../components/Layout/Title";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaActivitat } from "../../../components/Schemas";
import CustomCard from "../../../components/CustomCard";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import ImageInput from "../../../components/ImageInput";
import Thumb from "../../../components/Thumb";
import RichText from "../../../components/RichText";
import CustomRadio from "../../../components/CustomRadio";
import {
	createActivitat,
	deleteActivitat,
	getElement,
	getElements,
	updateActivitat,
} from "../../../database/API";
import CustomSelect from "../../../components/CustomSelect";
import Loading from "../../../components/Layout/Loading";
import { useSnackbar } from "notistack";
import ThumbMulti from "../../../components/ThumbMulti";
import ModalDelete from "../../../components/ModalDelete";
import CustomHeader from "../../../components/CustomHeader";
import Preus from "../../../components/Preus";
import TabsLang from "./TabsLang";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
}));

const ActivitatEdit = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [activitat, setActivitat] = useState();
	const [showDesc, setShowDesc] = useState(false);
	const { key } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { errors },
		trigger,
		getValues,
		setValue,
		control,
		watch,
		reset,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaActivitat),
		defaultValues: activitat,
	});
	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "preus",
	});

	useEffect(() => {
		const get = async () => {
			const { result } = await getElement("activitats", key);
			setActivitat(result);
			reset(result);
			setValue("check_descompte", result.descompte > 0);
			setValue("dificultat", Number(result.dificultat));
			setValue("foto", result.imatges);
			setValue("destacada", result.destacada === "1" ? true : false);
			setValue("familia", result.familia === "1" ? true : false);
			setLoading(false);
		};
		get();
	}, [key, reset, setValue]);

	useEffect(() => {
		const subscription = watch(() => {
			setShowDesc(getValues("check_descompte"));
		});
		return () => subscription.unsubscribe();
	}, [watch("check_descompte")]);

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("categories");
			setCategories(result);
		};
		get();
	}, []);

	const enviar = async (values) => {
		console.log(values);
		setLoading(true);
		const message = await updateActivitat(values, key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	const eliminar = async () => {
		const message = await deleteActivitat(key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1);
	};

	return (
		<Box className={classes.container}>
			<Title title="Editar activitat" />
			<Container maxWidth="xl">
				<Loading loading={loading}>
					<Box mt={4}>
						<form onSubmit={handleSubmit(enviar)}>
							<CustomHeader
								title={
									<Typography variant="h3">
										{watch({ nest: true }).langs?.[0]?.nom}
									</Typography>
								}
								button={
									<>
										<CustomButton
											onClick={() => setOpen(true)}
											danger
											title={"Eliminar activitat"}
											sx={{ marginRight: 1 }}
										/>
										<CustomButton
											type="submit"
											title={"Guardar i sortir"}
										/>
									</>
								}
							/>
							<Grid container spacing={5}>
								<Grid item md={8}>
									<CustomCard title="Entra les dades">
										<TabsLang
											register={register}
											control={control}
											setValue={setValue}
											errors={errors}
											getValues={getValues}
										/>
										<Grid container spacing={3}>
											<Grid item md={4}>
												<CustomTextField
													name="edat"
													label="Edat mínima"
													type="number"
													errors={errors.edat}
													register={register}
													InputLabelProps={{
														shrink: key
															? true
															: false,
													}}
												/>
											</Grid>
											<Grid item md={4}>
												<CustomTextField
													name="situacio"
													label="Situació"
													type="text"
													errors={errors.situacio}
													register={register}
													InputLabelProps={{
														shrink: key
															? true
															: false,
													}}
												/>
											</Grid>
											<Grid item md={4}>
												<CustomSelect
													elements={categories}
													name="categoria_id"
													label="Categoria"
													register={register}
													shrink={key ? true : false}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={6}>
												<CustomRadio
													name={"dificultat"}
													label={"Nivell"}
													elements={[
														{
															nom: "Iniciació",
															value: "1",
														},
														{
															nom: "Mig",
															value: "2",
														},
														{
															nom: "Avançat",
															value: "3",
														},
														{
															nom: "Expert",
															value: "4",
														},
													]}
													control={control}
												/>
											</Grid>
											<Grid item md={6}>
												<Typography mt={1}>
													Opcions
												</Typography>
												<FormControlLabel
													control={
														<Controller
															name="destacada"
															control={control}
															render={({
																field: {
																	value,
																	ref,
																	...field
																},
															}) => (
																<Checkbox
																	{...field}
																	inputRef={
																		ref
																	}
																	checked={
																		!!value
																	}
																	color="primary"
																	size={
																		"medium"
																	}
																	disableRipple
																/>
															)}
														/>
													}
													label="Destacar"
													labelPlacement="end"
												/>
												<FormControlLabel
													control={
														<Controller
															name="familia"
															control={control}
															render={({
																field: {
																	value,
																	ref,
																	...field
																},
															}) => (
																<Checkbox
																	{...field}
																	inputRef={
																		ref
																	}
																	checked={
																		!!value
																	}
																	color="primary"
																	size={
																		"medium"
																	}
																	disableRipple
																/>
															)}
														/>
													}
													label="Familiar"
													labelPlacement="end"
												/>
												<FormControlLabel
													control={
														<Controller
															name="check_descompte"
															control={control}
															defaultValue={false}
															render={({
																field: {
																	value,
																	ref,
																	...field
																},
															}) => (
																<Checkbox
																	{...field}
																	inputRef={
																		ref
																	}
																	checked={
																		!!value
																	}
																	color="primary"
																	size={
																		"medium"
																	}
																	disableRipple
																	onClick={() =>
																		setValue(
																			"descompte",
																			0
																		)
																	}
																/>
															)}
														/>
													}
													label="Descompte"
													labelPlacement="end"
												/>
												<Collapse in={showDesc}>
													<CustomTextField
														name="descompte"
														label="% de descompte"
														type="number"
														errors={
															errors.descompte
														}
														register={register}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Collapse>
											</Grid>
										</Grid>
									</CustomCard>
								</Grid>
								<Grid item md={4}>
									<CustomCard title="Imatge">
										<ThumbMulti file={getValues("foto")} />

										<ImageInput
											name="foto"
											register={register}
											trigger={trigger}
											getValues={getValues}
											multiple={true}
										/>
									</CustomCard>
									<Box my={4} />
									<CustomCard title="Preus">
										<Preus
											register={register}
											fields={fields}
											remove={remove}
											append={append}
											replace={replace}
											control={control}
											watch={watch}
										/>
									</CustomCard>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Loading>
				<ModalDelete
					title="Segur que vols eliminar l'activitat?"
					description="Si l'elimines, també s'eliminaran les imatges associades a aquesta"
					open={open}
					setOpen={setOpen}
					func={eliminar}
				/>
			</Container>
		</Box>
	);
};

export default ActivitatEdit;

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CustomCard from "../../../components/CustomCard";
import { getElementAdmin, reenviarMail } from "../../../database/API";
import { useSnackbar } from "notistack";
import Loading from "../../../components/Layout/Loading";
import Title from "../../../components/Layout/Title";
import moment from "moment";
import State from "../../../components/State";
import MaterialElement from "../../../components/Materials/MaterialElement";
import DialogDevolucioLlog from "../../../components/Lloguer/DialogDevolucioLlog";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	talla: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
	line: {
		display: "flex",
		justifyContent: "space-between",
		borderBottom: "1px solid " + theme.palette.background.third + "40",
		paddingTop: 10,
		paddingBottom: 10,
	},
}));

const LloguerView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [lloguer, setLloguer] = useState([]);
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const [loadingEmail, setLoadingEmail] = useState(false);
	const { key } = useParams();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElementAdmin("lloguers", key);
			setLloguer(result);
			setLoading(false);
			console.log(result);
		};
		get();
	}, []);

	const reenviar = async () => {
		setLoadingEmail(true);
		const { message } = await reenviarMail("reenviarEmailLloguer", key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setLoadingEmail(false);
	};

	return (
		<Box className={classes.container}>
			<Title title="Detall lloguer" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<Grid container spacing={4}>
							<Grid item md={7}>
								<CustomCard title="Material llogat">
									<Box>
										{lloguer?.materials?.map((material) => (
											<MaterialElement
												material={material}
											/>
										))}
									</Box>
								</CustomCard>
							</Grid>
							<Grid item md={5}>
								<CustomCard title="Dades del client">
									<Box className={classes.line}>
										<Typography variant="body2">
											Nom:
										</Typography>
										<Typography variant="body1">
											{lloguer?.nom}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											E-mail:
										</Typography>
										<Box
											display={"flex"}
											alignItems={"center"}
										>
											<Typography variant="body1">
												{lloguer?.email}
											</Typography>
											<Tooltip title="Reenviar correus">
												<IconButton onClick={reenviar}>
													<ForwardToInboxIcon />
												</IconButton>
											</Tooltip>
											{loadingEmail && (
												<CircularProgress />
											)}
										</Box>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Telèfon:
										</Typography>
										<Typography variant="body1">
											{lloguer?.phone}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data compra:
										</Typography>
										<Typography variant="body1">
											{moment(lloguer?.created_at).format(
												"DD/MM/YYYY HH:mm"
											)}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Observacions:
										</Typography>
										<Typography variant="body1">
											{lloguer?.observacions}
										</Typography>
									</Box>
								</CustomCard>
								<Box my={3} />
								<CustomCard title="Dades de la compra">
									<Box className={classes.line}>
										<Typography variant="body2">
											Estat:
										</Typography>
										<Typography variant="body1">
											<State
												state={lloguer?.state}
												id={lloguer?.id}
												element="changeStateLloguer"
											/>
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											ID:
										</Typography>
										<Typography variant="body1">
											{lloguer?.unique_id}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data de lloguer:
										</Typography>
										<Typography variant="body1">
											{moment(lloguer?.data).format(
												"DD/MM/YYYY"
											)}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body1">
											Total:
										</Typography>
										<Typography variant="h2">
											{lloguer?.preu} €
										</Typography>
									</Box>
								</CustomCard>
								<Box my={3} />
								<DialogDevolucioLlog lloguer={lloguer} />
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default LloguerView;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const Custom = styled(TextField)({
    marginTop: 25,
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
});

export default function CustomTextField({
    errors,
    label,
    name,
    type,
    register,
    size,
    ...rest
}) {
    return (
        <Custom
            error={Boolean(errors)}
            helperText={errors?.message}
            fullWidth
            label={label}
            name={name}
            type={type}
            variant="outlined"
            size={size ? size : 'small'}
            {...register(name)}
            {...rest}
        />
    );
}

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Plus } from 'react-feather';

const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: '1px solid ' + theme.palette.background.light,
    },
    boto: {
        boxShadow: 'none',
        textTransform: 'none',
        padding: '10px 20px',
        borderRadius: 5,
        lineHeight: 1.5,
        color: 'white',
        backgroundColor: theme.palette.background.main,
        borderColor: theme.palette.background.main,
        fontFamily: 'Open Sans',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
}));

export default function ImageInput({
    errors,
    name,
    register,
    trigger,
    multiple,
}) {
    const classes = useStyles();
    return (
        <Box mt={3}>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <label htmlFor={name}>
                        <input
                            id={name}
                            name={name}
                            type="file"
                            multiple={multiple}
                            {...register(name, {
                                onChange: () => {
                                    trigger(name);
                                },
                            })}
                            style={{
                                display: 'none',
                            }}
                        />
                        <Box className={classes.boto}>
                            <Plus size={15} />
                            <Typography
                                style={{
                                    color: 'white',
                                    fontSize: 13,
                                }}
                            >
                                {' '}
                                Afegir/Modificar imatges
                            </Typography>
                        </Box>
                    </label>
                </Box>
            </Box>

            <Typography variant="error">{errors?.message}</Typography>
        </Box>
    );
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import parse from "html-react-parser";
import { devolucio } from "../../database/API";
import CustomCard from "../CustomCard";
import CustomButton from "../CustomButton";

export default function DialogDevolucio({ reserva }) {
	const [open, setOpen] = useState(false);
	const [form, setForm] = useState();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const formDevolucio = useForm({
		reValidateMode: "onChange",
		defaultValues: { tantpercent: 1 },
	});

	const retornar = async (values) => {
		setLoading(true);
		const { resultat } = await devolucio(values, reserva?.unique_id);
		setForm(resultat);
		setTimeout(() => document.forms["redsys_form"].submit(), 500);
	};

	return (
		<Box>
			<CustomCard title="Devolució de la reserva">
				<Box m={3}></Box>
				{parse(form ? form.data : "")}
				<Box my={3}>
					<CustomButton
						fullWidth
						onClick={() => setOpen(true)}
						danger
						disabled={reserva?.state_id === "5"}
						title="Devolució"
					/>
				</Box>
			</CustomCard>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<form onSubmit={formDevolucio.handleSubmit(retornar)}>
					<DialogTitle id="alert-dialog-title">
						Segur que vols fer la devolució de la reserva?
					</DialogTitle>
					<DialogContent>
						<Typography>
							Si ho fas, es retornarà el % de l'import que
							determinis al client i es posarà la reserva com a
							estat "Cancel·lat".
						</Typography>
						<Box my={3} />
						<Typography>
							Les dades del client i la reserva es conservaràn.
						</Typography>
						<Box my={3} />
						<FormControl fullWidth>
							<InputLabel id={"tantpercent"}>
								% a retornar
							</InputLabel>
							<Select
								labelId="tantpercent"
								id="tantpercent"
								label=" % a retornar"
								{...formDevolucio.register("tantpercent")}
								required
							>
								<MenuItem value={1} selected>
									100%
								</MenuItem>
								<MenuItem value={0.5}>50%</MenuItem>
							</Select>
						</FormControl>
						<Box my={3}>
							<Typography variant="body1">
								{formDevolucio.watch("tantpercent") *
									reserva?.preu}{" "}
								€ de retorn
							</Typography>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Tancar</Button>
						<CustomButton
							autoFocus
							type="submit"
							variant="contained"
							danger
							title="Retornar import"
						/>
					</DialogActions>
				</form>
			</Dialog>
		</Box>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import { getElementAdmin, reenviarMail } from "../../../../database/API";
import Title from "../../../../components/Layout/Title";
import Loading from "../../../../components/Layout/Loading";
import CustomCard from "../../../../components/CustomCard";
import preusEntrada from "../../../../data/PreusData";
import State from "../../../../components/State";
import DialogDevolucioEntrada from "./DIalogDevolucioEntrada";
import { useSnackbar } from "notistack";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const EntradaView = () => {
	const classes = useStyles();
	const [reserva, setReserva] = useState();
	const [loading, setLoading] = useState(true);
	const [loadingEmail, setLoadingEmail] = useState(false);
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElementAdmin("entrades", id);
			setReserva(result);
			setLoading(false);
		};
		get();
	}, []);

	const reenviar = async () => {
		setLoadingEmail(true);
		const { message } = await reenviarMail("reenviarEmailEntrada", id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setLoadingEmail(false);
	};

	return (
		<Box className={classes.container}>
			<Title title="Detall entrada - Espai Actiu" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<Grid container spacing={4}>
							<Grid item md={7}>
								<CustomCard title="Dades del client">
									<Box className={classes.line}>
										<Typography variant="body2">
											Nom:
										</Typography>
										<Typography variant="body1">
											{reserva?.nom}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											E-mail:
										</Typography>
										<Box
											display={"flex"}
											alignItems={"center"}
										>
											<Typography variant="body1">
												{reserva?.email}
											</Typography>
											<Tooltip title="Reenviar correus">
												<IconButton onClick={reenviar}>
													<ForwardToInboxIcon />
												</IconButton>
											</Tooltip>
											{loadingEmail && (
												<CircularProgress size={"sm"} />
											)}
										</Box>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Telèfon:
										</Typography>
										<Typography variant="body1">
											{reserva?.phone}
										</Typography>
									</Box>

									<Box className={classes.line}>
										<Typography variant="body2">
											Observacions:
										</Typography>
										<Typography variant="body1">
											{reserva?.observacions}
										</Typography>
									</Box>
								</CustomCard>
								<Box mt={4} />
								<CustomCard title="Persones">
									{reserva?.persones?.map((persona) => (
										<Box className={classes.persona} mb={3}>
											<Box className={classes.lineN}>
												<Typography variant="body2">
													Nom:
												</Typography>
												<Typography variant="body1">
													{persona?.nom}
												</Typography>
											</Box>
											{persona?.dni !== "undefined" && (
												<Box className={classes.lineN}>
													<Typography variant="body2">
														DNI:
													</Typography>
													<Typography
														variant="caption"
														textTransform={
															"uppercase"
														}
													>
														{persona?.dni}
													</Typography>
												</Box>
											)}
											<Box className={classes.lineN}>
												<Typography variant="body2">
													Tipus:
												</Typography>
												<Typography
													variant="caption"
													textTransform={"uppercase"}
												>
													{persona?.tipus}
												</Typography>
											</Box>
											{persona?.tipus === "federat" &&
												persona?.carnet && (
													<Box
														className={
															classes.lineN
														}
													>
														<Typography variant="body2">
															Carnet:
														</Typography>
														<Tooltip title="Veure imatge carnet">
															<a
																href={
																	"https://pendolguies.covcontrol.net/public/storage/" +
																	persona?.carnet
																}
																target="_blank"
																rel="noreferrer"
															>
																<Avatar
																	src={
																		"https://pendolguies.covcontrol.net/public/storage/" +
																		persona?.carnet
																	}
																	style={{
																		width: 50,
																		height: 50,
																	}}
																	alt="carnet"
																/>
															</a>
														</Tooltip>
													</Box>
												)}
											<Box className={classes.lineN}>
												<Typography variant="body2">
													Preu:
												</Typography>
												<Typography variant="body1">
													{
														preusEntrada
															?.filter(
																(item) =>
																	item.id ===
																	Number(
																		reserva?.access
																	)
															)?.[0]
															?.preus?.filter(
																(i) =>
																	i?.nom ===
																	persona?.tipus
															)?.[0]?.preu
													}{" "}
													€
												</Typography>
											</Box>
										</Box>
									))}
								</CustomCard>
							</Grid>
							<Grid item md={5}>
								<CustomCard title="Dades de la compra">
									<Box className={classes.line}>
										<Typography variant="body2">
											Estat:
										</Typography>
										<Typography variant="body1">
											<State
												state={reserva?.state}
												id={reserva?.id}
												element="changeStateEntrada"
											/>
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											ID:
										</Typography>
										<Typography variant="body1">
											{reserva?.unique_id}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data compra:
										</Typography>
										<Typography variant="body1">
											{moment(reserva?.created_at).format(
												"DD/MM/YYYY"
											)}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Accés:
										</Typography>
										<Typography variant="body1">
											{reserva?.access === "1"
												? "Accés General"
												: "Accés Barrancs"}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Nº de persones:
										</Typography>
										<Typography variant="body1">
											{reserva?.persones?.length}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data de reserva:
										</Typography>
										<Typography variant="body1">
											{moment(reserva?.data).format(
												"DD/MM/YYYY"
											)}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body1">
											Total:
										</Typography>
										<Typography variant="h3">
											{reserva?.preu} €
										</Typography>
									</Box>
								</CustomCard>
								<Box my={3} />
								<DialogDevolucioEntrada reserva={reserva} />
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default EntradaView;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	talla: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
	line: {
		display: "flex",
		justifyContent: "space-between",
		borderBottom: "1px solid " + theme.palette.background.third + "40",
		paddingTop: 10,
		paddingBottom: 10,
	},
	lineN: {
		display: "flex",
		justifyContent: "space-between",
		paddingTop: 10,
		paddingBottom: 10,
	},
	persona: {
		backgroundColor: theme.palette.background.third + "50",
		padding: 10,
		borderRadius: 10,
	},
}));

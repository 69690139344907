import React from 'react';
import {
    Link,
    NavLink as RouterLink,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import navBarStyle from './NavBarStyle';
import { Box, Typography, Link as LinkTo } from '@mui/material';

const NavBarItemMobile = ({ className, to, title, closeMenu, ...rest }) => {
    const classes = navBarStyle();
    const location = useLocation();
    const navigate = useNavigate();

    const onClick = () => {
        closeMenu();
    };

    console.log(to);
    return (
        <LinkTo
            className={classes.buttonMobile}
            to={{
                pathname: to,
            }}
            underline="none"
            component={RouterLink}
            onClick={onClick}
        >
            <Typography variant="body1">{title}</Typography>
        </LinkTo>
    );
};

NavBarItemMobile.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavBarItemMobile;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	AppBar,
	Box,
	Container,
	Drawer,
	Hidden,
	IconButton,
	List,
	Toolbar,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/img/logo-icono-pendol.svg";
import { Menu } from "react-feather";
import NavBarItem from "./NavBarItem";
import { Close } from "@mui/icons-material";
import NavBarItemMobile from "./NavBarItemMobile";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import LangSelector from "../../lang/LangSelector";
import XXSS from "../../components/Layout/XXSS";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		paddingTop: 20,
		paddingBottom: 20,
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
	},
	navBar: {
		backgroundColor: "transparent !important",
		boxShadow: "none",
	},
	navBarDown: {
		backgroundColor: theme.palette.background.main,
		boxShadow: "none",
	},
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [menu, setMenu] = useState(null);
	const [scrollY, setScrollY] = useState(window.scrollY);
	const { t } = useTranslation();

	const items = [
		{
			title: t("Inici"),
			to: "",
		},
		{
			title: t("Qui som"),
			to: "qui-som",
		},
		{
			title: t("Activitats"),
			to: "activitats",
		},
		{
			title: t("Famílies"),
			to: "families",
		},
		{
			title: t("Escoles"),
			to: "escoles",
		},
		{
			title: t("Lloguer"),
			to: "lloguer",
		},
		{
			title: t("Contacte"),
			to: "contacte",
		},
	];
	const openMenu = (event) => {
		setMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return (
		<Box className={classes.main}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar
					className={clsx(
						classes.nav,
						scrollY > 200 ? classes.navBarDown : classes.navBar
					)}
				>
					<a href="/">
						<img src={logo} alt="logo" width={40} />
					</a>
					<Box flexGrow={1} />

					<Hidden mdDown>
						<Box style={{ display: "flex", alignItems: "center" }}>
							{items?.map((item) => (
								<NavBarItem
									to={item.to}
									key={item.title}
									title={item.title}
								/>
							))}
							<XXSS white />
							<LangSelector />
						</Box>
					</Hidden>
					<Hidden lgUp>
						<IconButton
							style={{ zIndex: 10 }}
							color="inherit"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={openMenu}
							className={classes.buttonDrawer}
						>
							<Menu />
						</IconButton>
						<Drawer open={menu} onClose={closeMenu}>
							<IconButton>
								<Close onClick={closeMenu} />
							</IconButton>
							{items?.map((item) => (
								<NavBarItemMobile
									to={item.to}
									key={item.title}
									title={item.title}
									closeMenu={closeMenu}
								/>
							))}
							<XXSS />
							<LangSelector mobile />
						</Drawer>
					</Hidden>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;

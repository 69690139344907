import * as React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export default function NivellFiltre({ nivell, set, dificultat }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	if (nivell) {
		return (
			<Box
				onClick={() => set(nivell.id)}
				className={clsx(
					classes.chip,
					nivell?.id <= dificultat ? classes.active : ""
				)}
			>
				<Typography variant="body1" textTransform={"uppercase"}>
					{nivell?.nom}
				</Typography>
			</Box>
		);
	} else {
		return (
			<Box
				onClick={() => set("")}
				className={clsx(
					classes.chip,
					dificultat === "" ? classes.active : ""
				)}
			>
				<Typography variant="body1" textTransform={"uppercase"}>
					{t("Tots")}
				</Typography>
			</Box>
		);
	}
}

const useStyles = makeStyles((theme) => ({
	chip: {
		paddingLeft: 17,
		paddingRight: 17,
		paddingTop: 5,
		paddingBottom: 5,
		margin: 5,
		borderRadius: 20,
		border: "1px solid " + theme.palette.background.main,
		cursor: "pointer",
	},
	active: {
		backgroundColor: theme.palette.background.color,
	},
}));

import { Cancel } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";

const Error = () => {
	const classes = Styles();
	const { t } = useTranslation();
	return (
		<Box className={classes.root}>
			<Container maxWidth="lg" className={classes.main}>
				<Box style={{ color: "red" }} className={classes.titol}>
					<Cancel />
					<Typography variant="h2">{t("Pagament fallat")}</Typography>
				</Box>
				<Typography className={classes.titol}>
					{t(
						"Alguna cosa no ha anat bé. Torna-ho a intentar més tard."
					)}
					{t("Disculpa les molèsties.")}
				</Typography>
			</Container>
		</Box>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingBottom: 50,
	},
	root: {
		paddingTop: 150,
		paddingBottom: 300,
		backgroundSize: "cover",
		overflow: "auto",
		justifyContent: "center",
	},

	titol: {
		color: "green",
		display: "flex",
		justifyContent: "center",
	},
}));

export default Error;

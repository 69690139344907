import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import CustomTextField from "../../../components/CustomTextField";
import RichText from "../../../components/RichText";
import { getElements } from "../../../database/API";

export default function TabsLang({
	register,
	errors,
	setValue,
	control,
	getValues,
}) {
	const classes = useStyles();
	const [tab, setTab] = useState(0);
	const [langs, setLangs] = useState([]);
	const [loading, setLoading] = useState(false);

	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "langs",
	});

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("languages");
			setLangs(result);
			setLoading(false);
		};
		get();
	}, []);

	const isFound = (id) =>
		fields.some((element) => {
			if (Number(element.language_id) === id) {
				return true;
			}

			return false;
		});

	useEffect(() => {
		langs.map((item) => {
			if (!isFound(item.id)) {
				append({ language_id: item.id });
			}
		});
	}, [langs]);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
				}}
			>
				<Tabs
					value={tab}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{langs?.map((item, index) => (
						<Tab label={item?.nom} {...a11yProps(index)} />
					))}
				</Tabs>
			</Box>

			{fields.map((item, index) => {
				const lang_nom = langs?.filter(
					(l) => l.id === Number(item.language_id)
				)?.[0]?.nom;

				return (
					<TabPanel
						value={tab}
						index={index}
						className={classes.tabs}
					>
						<CustomTextField
							name={`langs.${index}.nom`}
							label={"Nom de l'activitat (" + lang_nom + ")"}
							type="text"
							errors={errors.nom}
							register={register}
						/>
						<Grid container spacing={3}>
							<Grid item md={6}>
								<RichText
									register={register}
									name={`langs.${index}.descripcio`}
									label={"Descripció (" + lang_nom + ")"}
									setValue={setValue}
									getValues={getValues}
								/>
							</Grid>
							<Grid item md={6}>
								<RichText
									register={register}
									name={`langs.${index}.inclou`}
									label={"Inclou (" + lang_nom + ")"}
									setValue={setValue}
									getValues={getValues}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item md={4}>
								<CustomTextField
									name={`langs.${index}.epoca`}
									label={"Època (" + lang_nom + ")"}
									type="text"
									errors={errors.epoca}
									register={register}
								/>
							</Grid>
							<Grid item md={4}>
								<CustomTextField
									name={`langs.${index}.durada`}
									label={
										"Durada de l'activitat (" +
										lang_nom +
										")"
									}
									type="text"
									errors={errors.durada}
									register={register}
								/>
							</Grid>
							<Grid item md={4}>
								<CustomTextField
									name={`langs.${index}.material`}
									label={
										"Material a portar (" + lang_nom + ")"
									}
									type="text"
									errors={errors.material}
									register={register}
								/>
							</Grid>
						</Grid>
					</TabPanel>
				);
			})}
			<Divider />
		</>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		"& .MuiBox-root": {
			padding: 0,
		},
		marginBottom: 30,
	},
}));

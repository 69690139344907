import { Box, Fade, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import DialogMaterial from "./DialogMaterial";
import { useTranslation } from "react-i18next";

export default function MaterialPublic({
	material,
	setMaterialsSel,
	setTotal,
	data,
	filter,
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { i18n } = useTranslation();

	const renderNom = () => {
		switch (i18n.language) {
			case "ca":
				return material?.nom;

			case "es":
				return material?.nom_es;

			case "en":
				return material?.nom_en;

			default:
				break;
		}
	};

	return (
		material?.categoria_materials?.some(
			(item) => Number(item.id) === filter
		) && (
			<Grid item md={3} sm={4} xs={12}>
				<Box className={classes.wrap} onClick={() => setOpen(true)}>
					<Box className={classes.header}>
						<Typography variant="body1" padding={1}>
							{renderNom()}
						</Typography>
					</Box>
					<Box className={classes.body}>
						<img
							src={
								"https://pendolguies.covcontrol.net/public/storage/" +
								material?.foto
							}
							width={"100%"}
							alt={"Imatge"}
							role="presentation"
						/>
					</Box>
					<Typography variant="body1" textAlign="center" pb={1}>
						{material?.preus?.[0]?.preu} €
					</Typography>
				</Box>
				<DialogMaterial
					material={material}
					open={open}
					setOpen={setOpen}
					setMaterialsSel={setMaterialsSel}
					setTotal={setTotal}
					data_ll={data}
				/>
			</Grid>
		)
	);
}

const useStyles = makeStyles((theme) => ({
	wrap: {
		backgroundColor: "white",
		borderRadius: 10,
		textAlign: "left",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		border: "1px solid",
		transition: "0.2s",
		cursor: "pointer",
		"&:hover": {
			transform: "scale(1.05)",
			boxShadow:
				"0px 0px 11px 0px " + theme.palette.background.main + "90",
		},
	},
	header: {
		textAlign: "center",
		backgroundColor: theme.palette.background.color,
		borderRadius: "10px 10px 0 0",
		"& p": {
			fontWeight: 600,
		},
	},
	body: {
		textAlign: "center",
		paddingTop: 15,
		paddingBottom: 10,
		paddingLeft: 30,
		paddingRight: 30,
		"& p": {
			fontFamily: "Raleway",
			textAlign: "center",
		},
	},
}));

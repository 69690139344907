import * as React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Activity, CheckSquare, Package } from "react-feather";
import { getElementsAdmin } from "../database/API";
import {
	CalendarMonth,
	EventAvailable,
	EventBusy,
	EventRepeat,
	LocalMall,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
	kml: {
		borderRight: "1px solid " + theme.palette.background.light,
		paddingRight: 25,
		paddingLeft: 25,
		paddingTop: 15,
		paddingBottom: 15,
	},
	icon: {
		paddingRight: 10,
		width: 40,
		height: 40,
	},
	loading: {
		fontSize: 10,
	},
}));

export default function KPICalendari() {
	const classes = useStyles();
	const [kpi, setKpi] = React.useState();
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const get = async () => {
			const { result } = await getElementsAdmin("kpiCalendari");
			setKpi(result);
			setLoading(false);
		};
		get();
	}, []);

	return (
		<Box>
			<Grid
				container
				sx={{
					alignItems: "center",
					bgcolor: "background.paper",
					color: "text.secondary",
					padding: 0,
				}}
			>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Dies obert </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<EventAvailable
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2" fontSize={35}>
								{kpi?.dies_obert}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Dies tancat </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<EventBusy
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2" fontSize={35}>
								{kpi?.dies_tancat}
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Dies per determinar</Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<EventRepeat
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2" fontSize={35}>
								{kpi?.dies_determinar}
							</Typography>
						)}
					</Box>
				</Grid>
				<Grid item md={3} className={classes.kml}>
					<Typography variant="h6">Dies en manteniment </Typography>
					<Box display="flex" alignItems={"center"} mt={1}>
						<EventBusy
							sx={{ width: 40, height: 40 }}
							className={classes.icon}
						/>
						{loading ? (
							<CircularProgress size={28} />
						) : (
							<Typography variant="h2" fontSize={35}>
								{kpi?.dies_manteniment}
							</Typography>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

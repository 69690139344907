import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1150,
			xl: 1920,
		},
	},
	palette: {
		background: {
			main: "#2E2C26",
			secondary: "#1C1B17",
			third: "#9C978A",
			light: "#e6e6e7",
			color: "#fae500",
			gradient:
				"linear-gradient(0deg, rgba(28,27,23,1) 0%, rgba(46,44,38,1) 100%)",
		},
		primary: {
			main: "#2E2C26",
			hover: "#1d1d1b",
		},
		secondary: {
			main: "#fae500",
			hover: "#fae500",
		},
		danger: {
			main: colors.red[500],
			light: colors.red[300],
		},
		success: {
			main: colors.green[500],
		},
		text: {
			primary: "#2E2C26",
			secondary: "#fae500",
			main: "#9C978A",
			danger: "#be1622",
			opacity: "#403b2f50",
		},
		typography: {
			fontFamily: "Montserrat",
		},
	},
	typography: {
		h1: {
			fontSize: "4rem",
			fontFamily: "DM Serif Display",
			color: "#2E2C26",
			[breakpoints.down("sm")]: {
				fontSize: "3rem",
			},
		},
		h2: {
			fontSize: "1.5rem",
			fontFamily: "Raleway",
			color: "#2E2C26",
		},
		h3: {
			fontSize: "1.1rem",
			fontFamily: "Raleway",
			color: "#2E2C26",
			fontWeight: 800,
		},
		h4: {
			fontSize: "1.1rem",
			fontFamily: "Raleway",
			color: "#2E2C26",
		},
		h5: {
			fontFamily: "Raleway",
			fontWeight: 800,
			color: "#2E2C26",
		},
		h6: {
			fontSize: "0.9rem",
			fontFamily: "Raleway",
			fontWeight: 500,
			color: "#9C978A",
			textTransform: "uppercase",
		},
		body1: {
			fontFamily: "Raleway",
			color: "#2E2C26",
			[breakpoints.down("md")]: {
				fontSize: "0.9rem",
			},
		},
		body2: {
			fontFamily: "Raleway",
			fontSize: "0.8rem",
			color: "#2E2C26",
		},
		caption: {
			fontFamily: "Raleway",
			color: "#9C978A",
		},
		root: {
			"& a": {
				color: "#2E2C26",
				textDecoration: "none",
				transition: "0.5s",
				"&:hover": {
					color: "black",
				},
			},
		},
		error: {
			fontFamily: "Raleway",
			color: "#d32f2f",
			fontSize: "0.75rem",
		},
		title: {
			fontFamily: "Cookie ",
			fontSize: "3.5rem",
			color: "#2E2C26",
		},
		link: {
			fontSize: "0.9rem",
			fontFamily: "Raleway",
			fontWeight: 500,
			color: "#ffffff",
		},
	},
	"& a": {
		color: "black",
	},
});

export default theme;

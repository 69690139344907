import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomPublicButton from "../../../../components/CustomPublicButton";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: 140,
		paddingBottom: 220,
		textAlign: "center",
	},
}));

export default function QuiSom() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Typography variant="h1" textAlign="center" marginBottom={5}>
				{t("Qui som")}
			</Typography>
			<Typography variant="body1" textAlign="center" marginBottom={5}>
				{t(
					"Pèndol Guies de Muntanya és una empresa d'esports d'aventura, ubicada a l'Alt Berguedà (Vallcebre) i creada per guies titulats de muntanya i barrancs."
				)}
			</Typography>
			<CustomPublicButton
				title={t("Més info")}
				onClick={() => navigate("qui-som")}
			/>
		</Container>
	);
}

import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 5,
        marginBottom: 30,
        alignItems: 'center',
        alignContent: 'center',
    },
    col1: {
        paddingTop: '0 !important',
        display: 'flex',
        flex: 1,
        paddingRight: 20,
        '& .MuiFormControl-root': {
            marginTop: 0,
        },
    },
}));

export default function CustomHeader({ title, button }) {
    const classes = useStyles();
    return (
        <Box className={classes.header}>
            <Box className={classes.col1}>{title}</Box>
            <Box className={classes.col2}>{button}</Box>
        </Box>
    );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontSize: 14,
        fontWeight: 400,
    },
});

export default function CustomRadio({ control, name, label, elements }) {
    return (
        <FormControl>
            <Box my={1} />
            <FormLabel>{label}</FormLabel>
            <Controller
                control={control}
                name={name}
                defaultValue=""
                render={({ field }) => {
                    return (
                        <RadioGroup {...field} row>
                            {elements?.map((item) => (
                                <CustomFormControlLabel
                                    key={item.value}
                                    value={item.value}
                                    control={<Radio />}
                                    label={item.nom}
                                />
                            ))}
                        </RadioGroup>
                    );
                }}
            />
        </FormControl>
    );
}

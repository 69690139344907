import { Avatar, Chip, IconButton, Stack } from "@mui/material";
import { Edit } from "react-feather";
import { useNavigate } from "react-router";

const MaterialsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "foto",
			label: "Imatge",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						variant="circular"
						src={
							"https://pendolguies.covcontrol.net/public/storage/" +
							value
						}
					/>
				),
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "categoria_materials",
			label: "Categories",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => {
					return (
						<Stack direction="row" spacing={1}>
							{value?.map((item) => (
								<Chip label={item.nom} />
							))}
						</Stack>
					);
				},
			},
		},
		{
			name: "talles",
			label: "Talles",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => {
					return (
						<Stack direction="row" spacing={1}>
							{value?.map((item) => (
								<Chip label={item.nom + " x" + item.quant} />
							))}
						</Stack>
					);
				},
			},
		},
		{
			name: "preus",
			label: "Temps/Preu",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => {
					return (
						<Stack direction="row" spacing={1}>
							{value?.map((item) => (
								<Chip
									label={item.nom + ": " + item.preu + " €"}
								/>
							))}
						</Stack>
					);
				},
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/materials/edit/${activitats[dataIndex].id}`,
									{
										state: activitats[dataIndex],
									}
								)
							}
						>
							<Edit />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default MaterialsColumns;

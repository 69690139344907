import { Box, Fade, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import CustomPublicButton from "./CustomPublicButton";
import Nivell from "./Nivell";
import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from "react-router";
import familia from "../assets/img/familia.svg";
import { useTranslation } from "react-i18next";

const responsive = {
	0: { items: 1 },
	568: { items: 1 },
	1024: { items: 1 },
};
export default function ActivitatPublic({ activitat }) {
	const classes = useStyles();
	const [items, setItems] = useState([]);
	const handleDragStart = (e) => e.preventDefault();
	let navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		let list = [];
		activitat?.imatges?.map((img) => {
			list.push(
				<Box className={classes.imageWrap}>
					<img
						src={
							"https://pendolguies.covcontrol.net/public/storage/" +
							img.foto
						}
						width={"100%"}
						onDragStart={handleDragStart}
						alt={"Imatge"}
						role="presentation"
					/>
				</Box>
			);
		});
		setItems(list);
	}, [activitat]);

	return (
		<Grid item md={4} sm={12} xs={12}>
			<Box className={classes.wrap}>
				<Box>
					<Box className={classes.header}>
						<Typography variant="h2" padding={1}>
							{activitat?.langs?.[0]?.nom}
						</Typography>
					</Box>
					<Box className={classes.body}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
						>
							<Box>
								<Typography
									variant="h3"
									textTransform="uppercase"
								>
									{t(activitat?.categoria?.nom)}
								</Typography>
								<Nivell dificultat={activitat?.dificultat} />
							</Box>
							<Box>
								{activitat?.familia === "1" ? (
									<Tooltip title={t("Activitat familiar")}>
										<img
											src={familia}
											alt={t("Activitat familiar")}
											width={50}
										/>
									</Tooltip>
								) : (
									""
								)}
							</Box>
						</Box>

						<Typography variant="body1">
							<div
								dangerouslySetInnerHTML={{
									__html:
										activitat?.langs?.[0]?.descripcio.substring(
											0,
											150
										) + "...",
								}}
							/>
						</Typography>
					</Box>
				</Box>

				<Box className={classes.slide}>
					<AliceCarousel
						mouseTracking
						items={items}
						controlsStrategy="alternate"
						disableButtonsControls
						// responsive={responsive}
					/>
					<CustomPublicButton
						onClick={() => navigate("/activitats/" + activitat.id)}
						fullWidth
						title={t("Més info")}
					/>
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	wrap: {
		backgroundColor: "white",
		borderRadius: 30,
		textAlign: "left",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	header: {
		backgroundColor: theme.palette.background.color,
		padding: 10,
		borderRadius: "30px 30px 0 0",
		"& h2": {
			fontFamily: "DM Serif Display",
			fontWeight: 400,
			fontSize: 32,
		},
	},
	body: {
		textAlign: "left",
		padding: 20,
		"& p": {
			fontFamily: "Raleway",
			textAlign: "left",
		},
	},
	imageWrap: {
		height: 200,
	},
	slide: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
		"& .alice-carousel__dots-item": {
			backgroundColor: theme.palette.background.main,
		},
		"& .alice-carousel__dots-item.__active": {
			backgroundColor: theme.palette.background.color,
		},
		"& .alice-carousel__dots": {
			margin: 10,
		},
	},
}));

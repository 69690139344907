import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Login } from '../../database/API';
import img from '../../assets/img/escoles.jpg';
import Header from '../../components/Header';
import Sanefa from '../../components/Sanefa';
import Page from '../../components/Layout/Page';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}));

const LoginView = () => {
    const classes = useStyles();
    const [error, setError] = useState('');
    let navigate = useNavigate();

    const schema = Yup.object().shape({
        email: Yup.string()
            .email('Ha de ser un email vàlid')
            .max(255)
            .required("L'email és obligatòri"),
        password: Yup.string()
            .max(255)
            .required('La contrasenya és obligatòria'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
    });

    const logInEmail = async (values) => {
        const { email, password } = values;
        const token = await Login(email, password);
        if (token === 'error') {
            setError('Usuari o contrasenya incorrectes');
        } else {
            navigate('/admin/');
        }
    };

    return (
        <Page title={'Login'}>
            <Header img={img} small />
            <Sanefa color="white" />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                my={10}
            >
                <Container maxWidth="sm">
                    <form onSubmit={handleSubmit(logInEmail)}>
                        <Box mb={3}>
                            <Typography color="textPrimary" variant="h2">
                                {'Entrar'}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                {"Entra a la pàgina d'administració"}
                            </Typography>
                        </Box>
                        <TextField
                            error={Boolean(errors && errors.email)}
                            fullWidth
                            helperText={errors && errors?.email?.message}
                            label="E-mail"
                            margin="normal"
                            name="email"
                            type="email"
                            variant="outlined"
                            {...register('email')}
                        />
                        <TextField
                            error={Boolean(errors && errors.password)}
                            fullWidth
                            helperText={errors && errors?.password?.message}
                            label={'Contrasenya'}
                            margin="normal"
                            name="password"
                            type="password"
                            variant="outlined"
                            {...register('password')}
                        />
                        <Typography variant="body1" style={{ color: 'red' }}>
                            {error}
                        </Typography>

                        <Box my={2}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {'Sign in'}
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;
